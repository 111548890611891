import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Polygons } from 'pages/smart-chain/smart-chain/ui/intro/assets';
import { Br } from 'shared';

export const Intro = () => {
  const theme = useTheme();

  return (
    <Box component="section">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} md={8}>
          <Typography
            variant="h1"
            className="no-resize"
            sx={{
              [theme.breakpoints.down('sm')]: {
                fontSize: 30,
              },
            }}
          >
            <Typography variant="inherit" component="span" fontWeight="inherit" className="accent">
              Smart-
            </Typography>
            Chain
          </Typography>
        </Grid>
        <Grid item xs="auto" order={{ xs: 3, md: 2 }}>
          <Polygons />
        </Grid>
        <Grid item xs={12} order={{ xs: 2, md: 3 }}>
          <Typography variant="h5" className="sm secondary regular" mt={{ xs: 1.25, md: 1.75 }} mb={{ xs: 2.5, md: 0 }}>
            Pioneering the Future <Br display={{ xs: 'inline', sm: 'none' }} /> of Blockchain
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h5" maxWidth={864} fontSize="26px" ml="auto" letterSpacing="-0.1px" pt={{ xs: 4, sm: 6 }}>
        The CratD2C SmartChain ushers in a new era of blockchain innovation, redefining the boundaries of decentralized
        technology with its Layer-1 DPoS (Delegated Proof of Stake) consensus mechanism. This state-of-the-art platform
        guarantees unparalleled security and immediate transaction finality and sets new standards for scalability and
        efficiency while maintaining minimal costs and prioritizing privacy.
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(8, 1fr)',
        }}
      >
        <Box
          sx={{
            mt: { xs: 2.5, sm: 6 },
            gridColumn: { xs: 'span 12', sm: '2 / 11', md: '5 / 11' },
          }}
        >
          <Typography variant="body2" className="secondary" lineHeight="20px">
            As a beacon of technological advancement, CratD2C&#39;s SmartChain transcends conventional blockchain
            capabilities, marking the dawn of a transformative digital age. It is more than mere technology; it is a
            revolutionary shift in the digital landscape, offering a comprehensive solution that caters to the evolving
            needs of decentralized ecosystems.
            <br />
            <br />
            The CratD2C Decentralized Autonomous Smart Chain embodies the zenith of blockchain innovation. It represents
            a seamless fusion of leading-edge technology and meticulous design, establishing a decentralized framework
            that is both robust and flexible. This platform is a pivotal force in reshaping digital transactions,
            interactions, and applications, heralding a future where the full potential of blockchain technology is
            realized and decentralized possibilities are endless.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
