import { Box, Stack, Typography } from '@mui/material';
import { ListItem } from 'shared';

import { list } from './constants';

export const Luxury = () => {
  return (
    <Box>
      <Typography variant="h5">
        CratD2C&#39;s decentralized Luxury Lifestyle Booking Portal is an online booking platform that leverages
        blockchain technology and decentralized systems to facilitate bookings and reservations for luxury experiences
        such as hotels, travel, cruise, restaurants, events, and other exclusive services. In addition, the booking
        platform co-houses some fundamental decentralized features, which make holidays and travel much more fun,
        cost-friendly, & rewarding.
      </Typography>
      <Box
        py={6}
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Stack
          gap={5}
          sx={{
            gridColumn: { xs: 'span 12', md: '4 / 13' },
            transform: { xs: 'translateX(-35px)', md: 'none' },
          }}
        >
          {list.map((item, i) => {
            return <ListItem key={item.title} number={i + 1} title={item.title} description={item.description} />;
          })}
        </Stack>
      </Box>
      <Typography variant="h5" pt={{ md: 4 }}>
        With CratD2C&#39;s decentralized luxury lifestyle booking portal, users can be sure of a secure, transparent,
        and convenient way to book and manage luxury experiences while reducing reliance on centralized intermediaries
        and enhancing data privacy.
      </Typography>
    </Box>
  );
};
