import { COLOR_PRIMARY, Intro } from 'shared';

export const IntroSection = () => {
  return (
    <Intro
      sx={{ mb: { xs: 4, md: 8 } }}
      mainTitle="Tokenomics"
      paragraph="CratD2C's Decentralized Autonomous Smart Chain features an innovative tokenomics mechanism designed to maintain equilibrium between supply and demand for its native coin. This is accomplished by implementing an 8-layer ZigZag mechanized module Supply Chain, which adapts coin supply in response to market dynamics."
      paragraphSubtitle="What sets CratD2C's Decentralized Autonomous Smart Chain apart is its meticulously crafted coin distribution strategy aimed at fostering a flourishing ecosystem while incentivizing key stakeholders. The inclusive allocation of coins promotes active participation, stability, and enduring growth across all facets of the project."
      paragraphSubtitleProps={{ className: 'inherit', sx: { color: COLOR_PRIMARY } }}
    />
  );
};
