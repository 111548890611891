import { useDispatch } from 'react-redux';
import { Box, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { modalModel } from 'entities/modal';
import { Icons, useBreakpoints } from 'shared';

import { participants } from './constants';
import { CratCircle } from './ui';

export const Ecosystem = () => {
  const dispatch = useDispatch();
  const { isDownSmLayout } = useBreakpoints();

  const openModal = (
    type: Exclude<
      modalModel.ModalType,
      modalModel.ModalType.SendSuccess | modalModel.ModalType.SendReject | modalModel.ModalType.ApproveReject
    >,
  ) => {
    dispatch(
      modalModel.actions.openModal({
        type,
      }),
    );
  };

  return (
    <Box component="section" mt={10}>
      <Typography
        component="h2"
        variant="h1"
        sx={{
          maxWidth: '1050px',
        }}
      >
        Maximise the Moment Time Meet with Opportunity
      </Typography>
      <Grid container justifyContent="end" rowGap={{ xs: 3, md: 6 }} mt={{ xs: 3, md: 8.5 }}>
        <Grid item xs={12} md={8}>
          <Typography
            variant={isDownSmLayout ? 'body2' : 'h5'}
            fontSize={{ xs: 14, md: 26 }}
            className={clsx('no-resize', isDownSmLayout && 'font-secondary secondary')}
          >
            Join the CratD2C Delegation Foundation Program and enjoy a share of the Validation Pool Rewards, including
            Block Rewards and Transaction Fees on the Blockchain, for a Lifetime, as a VALIDATOR or DELEGATOR.
            Alternatively, participate as a LiteBacker or TurboBacker to secure Bonus EcoIP Portions that grant Lifetime
            Royalties.
          </Typography>
        </Grid>

        <Grid item container xs={12} md={8} rowSpacing={1.25} columnSpacing={1}>
          {participants.map(({ name, modalType }) => (
            <Grid key={name} item xs={12} sm={6}>
              <Card
                sx={{
                  gridColumn: { md: 'span 4', sm: 'span 5', xs: 'span 6' },
                  height: 145,
                }}
              >
                <Stack
                  justifyContent="space-between"
                  sx={{
                    height: '100%',
                  }}
                >
                  <Typography variant="h5" className="no-resize" lineHeight="22px">
                    Become
                    <br />
                    <Typography component="span" variant="inherit">
                      a{' '}
                    </Typography>
                    <Typography component="span" variant="inherit" className="accent">
                      {name}
                    </Typography>
                  </Typography>
                  <Stack direction="row" justifyContent="flex-end">
                    <IconButton onClick={() => openModal(modalType)}>
                      <Icons.Plus />
                    </IconButton>
                  </Stack>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Typography variant="h4" className="secondary" mt={{ xs: 6, md: 12 }} mb={{ md: 2, xs: 5 }}>
        Ecosystem
      </Typography>

      <CratCircle />

      <Box
        sx={{
          paddingTop: '36px',
          paddingBottom: '36px',
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Box
          sx={{
            pt: { sm: 12, xs: 6 },
            gridColumn: { md: '7 / 13', xs: 'span 12' },
            pr: { md: 8, xs: 0 },
          }}
        >
          <Typography variant="h5">
            A Smart Chain Built with Delegated Proof of Stake (DPoS), revolutionizing industries across the board,
            unlocking unprecedented efficiency, collaboration, and resilience in an interconnected, trustless ecosystem.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
