export const list = [
  {
    title: 'Block Rewards',
    description:
      "In the context of CratD2C, this refers to the transaction fees that validators and delegators receive for participating in the network's consensus process. Validators and delegators play a crucial role in verifying and validating transactions on the blockchain. As a reward for their efforts, they receive a share of the transaction fees network users pay.",
  },
  {
    title: 'Tiered StakePool APR+',
    description: `Think of this like earning interest on your savings account. The more CRAT Coins you hold and "stake" in the network, the more you can earn. It's a way to make your coins work for you.`,
  },
  {
    title: 'EcoIP Royalties',
    description:
      "This is a unique feature. When you hold CRAT Coins in the stakepool for a period of 6months, you get a share of the income generated by the platform's intellectual property. It's like owning a piece of a company and getting a share of its profits.",
  },
  {
    title: 'Liquidity Pool Reward',
    description:
      "CratD2C's Liquidity Pool Reward incentivizes users to contribute assets to liquidity pools, offering them a share of liquidity pool profits as compensation. This mechanism ensures the platform maintains high liquidity, facilitating smoother trades and financial operations. Rewards are designed to encourage long-term participation and investment in the ecosystem.",
  },
  {
    title: 'Defined Price Progression',
    description:
      'As the platform grows and becomes more valuable, the price of CRAT Coins is designed to go up. So, over time, the coins you hold could become more valuable.',
  },
];
