import { FC } from 'react';
import { Box, BoxProps, Grid, SvgIconProps, Typography } from '@mui/material';
import { COLOR_STROKE, COLOR_WH, FontWeights } from 'shared/config';
import { LinearProgressWithLabel } from 'shared/ui';

interface TableItem {
  name: string;
  total: number;
  filled: number;
  labelText: string;
  Icon: FC<SvgIconProps<'svg'>>;
}

interface AnalysisTableProps {
  data: TableItem[];
}

export const AnalysisTable = ({ data, ...boxProps }: AnalysisTableProps & BoxProps) => {
  return (
    <Box
      sx={{
        gridColumn: { xs: 'span 13', sm: '1 / 9', md: '7 / 13' },
      }}
      {...boxProps}
    >
      {data.map(({ name, labelText, total, filled, Icon }, index) => {
        const isFirst = index === 0;
        const value = !isFirst ? (filled / total) * 100 : -1;

        return (
          <Grid container justifyContent="end" key={name} marginTop={isFirst ? 0 : 4}>
            <Grid item xs={4} md={3} display="flex" alignItems="center" flexDirection="row">
              <Icon sx={{ width: 36, height: 36, mr: 1, ml: { xs: 0, lg: 2 } }} />
              <Typography variant="h5" className="secondary">
                {name}
              </Typography>
            </Grid>

            <Grid item xs={8} md={9}>
              <LinearProgressWithLabel
                label={
                  <Typography
                    variant="body2"
                    fontWeight={FontWeights.Medium}
                    sx={{
                      position: 'absolute',
                      color: COLOR_WH,
                      top: '50%',
                      right: 12,
                      transform: 'translateY(-50%)',
                    }}
                  >
                    {labelText}
                  </Typography>
                }
                value={value}
                wrapperSx={{ mr: 0, ml: 2 }}
                sx={{
                  width: '100%',
                  height: 50,
                  borderRadius: 0,
                  backgroundColor: COLOR_STROKE,
                }}
              />
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};
