import { memo } from 'react';
import { Stack, StackProps } from '@mui/material';

import { RowItem, RowItemProps } from './row-item';

interface SplittedRowsProps {
  data: RowItemProps[];
  textColor?: string;
  titleColor?: string;
  rowTitleVariant?: 'h4' | 'h5';
  rowProps?: StackProps;
}

export const SplittedRows = memo(
  ({ data, textColor, titleColor, rowProps, rowTitleVariant, ...stackProps }: SplittedRowsProps & StackProps) => {
    return (
      <Stack spacing={{ xs: 3, md: 5 }} mb={{ xs: 6, md: 12 }} {...stackProps}>
        {data.map(({ title, description, subitems }, index) => (
          <RowItem
            key={`${index + 1}`}
            textColor={textColor}
            titleColor={titleColor}
            title={title}
            description={description}
            subitems={subitems}
            titleVariant={rowTitleVariant}
            {...rowProps}
          />
        ))}
      </Stack>
    );
  },
);

SplittedRows.displayName = 'SplittedRows';
