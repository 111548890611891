export const BOTTOM_TEXT =
  "TurboBackers in the CratD2C Decentralized Autonomous Smart Chain Ecosystem are instrumental in fostering network growth, security, and decentralization through their significant investments. They enjoy numerous benefits, including higher APRs, the ability to compound earnings weekly, access to exclusive IP portions with potential royalty returns, and recognition as key contributors to the ecosystem's success. Their role signifies a higher level of commitment and underscores the crucial support they provide to the platform, making them an indispensable part of the CratD2C community.";

export const intro = {
  paragraph:
    "TurboBackers represent a distinguished group of contributors within the CratD2C Decentralized Autonomous Smart Chain Ecosystem, setting themselves apart by staking a minimum of 300,000 CRAT Coins. Their substantial stakes are vital for enhancing the network's decentralization and security, marking their critical role in the ecosystem's infrastructure.",
  subtitle:
    "By committing a larger amount of coins, TurboBackers underscore their dedication and support for the platform's vision and functionality. Here's an in-depth look at the TurboBacker role and the five key advantages they enjoy:",
};

export const list = [
  {
    title: 'Higher Annual Returns',
    description:
      'TurboBackers are rewarded with a higher Annual Percentage Rate (APR) of 20% on their staked coins, acknowledging their increased stake and commitment to the ecosystem. This elevated APR offers significant financial returns, reflecting the value placed on their enhanced contribution.',
  },
  {
    title: 'Weekly Re-Staking for Compounded Earnings',
    description:
      'Unlike LiteBackers, who re-stake biweekly, TurboBackers have the privilege of re-staking their holdings weekly. This frequent re-staking capability allows them to compound their rewards more often, accelerating their earnings growth and maximizing their investment potential.',
  },
  {
    title: 'Exclusive Bonus IP Portions',
    description:
      'TurboBackers also receive Bonus CratD2C IP portions, similar to LiteBackers. These IP portions grant them eligibility for potential biannual royalty returns, rewarding their substantial contributions and long-term dedication to the CratD2C ecosystem.',
  },
  {
    title: 'Strengthening Network Decentralization and Security',
    description:
      "TurboBackers play a pivotal role in bolstering the ecosystem's decentralization and enhancing its security by holding a significant stake in the network. Their substantial investment helps to distribute control more broadly across the network, reducing vulnerabilities and increasing resilience.",
  },
  {
    title: 'Recognition as Esteemed Contributors',
    description:
      "TurboBackers are acknowledged as elite members of the CratD2C community. Their willingness to invest a higher amount of CRAT Coins earns them a place of honour within the ecosystem, recognizing their vital role in supporting and shaping the platform's future.",
  },
];
