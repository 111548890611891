/* eslint-disable react/no-array-index-key */
import { Stack, StackProps } from '@mui/material';

import { RoadmapProps } from './types';
import { Step } from './ui';

export const Roadmap = ({
  steps,
  firstPolygonVariant,
  fixedLineSize,
  fixedStepSize,
  showCardNumber,
  spacing = 0,
  ...props
}: StackProps & RoadmapProps) => {
  return (
    <Stack spacing={{ xs: 3, md: spacing }} {...props}>
      {steps.map((step, i, arr) => {
        const isEven = (i + 1) % 2 === 0;
        const isLast = i + 1 === arr.length;
        const isFirst = i === 0;
        return (
          <Step
            key={i}
            firstPolygonVariant={firstPolygonVariant}
            step={step}
            isFirst={isFirst}
            isLast={isLast}
            isEven={isEven}
            index={i}
            spacing={spacing}
            fixedLineSize={fixedLineSize}
            fixedStepSize={fixedStepSize}
            showCardNumber={showCardNumber}
          />
        );
      })}
    </Stack>
  );
};
