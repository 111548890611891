import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, Modal } from 'shared';

export interface SendTimeoutModalPayload {
  data?: null;
  type: ModalType.SendTimeout;
  isOpen?: boolean;
}

export const SendTimeoutModal = React.forwardRef<
  HTMLElement,
  Pick<SendTimeoutModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose }, ref) => {
  return (
    <Modal ref={ref} showClose onClose={onClose}>
      <Stack alignItems="center">
        <Typography variant="h5" component="span" color="inherit" className="no-resize">
          STEP 2/2{' '}
          <Typography variant="inherit" component="span" className="accent">
            SEND
          </Typography>
        </Typography>
        <Icons.Exclamation
          sx={{
            mt: 4,
            mb: 3,
          }}
        />
        <Typography
          variant="h5"
          className="center"
          component="span"
          color="inherit"
          fontSize={18}
          sx={{
            maxWidth: 408,
          }}
        >
          Sorry, this transaction processing took too long. Please, refresh the page to see your current balance.
        </Typography>
        <Button
          onClick={() => window.location.reload()}
          sx={{
            mt: 4,
          }}
        >
          Refresh page
        </Button>
      </Stack>
    </Modal>
  );
});
