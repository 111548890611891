import { Box, Grid, Typography } from '@mui/material';

import { AboutCard, AboutText } from './ui';

export const About = () => {
  return (
    <Box component="section" mt={{ xs: 8, sm: 18.25 }}>
      <Grid container>
        <Grid item xs={12} sm={4} mb={{ xs: 4, sm: 0 }}>
          <Typography variant="h4" className="secondary">
            About
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <AboutText />
          <AboutCard />
        </Grid>
      </Grid>
    </Box>
  );
};
