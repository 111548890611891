import { Grid, SxProps, Typography } from '@mui/material';
import { CALCULATOR_BORDER } from 'features/calculator';
import {
  LEFT_SPACE_DESKTOP,
  LEFT_SPACE_XS,
} from 'pages/smart-chain/comparative-analysis/ui/comparison-table/comparison-table-head';
import { COLOR_PRIMARY, COLOR_SECONDARY } from 'shared';

interface TransformedData {
  title: string;
  values: string[];
}

export const ComparisonTableRow = ({ title, values, sx }: TransformedData & { sx?: SxProps }) => {
  return (
    <Grid
      container
      item
      xs={12}
      component="tr"
      sx={{ width: '100%', py: 1.75, minHeight: 70, borderTop: CALCULATOR_BORDER, ...sx }}
    >
      <Grid item xs={LEFT_SPACE_XS} md={LEFT_SPACE_DESKTOP} component="td">
        <Typography maxWidth={{ xs: 175, sm: 230 }} sx={{ paddingRight: 3 }} variant="h6">
          {title}
        </Typography>
      </Grid>

      {values.map((value, index) => (
        <Grid
          item
          component="td"
          xs={(12 - LEFT_SPACE_XS) / values.length}
          md={(12 - LEFT_SPACE_DESKTOP) / values.length}
          key={`${title + value + index}`}
        >
          <Typography variant="h6" color={value === 'NO' ? COLOR_SECONDARY : COLOR_PRIMARY} sx={{ paddingRight: 4 }}>
            {value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
