import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Play: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 40 40"
    sx={{
      width: '40px',
      height: '40px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <ellipse cx="20" cy="19.9921" rx="20" ry="19.998" fill="#B4EB56" />
    <path d="M28.8965 19.9386L15.6336 27.5155L15.6336 12.3616L28.8965 19.9386Z" fill="black" />
  </SvgIcon>
);
