import type { PropsWithChildren } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { ConnectModalContext } from './context';

export const ConnectModalProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const contextValue = useMemo(() => ({ isOpen, open, close }), [open, close, isOpen]);

  return <ConnectModalContext.Provider value={contextValue}>{children}</ConnectModalContext.Provider>;
};
