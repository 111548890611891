import { ChangeEvent, useCallback, useState } from 'react';

import { validateOnlyNumbers } from './useValidateInputField.helpers';
import { InputProps, ValidationTypes } from './useValidateInputField.types';

export const useValidateInputField = (
  options: InputProps,
): [
  string,
  (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => string | boolean,
  (value: string) => void,
] => {
  const { type, decimals, maxValue, initialState = '' } = options ?? {};
  const [inputValue, setInputValue] = useState(initialState);

  const handleChangeValue = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;

    if (value === '' || type === ValidationTypes.string) {
      setInputValue(value);
    }

    if (type === ValidationTypes.number) {
      const isNumber = validateOnlyNumbers({ value, decimals: decimals || 18 });

      if (!isNumber) {
        return false;
      }

      const maxNumberValue = maxValue ? Number(maxValue) : Infinity;
      if (isNumber && Number(value) <= maxNumberValue) {
        setInputValue(value);
      }
    }

    if (type === ValidationTypes.int) {
      const isInteger = validateOnlyNumbers({ value, isInteger: true, decimals: decimals || 18 });

      if (isInteger) {
        setInputValue(value);
      } else {
        return false;
      }
    }

    return value;
  };

  const handleSetOriginValue = useCallback((value: string) => {
    setInputValue(value);
  }, []);

  return [inputValue, handleChangeValue, handleSetOriginValue];
};
