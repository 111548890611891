import { Typography } from '@mui/material';

export const intro = {
  paragraph:
    "The intellectual property associated with CratD2C's Decentralized Autonomous Smart Chain represents a significant achievement in the technological domain, reflecting a meticulous and strategic approach to safeguarding innovation. This body of work, recognized and protected under the auspices of the International Online Copyright Office (INTEROCO), headquartered in the European Union, Berlin, Germany, encapsulates a comprehensive spectrum of intellectual property considerations.",
  subtitle:
    'These considerations range from the foundational aspects of scientific contribution, through detailed annotations, to the legal frameworks that underpin the protection of such a novel creation.\n',
};

export const list = [
  {
    title: 'Work of Science',
    description:
      'This aspect underscores the original research and technological advancements embodied in the Decentralized Autonomous Smart Chain. It highlights the innovative leap made by CratD2C in the realm of blockchain technology.',
  },
  {
    title: 'Annotation and Basic Definitions:',
    description:
      'These sections provide clarity and precision, defining the terminology and conceptual underpinnings essential for understanding the scope and application of the Smart Chain.',
  },
  {
    title: 'Analysis of the Closest Analogue',
    description:
      "Through a rigorous comparison with existing technologies, this analysis demonstrates the unique value proposition and advancement offered by CratD2C's creation.",
  },
  {
    title: 'Relevance and Summary of Work',
    description:
      'This summarization articulates the significance of the Smart Chain within the broader technological and economic landscape, outlining its potential impact and applications.',
  },
  {
    title: 'Legal Basis',
    description:
      "The documentation here outlines the robust legal framework that safeguards CratD2C's intellectual property, ensuring its protection against unauthorized use or infringement.",
  },
  {
    title: 'Sources of References, Brand Name, and Logo',
    description:
      'Essential branding components such as the brand name, logo, and domain protections are meticulously documented alongside the reference sources that underpin the technological development.',
  },
  {
    title: 'Business Technologies',
    description:
      'This encompasses the strategic integration of the Smart Chain into viable business models and technological infrastructures, ensuring its applicability and relevance in real-world scenarios.',
  },
];

export const listTitle = (
  <Typography variant="h4" className="summary" maxWidth={700} mb={{ md: 1.25 }}>
    At the core of this intellectual property package are several key elements:
  </Typography>
);
