import { Box, Typography } from '@mui/material';
import { COLOR_PRIMARY, H1, PageParagraph, Section } from 'shared';
import { FontWeights } from 'shared/config/theme/Typography';

export const Intro = () => {
  return (
    <Section>
      <H1 text="Latency & TPS" highlightedWords={[0]} />

      <PageParagraph>
        CratD2C Decentralized Autonomous Smart Chain boasts a remarkable combination of low latency and high throughput,
        making it a standout contender in the blockchain space. Latency refers to the time delay between the initiation
        of a transaction and its completion, while TPS (Transactions Per Second) measures the number of transactions a
        blockchain can process within a given time frame.
      </PageParagraph>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          mt: 4,
        }}
      >
        <Typography
          variant="body2"
          className="secondary"
          fontWeight={FontWeights.Medium}
          sx={{
            gridColumn: { xs: 'span 12', md: '7 / 13' },
            span: { color: COLOR_PRIMARY },
          }}
        >
          In the case of CratD2C, its latency is impressively low, ranging from 0.5 to 3 seconds.This near-instant
          finality ensures that transactions are confirmed swiftly, providing users with a seamless and efficient
          experience. The reduced latency not only enhances user satisfaction but also opens the door for real-time
          applications, such as micropayments, where timely confirmation is crucial.
          <br />
          <br />
          Furthermore,{' '}
          <span>CratD2C boasts a high TPS capacity, supporting up to 100,000 transactions per second.</span> This level
          of throughput enables the network to handle a substantial volume of transactions concurrently, ensuring that
          it can cater to the demands of a global user base without encountering congestion or delays. High TPS is
          particularly important for applications in sectors like e-commerce, finance, and gaming, where rapid
          transaction processing is essential.
          <br /> <br />
          The combination of low latency and high TPS showcases CratD2C&#39;s commitment to delivering an efficient,
          user-friendly, and scalable blockchain ecosystem. This technological advantage not only enables smooth
          day-to-day operations but also lays the foundation for innovative use cases that require rapid transaction
          confirmation and high network capacity. Whether it&#39;s facilitating secure and quick payments, powering
          decentralized applications, or supporting complex smart contracts, CratD2C&#39;s latency and TPS capabilities
          position it as a robust and forward-looking solution within the blockchain landscape.
        </Typography>
      </Box>
    </Section>
  );
};
