import React from 'react';
import { Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface CratNewModalPayload {
  data?: null;
  type: ModalType.CratNew;
  isOpen?: boolean;
}

export const CratNewModal = React.forwardRef<
  HTMLElement,
  Pick<CratNewModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar isOpen={isOpen} ref={ref} onClose={onClose} title="CratD2C Smart-Chain" icon={<Icons.CratThin />}>
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        CratD2C stands at the forefront of blockchain innovation, merging the efficiency and security of Delegated Proof
        of Stake (DPoS) with the foundational strength of Layer-1 architecture. This fusion not only sets a new
        benchmark in terms of security, speed, and scalability within the blockchain realm but also pioneers a
        transformation in e-commerce dynamics by enabling direct and seamless interactions between businesses and
        consumers. At the heart of CratD2C&apos;s innovation is utilizing a secure, transparent, and cost-efficient
        smart contract system underpinned by its advanced DPoS blockchain framework. This system is designed to
        streamline transactions and foster trust between parties, thus revolutionizing the traditional e-commerce model.
        <br />
        <br />
        CratD2C distinguishes itself through its expansive ecosystem, which transcends the conventional boundaries of
        blockchain functionality. Far more than a mere blockchain network, CratD2C emerges as a vibrant ecosystem for
        decentralized applications (dApps) and a variety of marketplaces. Within this ecosystem lies a trio of
        specialized portals, each targeting a specific industry sector:
        <br />
        <li>An e-commerce trading portal tailored for the seamless buying and selling of goods</li>
        <li>A real estate trading portal designed to revolutionize property transactions</li>
        <li>A luxury lifestyle booking portal offering exclusive access to premium services and experiences</li>
        <br />
        This comprehensive suite of portals is complemented by a universal payment gateway, which includes a crypto
        debit card feature, seamlessly bridging the gap between blockchain technology and traditional merchant services.
        <br />
        Integrating CRAT native coin as the primary medium of exchange across these portals ensures that every
        transaction benefits from the blockchain&apos;s core attributes: speed, transparency, and security. By
        leveraging blockchain technology, CratD2C can guarantee the integrity and reliability of transactions across its
        network and offer a level of efficiency that traditional payment methods struggle to match. This strategic
        incorporation of blockchain technology across diverse sectors underscores CratD2C&apos;s adaptability and
        ambition to redefine the landscape of e-commerce, real estate, luxury services, and more.
        <br />
        <br />
        Furthermore, CratD2C&apos;s vision extends to creating a decentralized ecosystem where users can engage in
        various activities, from shopping and trading to booking luxury experiences, all within a secure and
        user-friendly environment. The platform&apos;s commitment to innovation is evident in its continuous development
        of new features and services designed to enhance user experience and expand the practical applications of
        blockchain technology in everyday life. By doing so, CratD2C is not just advancing the technological framework
        of blockchain applications but is also shaping a future where blockchain and commerce converge, offering
        unparalleled benefits to businesses and consumers alike.
        <br />
        <br />
        In conclusion, CratD2C is more than a blockchain platform; it&apos;s a comprehensive ecosystem poised to disrupt
        traditional market paradigms through its unique blend of technology, security, and versatility. Its approach to
        integrating blockchain technology into various sectors exemplifies the potential for decentralized systems to
        create more efficient, transparent, and secure business operations. As CratD2C continues to expand its offerings
        and refine its technology, it stands as a beacon of innovation in the blockchain space, heralding a new era of
        e-commerce and beyond.
      </Typography>
    </ModalSidebar>
  );
});
