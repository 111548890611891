import { Box, Stack, styled, Typography } from '@mui/material';
import { COLOR_ACCENT, COLOR_STROKE } from 'shared/config';

export const ChartWrapper = styled(Box)({
  display: 'table',
  position: 'relative',
  width: '100%',
});

export const YAxisText = styled(Typography)({
  position: 'relative',
  transform: 'translateY(-50%)',
  width: '100%',
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '50%',
    width: 'calc(100% - 40px)',
    left: '40px',
    border: `1px dashed ${COLOR_STROKE}`,
    transform: 'translateY(50%)',
  },
});

export const BarElementsWrapper = styled(Stack)({
  position: 'absolute',
  tableLayout: 'fixed',
  height: '100%',
  bottom: '-2px',
  right: 0,
});

export const BarElement = styled(Box)({
  position: 'relative',
  transition: 'height 0.5s 2s',
  marginRight: 'auto',
  background: COLOR_ACCENT,
});
