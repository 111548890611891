import { Fragment } from 'react';
import { Box, Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { BORDER_MAIN, PageParagraph, useBreakpoints } from 'shared';

import { progress, tokenomicsInfo } from './constants';
import { ProgressItem } from './ui';

export const ProgressInfo = () => {
  const { isDownMdLayout } = useBreakpoints();

  return (
    <Stack component="section" alignItems="center" mb={{ xs: 8, md: 15 }}>
      <Box
        sx={{
          maxWidth: '904px',
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Card
          sx={{
            px: 2.5,
            py: 2,
            gridColumn: 'span 12',
            mb: 6,
          }}
        >
          <Grid container rowSpacing={{ xs: 1.5, md: 0 }}>
            {tokenomicsInfo.map(({ title, subtitle }, index) => {
              const last = index === tokenomicsInfo.length - 1;
              const even = index % 2 === 0;

              return (
                <Fragment key={title}>
                  <Grid item xs={6} md={12 / tokenomicsInfo.length}>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      flexGrow={1}
                      sx={{
                        borderRight: {
                          xs: even ? BORDER_MAIN : 'none',
                          md: last ? 'none' : BORDER_MAIN,
                        },
                      }}
                    >
                      <Typography variant="h4" className="accent md" mb={0.75}>
                        {title}
                      </Typography>
                      <Typography variant="h6" className="center">
                        {subtitle}
                      </Typography>
                    </Stack>
                  </Grid>
                  {!even && !last && isDownMdLayout && (
                    <Grid item xs={12}>
                      <Divider orientation="horizontal" sx={{ height: 'auto' }} />
                    </Grid>
                  )}
                </Fragment>
              );
            })}
          </Grid>
        </Card>
        <Stack
          gap={{ xs: 3, md: 2.5 }}
          sx={{
            gridColumn: 'span 12',
          }}
        >
          {progress.map(({ filled, title, total, label, subtitle }) => {
            const value = (filled / total) * 100;
            return (
              <ProgressItem
                key={title}
                value={value}
                subtitle={subtitle}
                title={title}
                label={label}
                percentage={filled}
              />
            );
          })}
        </Stack>
      </Box>

      <PageParagraph
        sx={{ mt: 5.25 }}
        pProps={{ variant: 'body1', sx: { gridColumn: { xs: 'span 12', md: '7 / 13' } } }}
      >
        This comprehensive and balanced approach ensures that various aspects of the ecosystem are adequately funded,
        stakeholders are incentivized, and the overall project remains vibrant and prosperous.
      </PageParagraph>
    </Stack>
  );
};
