import { H1, PageParagraph, Section } from 'shared';

export const Intro = () => {
  return (
    <Section>
      <H1 text="Distribution & Vesting" highlightedWords={[0]} />
      <PageParagraph sx={{ mt: { xs: 6, md: 10 } }}>
        A comprehensive explanation of the CratD2C Decentralized Autonomous Smart Chain Cliff Period and Vesting Period:
      </PageParagraph>
    </Section>
  );
};
