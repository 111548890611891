import { modalModel } from 'entities/modal';
import { Icons } from 'shared';

export const mainCircleButtons = [
  {
    Icon: Icons.Pos,
    modal: modalModel.ModalType.POS,
    size: 'normal',
  },
  {
    Icon: Icons.PersonSettings,
    modal: modalModel.ModalType.CTVG,
    size: 'normal',
  },
  {
    Icon: Icons.People,
    modal: modalModel.ModalType.DecentralizedCommunity,
    size: 'normal',
  },
  {
    Icon: Icons.Todo,
    modal: modalModel.ModalType.TrustLedger,
    size: 'normal',
  },
  {
    Icon: Icons.Exchange,
    modal: modalModel.ModalType.PaymentGateway,
    size: 'normal',
  },
  {
    Icon: Icons.Building,
    modal: modalModel.ModalType.EstateTrading,
    size: 'normal',
  },
  {
    Icon: Icons.IntellectualProperty,
    modal: modalModel.ModalType.IntellectualProperty,
    size: 'small',
  },
  {
    Icon: Icons.Buy,
    modal: modalModel.ModalType.EcommerceTrading,
    size: 'normal',
  },
] as const;

export const innerCircleButtons = [
  {
    Icon: Icons.BiennialSupplyMechanism,
    modal: modalModel.ModalType.BiennialSupplyMechanism,
  },
  {
    Icon: Icons.Staking,
    modal: modalModel.ModalType.Staking,
  },
  {
    Icon: Icons.Display,
    modal: modalModel.ModalType.VendingMachine,
  },
  {
    Icon: Icons.Diamond,
    modal: modalModel.ModalType.Luxury,
  },
  {
    Icon: Icons.Settings,
    modal: modalModel.ModalType.PaymentApi,
  },
  {
    Icon: Icons.Widget,
    modal: modalModel.ModalType.CratWidget,
  },
] as const;
