import { useReducer } from 'react';

import { retrieveState } from './utils/retrieve-state';
import type { Action, State } from './types';
import { saveState } from './utils';

const DEFAULT_STATE: State = {
  isAuthorized: false,
  token: '',
  address: '',
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'authorize': {
      const { token, address } = action.payload;
      const newState = { isAuthorized: true, token, address };

      localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN_KEY ?? '', JSON.stringify(token));
      saveState(newState);

      return newState;
    }
    case 'disconnect': {
      const newState = { isAuthorized: false, token: '', address: '' } as const;

      localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN_KEY ?? '');
      saveState(newState);

      return newState;
    }
    default: {
      return state;
    }
  }
};

export const useStore = () => {
  return useReducer(reducer, DEFAULT_STATE, (fallback) => retrieveState() ?? fallback);
};
