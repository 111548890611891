import { COLOR_STROKE } from 'shared';

import { type Pool, OptionsEnum, PoolConfigKeys } from './types';

export const uiTitles: Record<PoolConfigKeys, string> = {
  minAmount: 'Minimum CRAT Coins Required to Stake',
  apr: 'APR',
  frequency: 'Re-stating Frequency',
  blockConfirmationReward: 'Block Confirmation Reward (BCR)',
  blockTransactionReward: 'Block Transaction Reward (BTR)',
  stakePoolReward: 'Stake Pool Reward (SPR)',
  ecoIpRoyaltiesReward: 'EcoIP Royalties Reward (ERR)',
  liquidityPoolReward: 'Liquidity Pool Reward (LPR)',
  bonusIpPortions: 'Bonus IP Portions',
  ipPortionsRoyaltyReturns: 'IP-Portions Royalty Returns',
};

export const poolsConfig: Record<OptionsEnum, Pool> = {
  [OptionsEnum.Validator]: {
    minAmount: 100000,
    apr: 15,
    frequency: 'Biweekly',
    blockConfirmationReward: 'YES',
    blockTransactionReward: 'YES',
    stakePoolReward: 'YES',
    ecoIpRoyaltiesReward: 'YES',
    liquidityPoolReward: 'YES',
    bonusIpPortions: 'NO',
    ipPortionsRoyaltyReturns: 'NO',
  },
  [OptionsEnum.Delegator]: {
    minAmount: 1000,
    apr: 13,
    frequency: 'Monthly',
    blockConfirmationReward: 'YES',
    blockTransactionReward: 'YES',
    stakePoolReward: 'YES',
    ecoIpRoyaltiesReward: 'YES',
    liquidityPoolReward: 'YES',
    bonusIpPortions: 'NO',
    ipPortionsRoyaltyReturns: 'NO',
  },
  [OptionsEnum.LiteBacker]: {
    minAmount: 200000,
    apr: 17,
    frequency: 'Biweekly',
    blockConfirmationReward: 'NO',
    blockTransactionReward: 'NO',
    stakePoolReward: 'YES',
    ecoIpRoyaltiesReward: 'YES',
    liquidityPoolReward: 'YES',
    bonusIpPortions: 'YES',
    ipPortionsRoyaltyReturns: 'Biannual',
  },
  [OptionsEnum.TurboBacker]: {
    minAmount: 300000,
    apr: 20,
    frequency: 'Weekly',
    blockConfirmationReward: 'NO',
    blockTransactionReward: 'NO',
    stakePoolReward: 'YES',
    ecoIpRoyaltiesReward: 'YES',
    liquidityPoolReward: 'YES',
    bonusIpPortions: 'YES',
    ipPortionsRoyaltyReturns: 'Biannual',
  },
};

export const initialOptions = [
  OptionsEnum.Validator,
  OptionsEnum.Delegator,
  OptionsEnum.LiteBacker,
  OptionsEnum.TurboBacker,
];

export const CALCULATOR_BORDER = `1px solid ${COLOR_STROKE}`;

export const valuesToPercentageSet = new Set(['APR', 'APY']);
