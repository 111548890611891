import { CliffPeriod, Intro, Schedule, VestingPeriod } from './ui';

export const Distribution = () => {
  return (
    <>
      <Intro />
      <CliffPeriod />
      <VestingPeriod />
      <Schedule />
    </>
  );
};
