import { H1, PageParagraph, Section } from 'shared';

export const Intro = () => {
  return (
    <Section>
      <H1 text="Smart Chain Scope" highlightedWords={[0, 1]} />

      <PageParagraph>
        In the expansive and innovative ecosystem of the CratD2C Decentralized Autonomous Smart Chain, several distinct
        roles or statuses play crucial roles in its functioning, each contributing uniquely to its success. These roles
        are meticulously designed to create a harmonious and dynamic environment that fuels the platform&#39;s growth,
        security, and effectiveness. Here, we delve into comprehensive explanations of these key players within the
        CratD2C ecosystem
      </PageParagraph>
    </Section>
  );
};
