import { Box, Stack, Typography } from '@mui/material';
import { COLOR_ACCENT, COLOR_PRIMARY, COLOR_SECONDARY, SplittedRows } from 'shared';

import { rows } from './constants';
import { CertificationCard } from './ui';

export const IpCertification = () => {
  return (
    <>
      <Stack direction="row" justifyContent="space-between" flexWrap="wrap" gap={3}>
        <Typography
          variant="h1"
          className="no-resize"
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              fontSize: 30,
            },
          })}
        >
          <Box component="span" color={COLOR_ACCENT}>
            IP-
          </Box>
          Certification
        </Typography>
        <CertificationCard />
      </Stack>
      <Typography variant="h4" sx={{ mt: { xs: 5, md: 6.25 }, mb: { xs: 2, md: 3.75 } }}>
        Key Intellectual Property Components
      </Typography>
      <SplittedRows data={rows} titleColor={COLOR_PRIMARY} textColor={COLOR_SECONDARY} />
      <Stack
        direction="row"
        justifyContent="space-between"
        my={{ xs: 4, md: 14.25 }}
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Typography variant="h4" sx={{ gridColumn: { xs: 'span 12', md: '1 / 5' } }}>
          International Protection and Recognition:
        </Typography>
        <Typography
          variant="body2"
          color={COLOR_SECONDARY}
          sx={{ mt: { xs: 3, md: 0 }, gridColumn: { xs: 'span 12', md: '7 / 13' } }}
        >
          The intellectual property rights of CratD2C are internationally protected and have been rigorously appraised
          by INTEROCO and the Author&apos;s Design (literary and financial) by the Ministry of Economy of the United
          Arab Emirates. This global certification ensures the secure and widespread application of CratD2C&apos;s
          Decentralized Autonomous Smart Chain, fostering innovation and development across the blockchain technology
          landscape.
        </Typography>
      </Stack>
    </>
  );
};
