import { Box, Stack, Typography } from '@mui/material';
import { TeamSlider } from 'shared';

export const Team = () => {
  return (
    <Box component="section" mt={{ xs: 6, sm: 12 }} mb={{ xs: 11.5, md: 13 }}>
      <Stack gap={6}>
        <Typography variant="h4" className="secondary">
          Team
        </Typography>

        <TeamSlider />
      </Stack>
    </Box>
  );
};
