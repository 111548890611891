export const BOTTOM_TEXT =
  "In summary, Delegators play a critical role in CratD2C's decentralized governance and operation. By delegating their coins to Validators, Delegators enhance the network's consensus, contribute to its security, and are rewarded for their participation. Their role simplifies the process for individuals who want to contribute to the network without becoming Validators themselves, ultimately contributing to the decentralized and robust nature of CratD2C's Decentralized Autonomous Smart Chain.";

export const intro = {
  paragraph:
    "Within the expansive ecosystem of the CratD2C Decentralized Autonomous Smart Chain, the role of a Delegator is instrumental in ensuring the network's stability, security, and consensus. Delegators play a crucial part in the decentralized governance and operation of the blockchain, contributing to its efficient functioning and overall success.",
  subtitle: (
    <>
      At its core, a Delegator is an individual who entrusts their CRAT Coins to a chosen Validator&#39;s staking pool.
      By doing so, Delegators actively participate in the network&#39;s consensus mechanism without the technical
      complexities that Validators deal with. Delegators essentially delegate their staking power to Validators,
      allowing Validators to use their delegated coins to participate in the consensus process. <br /> <br /> Delegators
      contribute to the overall security and stability of the CratD2C blockchain by enhancing the voting power of
      Validators. Validators with higher delegated stakes have more influence in the consensus mechanism, as their
      decision-making power is proportional to the total number of staked coins under their management, including those
      from Delegators. This mechanism encourages Validators to act in the network&#39;s best interest, as they have a
      vested interest in maintaining the trust of both their own staked coins and those delegated to them. <br />
      <br /> In return for their role, Delegators are entitled to rewards based on the performance of the
      Validator&#39;s staking pool. These rewards are distributed among delegators in proportion to the number of CRAT
      coins they have delegated. The rewards usually come as an Annual Percentage Rate (APR) of 13% on the staked coins.
      This incentivizes Delegators to actively participate in the network by choosing reputable Validators who offer
      competitive rewards. <br />
      <br /> Delegators enjoy several benefits. They can participate in the network&#39;s consensus mechanism without
      the technical know-how and infrastructure required for Validators. Delegators also have the flexibility to
      re-delegate their coins to a different Validator&#39;s pool if they believe another Validator offers better
      rewards or services. This versatility allows Delegators to optimize their participation in the network based on
      their preferences and objectives. <br />
      <br /> However, it&#39;s important to note that Delegators do not have direct control over the network&#39;s
      decisions. Their influence is indirect through the Validators they choose to delegate to. Validators, in turn,
      make decisions that affect the network&#39;s consensus and security.
    </>
  ),
};

export const list = [
  {
    title: 'Indirect Participation in Consensus Mechanism',
    description:
      "Delegators have the unique advantage of contributing to the network's consensus mechanism without needing technical expertise or infrastructure. By delegating their CRAT Coins to Validators, they empower these Validators to represent them in the consensus process, enabling a more secure and stable blockchain environment.",
  },
  {
    title: 'Earn Rewards without Active Management',
    description:
      "Delegators receive rewards for their staked coins, typically an Annual Percentage Rate (APR) of 13%. This allows them to benefit from participating in the network's growth and success without the complexities of active network management. It makes it an attractive option for those looking to contribute with minimal involvement.",
  },
  {
    title: 'Enhanced Network Security and Stability',
    description:
      "Through their delegation, Delegators enhance the voting power of Validators, directly contributing to the network's overall security and stability. The increased stake gives Validators more influence in the consensus mechanism, promoting decisions that align with the network's best interests and encouraging responsible management by Validators.",
  },
  {
    title: 'Flexibility to Optimize Returns',
    description:
      'Delegators have the flexibility to re-delegate their coins to different Validators, allowing them to seek out those offering competitive rewards or better performance. This versatility enables Delegators to adapt their strategies based on changing conditions or preferences, optimizing their investment returns.',
  },
  {
    title: 'Contribute to Decentralized Governance',
    description:
      'Delegators play a crucial role in the decentralized governance of the CratD2C blockchain by selecting Validators to delegate their staking power. Their choices influence power distribution among Validators, fostering a balanced and democratic decision-making process within the network.',
  },
];
