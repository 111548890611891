import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { appRoutes } from 'shared';

import { AmbassadorialSystem } from './ambassadorial-system';
import { BugBounty } from './bug-bounty';
import { Career } from './career';
import { EcoCommunity } from './eco-community';
import { Giveaway } from './giveaway';
import { Home } from './home';
import {
  EcosystemValuation,
  IntellectualProperty,
  IpCertification,
  IpPortionRoyalties,
  IpPortionSeedRounds,
  IpPortionsIssuance,
} from './intellectual-property';
import { MarketPortals } from './market-portals';
import { PressReleases } from './press-releases';
import { PrivacyPolicy } from './privacy-policy';
import { ProjectTeam } from './project-team';
import { Resources } from './resources';
import {
  BlockchainFeatures,
  ComparativeAnalysis,
  Ctvg,
  Delegator,
  Explorer,
  Latency,
  Litebacker,
  SmartChain,
  SmartChainScope,
  Staking,
  TurboBacker,
  Validator,
  Wallet,
} from './smart-chain';
import { TermsAndConditions } from './terms-and-conditions';
import { BiennialLayer, Distribution, EarnWithCrat, Tokenomics } from './tokenomics';
import { UpcomingEvents } from './upcoming-events';
import { UseCases } from './use-cases';
import { VcAngel } from './vc-angel';

// TODO: rollback lazy loading when detect the problem in it (app's modals dont work)
export const RouteManager: FC = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to={appRoutes.home.root.path} />} />
      <Route element={<SmartChain />} path={appRoutes.smartchain.root.path} />
      <Route element={<IntellectualProperty />} path={appRoutes.intellectualProperty.root.path} />
      <Route element={<IpCertification />} path={appRoutes.intellectualProperty.children?.ipCertification.root.path} />
      <Route
        element={<EcosystemValuation />}
        path={appRoutes.intellectualProperty.children?.ecosystemValuation.root.path}
      />
      <Route
        element={<IpPortionRoyalties />}
        path={appRoutes.intellectualProperty.children?.ipPortionRoyalties.root.path}
      />
      <Route
        element={<IpPortionsIssuance />}
        path={appRoutes.intellectualProperty.children?.ipPortionsIssuance.root.path}
      />
      <Route
        element={<IpPortionSeedRounds />}
        path={appRoutes.intellectualProperty.children?.ipPortionSeedRounds.root.path}
      />
      <Route element={<MarketPortals />} path={appRoutes.marketPortals.root.path} />
      <Route element={<Tokenomics />} path={appRoutes.tokenomics.root.path} />
      <Route element={<Giveaway />} path={appRoutes.giveaway.root.path} />
      <Route element={<BlockchainFeatures />} path={appRoutes.smartchain.children?.blockchainFeatures.root.path} />
      <Route element={<SmartChainScope />} path={appRoutes.smartchain.children?.scope.root.path} />
      <Route element={<Latency />} path={appRoutes.smartchain.children?.latency.root.path} />
      <Route element={<Staking />} path={appRoutes.smartchain.children?.staking.root.path} />
      <Route element={<Validator />} path={appRoutes.smartchain.children?.validator.root.path} />
      <Route element={<Delegator />} path={appRoutes.smartchain.children?.delegator.root.path} />
      <Route element={<Litebacker />} path={appRoutes.smartchain.children?.litebacker.root.path} />
      <Route element={<TurboBacker />} path={appRoutes.smartchain.children?.turbobacker.root.path} />
      <Route element={<Ctvg />} path={appRoutes.smartchain.children?.ctvg.root.path} />
      <Route element={<UseCases />} path={appRoutes.useCases.root.path} />
      <Route element={<Distribution />} path={appRoutes.tokenomics.children?.distribution.root.path} />
      <Route element={<ProjectTeam />} path={appRoutes.connect.children?.projectTeam.root.path} />
      <Route element={<ComparativeAnalysis />} path={appRoutes.smartchain.children?.comparativeAnalysis.root.path} />
      <Route element={<Wallet />} path={appRoutes.smartchain.children?.wallet.root.path} />
      <Route element={<Explorer />} path={appRoutes.smartchain.children?.explorer.root.path} />
      <Route element={<VcAngel />} path={appRoutes.connect.children?.vcAngel.root.path} />
      <Route element={<Career />} path={appRoutes.connect.children?.career.root.path} />
      <Route element={<BiennialLayer />} path={appRoutes.tokenomics.children?.biennialLayer.root.path} />
      <Route element={<EarnWithCrat />} path={appRoutes.tokenomics.children?.earning.root.path} />
      <Route element={<EcoCommunity />} path={appRoutes.connect.children?.ecoCommunity.root.path} />
      <Route element={<BugBounty />} path={appRoutes.connect.children?.bugBounty.root.path} />
      <Route element={<TermsAndConditions />} path={appRoutes.termsAndConditions.root.path} />
      <Route element={<PrivacyPolicy />} path={appRoutes.privacyPolicy.root.path} />
      <Route element={<UpcomingEvents />} path={appRoutes.connect.children?.upcomingEvents.root.path} />
      <Route element={<PressReleases />} path={appRoutes.connect.children?.pressReleases.root.path} />
      <Route element={<AmbassadorialSystem />} path={appRoutes.connect.children?.ambassadorialSystem.root.path} />
      <Route element={<Resources />} path={appRoutes.resources.root.path} />
      <Route element={<Home />} path={appRoutes.home.root.path}>
        <Route path=":address" />
      </Route>
    </Routes>
  );
};
