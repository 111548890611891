import { Typography } from '@mui/material';
import { useCasesData, VideoPreviewCard } from 'shared';

export const BOTTOM_TEXT = `Overall, CratD2C's versatile ecosystem offers a wide range of use cases, each addressing specific pain points and inefficiencies in traditional systems across industries. With its innovative solutions powered by blockchain technology, CratD2C aims to revolutionize how businesses and individuals interact and transact in the digital age.`;
export const intro = {
  paragraph: `CratD2C, with its innovative blockchain technology, presents many compelling and diverse use cases across various industries. Here's a comprehensive breakdown of the use cases:`,
};

export const list = [
  {
    title: useCasesData[0].subtitle,
    description: (
      <>
        <Typography variant="body2" className="font-secondary secondary" fontSize={14}>
          CratD2C&apos;s decentralized e-commerce trading platform allows businesses to connect directly with consumers,
          eliminating intermediaries. This fosters transparency, reduces costs, and enhances security in online
          transactions.
        </Typography>

        <VideoPreviewCard
          subtitle={useCasesData[0].subtitle}
          image={useCasesData[0].image}
          videoSrc={useCasesData[0].src}
          info={useCasesData[0].info}
          boxSxProps={{ marginTop: 3 }}
        />
      </>
    ),
  },
  {
    title: useCasesData[2].subtitle,
    description: (
      <>
        <Typography variant="body2" className="font-secondary secondary" fontSize={14}>
          The platform facilitates transparent and secure real estate transactions by leveraging smart contracts and
          blockchain technology. It streamlines property listing, transaction management, and title verification
          processes, thus increasing efficiency and reducing fraud.
        </Typography>
        <VideoPreviewCard
          subtitle={useCasesData[2].subtitle}
          image={useCasesData[2].image}
          videoSrc={useCasesData[2].src}
          info={useCasesData[2].info}
          boxSxProps={{ marginTop: 3 }}
        />
      </>
    ),
  },
  {
    title: useCasesData[1].subtitle,
    description: (
      <>
        <Typography variant="body2" className="font-secondary secondary" fontSize={14}>
          CRAT Luxury Lifestyle booking portal allows users to book exclusive experiences such as hotel stays, travel
          packages, cruises, and fine dining using CRAT Native Coins to complete transactions. The decentralized nature
          of the platform ensures privacy, security, and seamless transactions.
        </Typography>
        <VideoPreviewCard
          subtitle={useCasesData[1].subtitle}
          image={useCasesData[1].image}
          videoSrc={useCasesData[1].src}
          info={useCasesData[1].info}
          boxSxProps={{ marginTop: 3 }}
        />
      </>
    ),
  },
  {
    title: useCasesData[3].subtitle,
    description: (
      <>
        <Typography variant="body2" className="font-secondary secondary" fontSize={14}>
          CratD2C&apos;s native coin serves as a means of payment within the ecosystem, allowing users to make
          purchases, pay for services, and transfer funds securely and efficiently. Integrating blockchain technology
          enhances transaction speed, reduces fees, and enables cross-border payments.
        </Typography>
        <VideoPreviewCard
          subtitle={useCasesData[3].subtitle}
          image={useCasesData[3].image}
          videoSrc={useCasesData[3].src}
          info={useCasesData[3].info}
          boxSxProps={{ marginTop: 3 }}
        />
      </>
    ),
  },
  {
    title: 'Supply Chain Management',
    description: `The platform's blockchain-based supply chain management solution enhances transparency and traceability in supply chain operations. It enables businesses to track the movement of goods, verify product authenticity, and streamline logistics processes.`,
  },
  {
    title: 'Identity Management',
    description:
      'CratD2C incorporates decentralized identity solutions to enable users to securely control and manage their digital identities. This eliminates the need for centralized identity verification authorities and reduces the risk of identity theft and fraud.',
  },
  {
    title: 'Tokenization of Assets',
    description:
      'CratD2C allows for the tokenization of real-world assets such as real estate properties, artworks, and intellectual property rights. This fractional ownership model enables asset owners to unlock liquidity, facilitate asset transfer, and democratize access to investment opportunities.',
  },
  {
    title: 'Governance and Voting',
    description: `Through coin-based voting, the platform's governance model enables coin holders to participate in decision-making processes such as protocol upgrades, fee structures, and community initiatives. This ensures democratic governance and community involvement in the platform's development.`,
  },
  {
    title: 'Intellectual Property Management',
    description:
      'CratD2C provides a platform for creators and innovators to register, protect, and monetize their intellectual property assets using blockchain technology. This enhances copyright protection, facilitates royalty distribution, and promotes fair compensation for creators.',
  },
  {
    title: 'Data Security and Privacy',
    description: `By leveraging blockchain's immutable and tamper-proof ledger, CratD2C ensures the security and privacy of user data and transactions. This protects sensitive information from unauthorized access, data breaches, and manipulation.`,
  },
];
