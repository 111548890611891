import { Icon } from './icon';
import { getWcConnector } from './utils';

export const wcId = 'walletConnect';
export const wcName = 'WalletConnect';

type Params = {
  walletconnectProjectId: string;
};

export const WalletConnect = ({ walletconnectProjectId }: Params) => ({
  walletName: wcName,
  walletId: wcId,
  type: getWcConnector.type,
  Icon,
  createConnectorFn: getWcConnector({ projectId: walletconnectProjectId }),
});
