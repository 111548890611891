import { useCallback, useRef, useState } from 'react';
import { useConnectModal, useWagmiAuth } from 'app/providers';
import { useBreakpoints } from 'shared';

import { DesktopButton, LogoutPopover, MobileButton } from './ui';

export const ConnectButton = () => {
  const [anchorElLogout, setAnchorElLogout] = useState<HTMLButtonElement | null>(null);
  const { state } = useWagmiAuth();
  const { open } = useConnectModal();
  const { isDownMdLayout } = useBreakpoints();
  const anchorEl = useRef<HTMLButtonElement | null>(null);

  const isLogoutOpen = Boolean(anchorElLogout);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (state.isAuthorized) {
        setAnchorElLogout(event.currentTarget);
      } else {
        open();
      }
    },
    [open, state.isAuthorized],
  );

  const handleCloseLogout = useCallback(() => {
    setAnchorElLogout(null);
  }, []);

  return (
    <>
      {isDownMdLayout ? (
        <MobileButton ref={anchorEl} onClick={handleClick} />
      ) : (
        <DesktopButton ref={anchorEl} onClick={handleClick} />
      )}
      <LogoutPopover anchor={anchorElLogout} isOpen={isLogoutOpen} onClose={handleCloseLogout} />
    </>
  );
};
