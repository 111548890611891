import { forwardRef, useCallback, useMemo } from 'react';
import { Button, styled } from '@mui/material';
import { BORDER_RADIUS_M, getDevice, openWindow, useDisconnect } from 'shared';
import { useAccount, useConnect } from 'wagmi';

import type { WalletAdapterData } from '../../types';

const StyledButton = styled(Button)(({ theme }) => ({
  boxSizing: 'content-box',
  textAlign: 'center',
  fontWeight: 400,
  fontSize: 14,
  padding: theme.spacing(1),
  borderRadius: BORDER_RADIUS_M,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  svg: {
    borderRadius: '50%',
    width: 40,
    height: 40,
  },
})) as typeof Button;

const StyledTitle = styled('span')({
  overflow: 'hidden',
  maxWidth: '100%',
  textOverflow: 'ellipsis',
  color: 'currentcolor',
});

type Props = Pick<WalletAdapterData, 'walletName' | 'deeplink' | 'detector' | 'shouldUseDetector'> & {
  Icon: WalletAdapterData['Icon'];
  onConnect?: () => void | Promise<void>;
  connector: WalletAdapterData['createConnectorFn'];
};

export const WalletButton = forwardRef<HTMLButtonElement, Props>(
  ({ connector, onConnect, walletName, deeplink, Icon, detector, shouldUseDetector }, ref) => {
    const { isConnected } = useAccount();
    const { disconnectAsync } = useDisconnect();
    const { connectAsync } = useConnect();

    const { isMobile, isTablet } = useMemo(getDevice, []);

    const handleClick = useCallback(async () => {
      /** if detector isn't present it means it should be supported by default */
      const isDetected = detector?.() ?? true;

      if (!isDetected && (isMobile || isTablet) && deeplink) {
        openWindow(deeplink);

        return;
      }

      if (!isDetected && shouldUseDetector) {
        return;
      }

      if (isConnected) {
        await disconnectAsync();
      }

      await connectAsync({ connector });
      await onConnect?.();
    }, [
      isConnected,
      connector,
      detector,
      onConnect,
      disconnectAsync,
      connectAsync,
      isMobile,
      isTablet,
      shouldUseDetector,
      deeplink,
    ]);

    return (
      <StyledButton color="secondary" onClick={handleClick} ref={ref}>
        <Icon />
        <StyledTitle>{walletName}</StyledTitle>
      </StyledButton>
    );
  },
);
