/* eslint-disable no-nested-ternary */
import { useDispatch } from 'react-redux';
import { alpha, Box, IconButton, Stack, styled } from '@mui/material';
import { modalModel } from 'entities/modal';
import { COLOR_ACCENT, COLOR_BG, COLOR_STROKE, CratLogoAnimated, useBreakpoints } from 'shared';
import { GRADIENT_ACCENT, TRANSITION_300 } from 'shared/config/theme/common';

import { innerCircleButtons, mainCircleButtons } from './constants';

const CIRCLE_DIRAMETRE_LG = 675;
const CIRCLE_DIRAMETRE_MD = 470;
const CIRCLE_DIRAMETRE_SM = 295;

const INNER_CIRCLE_DIRAMETRE_LG = 471;
const INNER_CIRCLE_DIRAMETRE_MD = 300;
const INNER_CIRCLE_DIRAMETRE_SM = 206;

const GREEN_CIRCLE_DIRAMETRE_LG = 211;
const GREEN_CIRCLE_DIRAMETRE_MD = 140;
const GREEN_CIRCLE_DIRAMETRE_SM = 0;

const LOGO_CIRCLE_DIRAMETRE_LG = 158;
const LOGO_CIRCLE_DIRAMETRE_MD = 90;
const LOGO_CIRCLE_DIRAMETRE_SM = 69;

const CircleIconButtonWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'angle' && prop !== 'circleWidth',
})<{ angle: number; circleWidth: number }>(({ theme, angle, circleWidth }) => {
  return {
    transform: `rotate(${angle}deg) translate(${circleWidth / 2}px) rotate(-${angle}deg)`,
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 72,
    height: 72,
    margin: -72 / 2,

    [theme.breakpoints.down('md')]: {
      width: 48,
      height: 48,
      margin: -48 / 2,
    },

    [theme.breakpoints.down('sm')]: {
      width: 32,
      height: 32,
      margin: -32 / 2,
    },
  };
});

export const CratCircle = () => {
  const dispatch = useDispatch();
  const { isDownMdLayout, isDownSmLayout } = useBreakpoints();
  const circleWidth = isDownSmLayout ? CIRCLE_DIRAMETRE_SM : isDownMdLayout ? CIRCLE_DIRAMETRE_MD : CIRCLE_DIRAMETRE_LG;

  const innerCircleWidth = isDownSmLayout
    ? INNER_CIRCLE_DIRAMETRE_SM
    : isDownMdLayout
    ? INNER_CIRCLE_DIRAMETRE_MD
    : INNER_CIRCLE_DIRAMETRE_LG;

  const greenCircleWidth = isDownSmLayout
    ? GREEN_CIRCLE_DIRAMETRE_SM
    : isDownMdLayout
    ? GREEN_CIRCLE_DIRAMETRE_MD
    : GREEN_CIRCLE_DIRAMETRE_LG;

  const logoCircleWidth = isDownSmLayout
    ? LOGO_CIRCLE_DIRAMETRE_SM
    : isDownMdLayout
    ? LOGO_CIRCLE_DIRAMETRE_MD
    : LOGO_CIRCLE_DIRAMETRE_LG;

  let angle = 360 - 90;
  const dangle = 360 / mainCircleButtons.length;

  let innerAngle = 360 - 90;
  const innerDangle = 360 / innerCircleButtons.length;

  const openModal = (
    type: Exclude<
      modalModel.ModalType,
      modalModel.ModalType.SendSuccess | modalModel.ModalType.SendReject | modalModel.ModalType.ApproveReject
    >,
  ) => {
    dispatch(
      modalModel.actions.openModal({
        type,
      }),
    );
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Box
        sx={{
          width: circleWidth,
          height: circleWidth,
          borderRadius: '50%',
          background: GRADIENT_ACCENT,
          padding: '3px',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            background: COLOR_BG,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: innerCircleWidth,
              height: innerCircleWidth,
              borderRadius: '50%',
              background: 'transparent',
              border: '2px dashed',
              borderColor: COLOR_STROKE,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: greenCircleWidth,
                height: greenCircleWidth,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                '&:before': {
                  position: 'absolute',
                  content: '""',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  background: alpha(COLOR_ACCENT, 0.23),
                  filter: 'blur(71px)',
                },
              }}
            >
              <Box
                onClick={() => openModal(modalModel.ModalType.CratNew)}
                sx={{
                  cursor: 'pointer',
                  transition: TRANSITION_300,
                  position: 'relative',
                  width: logoCircleWidth,
                  height: logoCircleWidth,
                  borderRadius: '50%',
                  background: COLOR_BG,
                  border: '1px solid',
                  borderColor: COLOR_STROKE,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexShrink: 0,
                  '&:hover': {
                    transform: 'scale(1.35)',
                  },
                }}
              >
                <Box
                  component="img"
                  src={CratLogoAnimated}
                  sx={{
                    width: { md: 67, sm: 46, xs: 28 },
                  }}
                />
              </Box>
            </Box>
            {innerCircleButtons.map((btn) => {
              innerAngle += innerDangle;

              return (
                <CircleIconButtonWrapper key={btn.modal} angle={innerAngle} circleWidth={innerCircleWidth}>
                  <IconButton
                    onClick={() => openModal(btn.modal)}
                    className="scale rounded"
                    color="secondary"
                    sx={{
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <btn.Icon
                      sx={{
                        width: { md: '100%', sm: '22px', xs: '18px' },
                      }}
                    />
                  </IconButton>
                </CircleIconButtonWrapper>
              );
            })}
          </Box>
          {mainCircleButtons.map((btn) => {
            angle += dangle;

            return (
              <CircleIconButtonWrapper key={btn.modal} angle={angle} circleWidth={circleWidth}>
                <IconButton
                  onClick={() => openModal(btn.modal)}
                  className="scale rounded"
                  color="secondary"
                  sx={{
                    width: '100%',
                    height: '100%',
                    fontSize: 10,
                  }}
                >
                  <btn.Icon
                    sx={{
                      width: { md: '100%', sm: '22px', xs: btn.size === 'small' ? '12px' : '17px' },
                    }}
                  />
                </IconButton>
              </CircleIconButtonWrapper>
            );
          })}
        </Box>
      </Box>
    </Stack>
  );
};
