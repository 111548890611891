import { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { TextExpander } from 'shared';

export const AboutText = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleTextExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <>
      <Typography variant="h3" fontSize={26} maxWidth={800} whiteSpace="pre-line">
        <TextExpander
          isExpanded={isExpanded}
          maxLength={326}
          text={`CratD2C represents a pioneering platform in the blockchain space, combining the robustness of Delegated Proof of Stake (DPoS) with Layer-1 architecture to achieve a new standard in security, speed, and scalability. At its core, CratD2C transforms e-commerce by facilitating direct connections between businesses and consumers. This is achieved through a secure, transparent, cost-effective smart contract system built on its DPoS blockchain framework.
          
          What sets CratD2C apart is its comprehensive ecosystem, which extends far beyond traditional blockchain applications. It's not just a blockchain network; it's a dynamic hub for decentralized applications (dApps) and diverse marketplaces. The ecosystem encompasses three unique portals: an e-commerce trading portal, a real estate trading portal, and a luxury lifestyle booking portal. Additionally, it features a universal payment gateway, including a crypto debit card, integrating the convenience of traditional financial services into the blockchain world.
          
          Each of these portals operates with the blockchain's native coin as a means of settlement, ensuring transactions are not only swift but also transparent and secure, thanks to the inherent qualities of blockchain technology. This integration across various sectors showcases CratD2C's versatility and its potential to revolutionize operations in e-commerce, real estate, luxury services, and beyond, marking a significant step forward in the practical application of blockchain technology in everyday business and consumer activities.`}
        />
      </Typography>
      <Button
        onClick={toggleTextExpand}
        variant="text"
        className="secondary"
        size="medium"
        sx={{
          mt: { sm: 3, xs: 2 },
          mb: { xs: 4, sm: 0 },
          '& > *': {
            textDecoration: 'underline',
          },
        }}
      >
        <span>{isExpanded ? 'Roll up' : 'Read more'}</span>
      </Button>
    </>
  );
};
