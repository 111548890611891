import { useEffect } from 'react';
import { Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { useWagmiAuth } from 'app/providers';
import { giveawayModel } from 'entities/giveaway';
import { ReferralBlock } from 'features/referral';
import { useAppDispatch, useShallowSelector } from 'shared';

export const ReferralSection = () => {
  const dispatch = useAppDispatch();
  const { state: authState } = useWagmiAuth();
  const { referralPointsEarned, referralCount } = useShallowSelector(giveawayModel.selectors.getGiveaway);

  useEffect(() => {
    if (authState.address) dispatch(giveawayModel.thunks.getReferrals());
  }, [dispatch, authState.address]);

  return (
    <Grid container>
      <Grid item xs={12} md={2} mb={{ xs: 3, md: 0 }}>
        <Typography variant="h4" className="secondary">
          03. Referral link
        </Typography>
      </Grid>
      <Grid item container xs={12} md={10} columnSpacing={5} rowSpacing={2}>
        <Grid item xs={12} md={5}>
          <ReferralBlock />
        </Grid>
        <Grid item xs={12} md={7}>
          <Card sx={{ display: 'flex', flexDirection: 'row', p: 3, justifyContent: 'space-around', height: '100%' }}>
            <Stack justifyContent="center" alignItems="center">
              <Typography className="secondary">Referrals invited</Typography>
              <Typography variant="h3" className="no-resize">
                {referralCount}
              </Typography>
            </Stack>
            <Divider flexItem orientation="vertical" />
            <Stack justifyContent="center" alignItems="center">
              <Typography className="secondary">Points earned</Typography>

              <Typography variant="h3" className="no-resize">
                +{referralPointsEarned}
              </Typography>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
