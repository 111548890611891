/* eslint-disable @typescript-eslint/ban-ts-comment */
import { metaMask } from 'wagmi/connectors';

import { Icon } from './icon';

export const metaMaskId = 'metaMask';
export const metaMaskName = 'MetaMask';

const currentHref = globalThis.window
  ? globalThis.window.location.hostname + globalThis.window.location.pathname // encoding not supported
  : '';

const getMetaMaskConnector = () => metaMask({ extensionOnly: true });

const detector = () => {
  // @ts-ignore
  return Boolean(
    globalThis.window?.ethereum?.isMetaMask || globalThis.window?.ethereum?.providerMap?.has?.('MetaMask'),
  );
};

export const MetaMask = () => ({
  walletName: metaMaskName,
  walletId: metaMaskId,
  type: metaMask.type,
  Icon,
  detector,
  deeplink: `https://metamask.app.link/dapp/${currentHref}`,
  createConnectorFn: getMetaMaskConnector(),
});
