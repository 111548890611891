export const CALCULATOR_BOTTOM_TEXT =
  "Validators are the backbone of the CratD2C ecosystem, ensuring secure and efficient transaction processing, maintaining consensus, and upholding the blockchain's integrity. Their role contributes to the network's overall health, stability, and success, making them indispensable to CratD2C's Decentralized Autonomous Smart Chain.";

export const intro = {
  paragraph:
    "In the expansive ecosystem of CratD2C Decentralized Autonomous Smart Chain, the role of a Validator holds a paramount position. Validators play a critical role in maintaining the network's security, consensus, and integrity. Their responsibilities are pivotal to the seamless functioning of the blockchain and its various applications.",
  subtitle: (
    <>
      At its core, a Validator is tasked with validating and confirming transactions on the CratD2C blockchain. When
      users initiate transactions, Validators are responsible for verifying the accuracy and legitimacy of these
      transactions before they are added to the blockchainvs distributed ledger. This verification process ensures that
      the data stored on the blockchain is accurate and trustworthy. <br /> <br /> Validators also play a significant
      role in achieving consensus within the network. Consensus is a crucial mechanism in blockchain technology,
      ensuring that all participants in the network agree on the state of the blockchain. Validators participate in this
      consensus process by agreeing on the validity of transactions and adding them to the blockchain synchronised. This
      consensus mechanism enhances security and prevents any malicious or fraudulent activities. <br /> <br /> One of
      the defining features of Validators within CratD2C is their commitment to network security. Validators are
      required to stake a minimum amount of CRAT Coins as collateral. This stake serves as a form of commitment and
      accountability. If a Validator acts maliciously or behaves against the network&#39;s interests, a portion of their
      stake can be slashed as a penalty. This incentive structure ensures that Validators are vested in the
      network&#39;s security and reliability. <br /> <br /> In return for their crucial role in maintaining security and
      consensus, Validators are rewarded with various benefits. Based on their staked coins, they receive a staking
      reward of 15% Annual Percentage Rate (APR). Validators also earn transaction fees generated on the blockchain
      according to the number of coins staked in their pool, adding to their overall rewards. This incentive structure
      encourages participation and competition among Validators, ultimately enhancing the network&#39;s overall security
      and efficiency. <br /> <br /> The role of a Validator is not only technically demanding but also strategically
      important. Validators need to strike a balance between network security and efficiency. They must ensure that
      transactions are validated swiftly without compromising the network&#39;s integrity. This requires technical
      expertise, infrastructure, and a deep understanding of the blockchain&#39;s operations.
    </>
  ),
};

export const list = [
  {
    title: 'Key Role in Network Security',
    description:
      "Validators are at the forefront of maintaining the blockchain's security. By validating and confirming transactions, they ensure the network remains secure against fraudulent or malicious activities. Their critical function in verifying the accuracy and legitimacy of transactions upholds the blockchain's trustworthiness and reliability.",
  },
  {
    title: 'Central to Achieving Consensus',
    description:
      "Validators are instrumental in the consensus mechanism, a core aspect of blockchain technology. This mechanism ensures all network participants agree on the blockchain's current state, enhancing the network's stability and security. Through their participation in the consensus process, Validators help synchronize and validate transactions, maintaining the network's integrity.",
  },
  {
    title: 'Staking Rewards and Transaction Fees',
    description:
      'Validators receive financial incentives for their contributions, including a 15% Annual Percentage Rate (APR) on their staked coins and earnings from transaction fees generated on the blockchain. This incentive structure rewards their crucial role in the network and encourages healthy competition and participation, leading to enhanced network performance.',
  },
  {
    title: "Stake as a Commitment to Network's Interests",
    description:
      "The requirement for Validators to stake a minimum amount of CRAT Coins acts as a form of commitment to the network's well-being. This staked amount serves as collateral, ensuring Validators act in the network's best interest. It introduces an accountability mechanism where Validators risk having their stake slashed if they act maliciously, further securing the network.",
  },
  {
    title: 'Technical Expertise and Strategic Importance',
    description:
      "The role of a Validator demands a high level of technical knowledge and strategic foresight. Validators must efficiently balance network security and transaction validation speed, requiring a deep understanding of blockchain operations and robust infrastructure. This balance is crucial for the network's efficiency and reliability, highlighting the strategic importance of Validators in the CratD2C ecosystem.",
  },
];
