import { Box, Stack, styled, Typography } from '@mui/material';
import { FontWeights } from 'shared/config';
import { team } from 'shared/constants';

import { Icons } from '..';

const ImageWrapper = styled(Box)(({ theme }) => ({
  width: 200,
  height: 272,
  borderRadius: 20,
  overflow: 'hidden',
  position: 'relative',
  '&.right': {
    marginLeft: 250,
    [theme.breakpoints.only('md')]: {
      marginLeft: 230,
    },
  },
}));

const Gradient = styled(Box)(() => ({
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 56.16%, rgba(15, 10, 19, 0.5) 100%)',
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 1,
  top: 0,
  left: 0,
}));

const Name = styled(Typography)(() => ({
  fontWeight: FontWeights.SemiBold,
  lineHeight: '20px',
  width: 137,
}));

type TeamCardProps = (typeof team)[number];

export const TeamCard = ({ name, image, link, align = 'left' }: TeamCardProps) => {
  return (
    <ImageWrapper className={align}>
      <Box component="img" src={image} sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      <Stack
        component="a"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        position="absolute"
        bottom={0}
        left={0}
        zIndex={2}
        px="10px"
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        width="100%"
        height={43}
      >
        <Name>{name}</Name>
        <Icons.LinkedinSecondary />
      </Stack>
      <Gradient />
    </ImageWrapper>
  );
};
