import React from 'react';
import { Box } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { FontWeights, Icons, ModalSidebar } from 'shared';

export interface IntellectualPropertyModalPayload {
  data?: null;
  type: ModalType.IntellectualProperty;
  isOpen?: boolean;
}

export const IntellectualPropertyModal = React.forwardRef<
  HTMLElement,
  Pick<IntellectualPropertyModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar
      isOpen={isOpen}
      ref={ref}
      onClose={onClose}
      title="Intellectual Property"
      icon={<Icons.IntellectualProperty />}
    >
      <Box component="p" fontWeight={FontWeights.SemiBold}>
        The intellectual property associated with CratD2C&apos;s Decentralized Autonomous Smart Chain represents a
        significant achievement in the technological domain, reflecting a meticulous and strategic approach to
        safeguarding innovation. This body of work, recognized and protected under the auspices of the International
        Online Copyright Office (INTEROCO), headquartered in the European Union, Berlin, Germany, encapsulates a
        comprehensive spectrum of intellectual property considerations.
      </Box>
      <br />
      <Box component="p">
        These considerations range from the foundational aspects of scientific contribution, through detailed
        annotations, to the legal frameworks that underpin the protection of such a novel creation.
      </Box>
    </ModalSidebar>
  );
});
