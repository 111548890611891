import { Inputs } from '../types';

const URL = 'https://cratd2csmartchain.io/api/v1/feedback/';

export const sendFeedback = async (data: Inputs) => {
  const body = Object.entries(data).reduce<Record<string, string>>((acc, [key, value]) => {
    if (!value) {
      return acc;
    }

    acc[key] = value;

    return acc;
  }, {});

  const res = await fetch(URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  return res.ok;
};
