import { ReactNode } from 'react';
import { Stack, StackProps, Typography } from '@mui/material';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_STROKE } from 'shared';

export interface RowItemProps {
  title: string | ReactNode;
  description?: string | ReactNode;
  subitems?: { subTitle: string; subDescription: string }[];
  textColor?: string;
  titleColor?: string;
  titleVariant?: 'h4' | 'h5';
}

export const RowItem = ({
  title,
  description,
  subitems,
  textColor = COLOR_PRIMARY,
  titleColor = COLOR_SECONDARY,
  titleVariant = 'h5',
  ...stackProps
}: RowItemProps & Omit<StackProps, 'title'>) => {
  return (
    <Stack
      paddingTop={3}
      direction="row"
      justifyContent="space-between"
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        borderTop: `1px solid ${COLOR_STROKE}`,
      }}
      {...stackProps}
    >
      <Typography
        variant={titleVariant}
        className="secondary"
        sx={{
          gridColumn: { xs: 'span 12', md: '1 / 5' },
          color: `${titleColor} !important`,
        }}
      >
        {title}
      </Typography>
      {description && (
        <Typography
          variant="body2"
          sx={{
            mt: { xs: 3, md: 0 },
            color: textColor,
            gridColumn: { xs: 'span 12', md: '7 / 13' },
          }}
        >
          {description}
        </Typography>
      )}
      {subitems && (
        <Stack spacing={4} sx={{ mt: { xs: 3, md: 0 }, gridColumn: { xs: 'span 12', md: '7 / 13' } }}>
          {subitems.map(({ subTitle, subDescription }) => (
            <Stack
              key={subTitle}
              direction="row"
              justifyContent="space-between"
              sx={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)' }}
            >
              <Typography className="semi-bold" sx={{ gridColumn: { xs: 'span 12', md: '1 / 3' } }}>
                {subTitle}
              </Typography>
              <Typography
                variant="body2"
                color={COLOR_SECONDARY}
                sx={{ mt: { xs: 2, md: 0 }, gridColumn: { xs: 'span 12', md: '4 / 13' } }}
              >
                {subDescription}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
