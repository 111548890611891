import React from 'react';
import { Box } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { FontWeights, Icons, ModalSidebar } from 'shared';

export interface BiennialSupplyMechanismModalPayload {
  data?: null;
  type: ModalType.BiennialSupplyMechanism;
  isOpen?: boolean;
}

export const BiennialSupplyMechanismModal = React.forwardRef<
  HTMLElement,
  Pick<BiennialSupplyMechanismModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar
      isOpen={isOpen}
      ref={ref}
      onClose={onClose}
      title="Biennial 8-Layer Zig-Zag Supply Mechanism"
      icon={<Icons.BiennialSupplyMechanism />}
    >
      <Box component="p" fontWeight={FontWeights.SemiBold}>
        CratD2C introduces an innovative supply mechanism designed to operate until 2039, underscoring its dedication to
        sustainability, fairness, and community involvement. This pioneering approach ensures a regulated and equitable
        distribution of CRAT native coins, heralding a new era in the blockchain landscape.
      </Box>
      <br />
      <Box component="p">
        The CratD2C Decentralized Autonomous Smart Chain unveils the revolutionary Biennial 8-Layer Zig-Zag Supply
        Mechanism, marking a significant advancement in blockchain technology. This mechanism represents a paradigm
        shift, offering numerous benefits that differentiate it from existing projects and establish a new standard for
        sustainability, fairness, and community engagement.
      </Box>
      <br />
      <Box component="p">
        The 8-Layer Zig-Zag Supply Mechanism is meticulously crafted to facilitate a balanced and controlled
        distribution of CRAT native coins among participants. Comprising eight distinct layers, each serves a specific
        purpose while ensuring overall ecosystem equilibrium. Let&apos;s explore this mechanism&apos;s detailed
        explanation and its unique advantages.
      </Box>
    </ModalSidebar>
  );
});
