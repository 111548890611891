import {
  Benzinga,
  Bloomsburg,
  Fox47,
  GlobeNewswire,
  GoldStocks,
  PennyStocks,
  StocksNow,
  YahooFinance,
  YahooTech,
} from 'shared';

export interface IPress {
  name: string;
  image: string;
  paragraph: string;
  link: string;
  height: number;
}

export const pressReleases: IPress[] = [
  {
    name: 'Yahoo Finance',
    image: YahooFinance,
    paragraph: `Revolutionizing Blockchain: Unleashing CratD2C's Game-Changing Potential and the Rising Global Interest in its ICO`,
    link: 'https://finance.yahoo.com/revolutionizing-blockchain-unleashing-cratd2cs-game-133000439.html',
    height: 33,
  },
  {
    name: 'Globe Newswire',
    image: GlobeNewswire,
    paragraph: `Revolutionizing Blockchain: Unleashing CratD2C's Game-Changing Potential and the Rising Global Interest in its ICO`,
    link: 'https://www.globenewswire.com/news-release/2024/01/25/2816777/0/en/Revolutionizing-Blockchain-Unleashing-CratD2C-s-Game-Changing-Potential-and-the-Rising-Global-Interest-in-its-ICO.html#:~:text=At%20the%20core%20of%20CratD2C,ideal%20for%20real%2Dworld%20applications',
    height: 43,
  },
  {
    name: 'Bloomberg University',
    image: Bloomsburg,
    paragraph: `Revolutionizing Blockchain: Unleashing CratD2C's Game-Changing Potential and the Rising Global Interest in its ICO`,
    link: 'https://www.stocktrak.com/quotes/quotes?clientname=bloomsburgtrading&qmodStoryID=8277067498497910',
    height: 49,
  },
  {
    name: 'Benzinga',
    image: Benzinga,
    paragraph: `Revolutionizing Blockchain: Unleashing CratD2C's Game-Changing Potential and the Rising Global Interest in its ICO`,
    link: 'https://www.benzinga.com/pressreleases/24/01/g36778948/revolutionizing-blockchain-unleashing-cratd2cs-game-changing-potential-and-the-rising-global-inter',
    height: 22,
  },
  {
    name: 'Pennystocks',
    image: PennyStocks,
    paragraph: `Revolutionizing Blockchain: Unleashing CratD2C's Game-Changing Potential and the Rising Global Interest in its ICO`,
    link: 'https://pennystocks.com/ticker/?qmodStoryID=8277067498497910#tabs-8',
    height: 29,
  },
  {
    name: 'Goldstocks',
    image: GoldStocks,
    paragraph: `Revolutionizing Blockchain: Unleashing CratD2C's Game-Changing Potential and the Rising Global Interest in its ICO`,
    link: 'https://goldstocks.com/ticker/?qmodStoryID=8277067498497910#tabs-8',
    height: 31,
  },
  {
    name: 'StocksToBuyNow',
    image: StocksNow,
    paragraph: `Revolutionizing Blockchain: Unleashing CratD2C's Game-Changing Potential and the Rising Global Interest in its ICO`,
    link: 'https://www.stockpulse.com/Profile/HIVE:CA/article/8277067498497910',
    height: 40,
  },
  {
    name: 'fox47',
    image: Fox47,
    paragraph: `Revolutionizing Blockchain: Unleashing CratD2C's Game-Changing Potential and the Rising Global Interest in its ICO`,
    link: 'https://fox47.marketminute.com/article/globeprwire-2024-1-25-revolutionizing-blockchain-unleashing-cratd2cs-game-changing-potential-and-the-rising-global-interest-in-its-ico',
    height: 45,
  },
  {
    name: 'Yahoo Tech',
    image: YahooTech,
    paragraph: `Revolutionizing Blockchain: Unleashing CratD2C's Game-Changing Potential and the Rising Global Interest in its ICO`,
    link: 'https://finance.yahoo.com/tech/revolutionizing-blockchain-unleashing-cratd2cs-game-133000439.html',
    height: 40,
  },
];
