import { IconButton } from '@mui/material';
import { COLOR_STROKE, Icons, usePopover } from 'shared';

import { TipPopover } from '../tip-popover';

export const ReferalTip = () => {
  const { handleClose, handleOpen, open, anchorEl } = usePopover();

  return (
    <>
      <IconButton className="transparent" size="small" onClick={handleOpen}>
        <Icons.InfoCircle
          sx={{
            color: COLOR_STROKE,
          }}
        />
      </IconButton>
      <TipPopover open={open} onClose={handleClose} anchorEl={anchorEl}>
        You will earn 10% in USDT from all purchases of your referrals.
      </TipPopover>
    </>
  );
};
