import { wagmiConfig } from 'app/config/wallet';
import { api, getToastMessage } from 'shared';
import { signMessage } from 'wagmi/actions';

const TARGET_URL =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://cratd2csmartchain.io/api'
    : 'https://crat3.rocknblock.io/api';

const BASE_URL = `${TARGET_URL}/v1/accounts/wallet`;

type GetMessageResponse = {
  message: string;
};

type ConnectResponse = {
  token: string;
};

export const getAuthToken = async (address: string, showNotification = true) => {
  const {
    data: { message: messageToSign },
  } = await api<GetMessageResponse>(`${BASE_URL}/get_message/`);

  if (showNotification) {
    getToastMessage('info', `Sign message in your wallet to procceed`);
  }

  const signedMessage = await signMessage(wagmiConfig, { message: messageToSign });

  const {
    data: { token },
  } = await api<ConnectResponse>(`${BASE_URL}/connect/`, {
    method: 'POST',
    data: {
      address,
      signed_msg: signedMessage,
      message: messageToSign,
    },
  });

  return token;
};
