import { useMemo } from 'react';
import { useAccount, useChains } from 'wagmi';

import { Writable } from './types';
import { normalizeData } from './utils';

export const useSupportedChains = () => {
  const chains = useChains();
  const { chainId } = useAccount();

  const normalizedChains = useMemo(() => normalizeData(chains as Writable<typeof chains>, 'id'), [chains]);

  return useMemo(
    () => ({
      isSupported: Boolean(normalizedChains[chainId ?? chains[0].id]),
      chainId,
      chain: normalizedChains[chainId ?? chains[0].id],
      chains,
      normalizedChains,
    }),
    [chains, chainId],
  );
};
