/* eslint-disable @typescript-eslint/no-explicit-any */
import { CHAINS } from 'app/config/wallet';
import type { Chain } from 'viem';
import type { Config, CreateConnectorFn } from 'wagmi';
import { connect, disconnect } from 'wagmi/actions';

export const connectToAdapter = async (
  config: Config,
  connector: CreateConnectorFn,
  supportedChains: readonly [Chain, ...Chain[]],
) => {
  const connectResult = await connect(config, { connector });
  const found = supportedChains.findIndex(({ id }) => id === connectResult.chainId) === -1;

  if (!connectResult || !found) {
    return connectResult;
  }

  await disconnect(config);

  if (config.state.current) {
    const activeConnector = config.state.connections.get(config.state.current)?.connector;

    if (activeConnector) {
      const provider: any = await activeConnector.getProvider();
      provider.once('chainChanged', () => globalThis.window?.location.reload());
    }
  }

  throw Error(`You've changed to wrong network. Please, choose ${CHAINS[0].name}`);
};
