import { Box, Typography } from '@mui/material';
import { COLOR_GRAY_5 } from 'shared';

export const ApiSection = () => {
  return (
    <Box>
      <Typography variant="h5" letterSpacing="-0.021em">
        API stands for Application Programming Interface, a programmatic instruction set allowing one program to
        interface. In other words, an API authorizes and integrates two applications to communicate to provide a
        seamless digital experience, payment or remittance.
      </Typography>
      <Box
        sx={{
          mt: 4.5,
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Box
          sx={{
            gridColumn: { xs: 'span 12', md: '4 / 13' },
          }}
        >
          <Typography>
            <Typography variant="body2" color={COLOR_GRAY_5} className="font-secondary">
              CratD2C payment API, also known as CratD2C Payment Gateway API or CratD2C Payment Processor, operates to
              integrate a payment solution with any other existing applications, such as connecting a business’s
              checkout function to the payment system and allowing business merchants to accept payments from customers
              on-site, avoiding the risk of shopping cart abandonment.
            </Typography>
            <br />
            <Typography variant="body2" color={COLOR_GRAY_5} className="font-secondary">
              Effecting business flexibility provides security and convenience for merchants and customers.
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
