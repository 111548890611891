export const BOTTOM_TEXT =
  "The CratD2C Blockchain Explorer is a powerful tool for users, developers, and researchers to interact with the CratD2C blockchain network. Its transparency and accessibility enhance the overall experience of engaging with the blockchain, whether for tracking transactions, verifying wallet balances, or exploring the network's activities in depth.";

export const intro = {
  paragraph:
    "The CratD2C Blockchain Explorer is a vital tool for users and developers to gain insight into the CratD2C blockchain network. It provides transparency, real-time data, and a user-friendly interface for exploring and understanding the blockchain's activities. Here are the key attributes and functionalities of the CratD2C Blockchain Explorer:",
};

export const list = [
  {
    title: 'Transaction Tracking',
    description:
      'Users can search for and view specific transactions by entering transaction IDs or wallet addresses. This feature allows users to monitor the flow of CRAT Coins and verify the status of their transactions.',
  },
  {
    title: 'Block Information',
    description:
      "The explorer displays details about each block on the CratD2C blockchain, including block height, timestamp, and the list of transactions included in the block. Users can navigate through different blocks to track the blockchain's history.",
  },
  {
    title: 'Wallet Balances',
    description:
      'Users can check the balances of specific wallet addresses, including their own, to see how many CRAT Coins are held in a particular wallet.',
  },
  {
    title: 'Coin and Smart Contract Information',
    description:
      'The explorer provides insights into CRAT coins and smart contracts deployed on the blockchain. Users can access information about coin supply, contract addresses, and coin transfers.',
  },
  {
    title: 'Transaction History',
    description:
      'Users can access a comprehensive history of all transactions on the CratD2C blockchain. This includes both incoming and outgoing transactions for any given wallet.',
  },
  {
    title: 'Validators, Delegators, and Backers',
    description:
      'The explorer offers data on Validators, Delegators, and Backers participating in the CratD2C network. Users can check the staking statistics, rewards, and performance of Validators, Delegators, LiteBackers, and TurboBackers.',
  },
  {
    title: 'Network Statistics',
    description:
      'Real-time statistics about the CratD2C blockchain network are available. This includes metrics like the total number of transactions, block times, transaction fees, and more.',
  },
  {
    title: 'IP-Portion Royalties',
    description:
      'Users can track the distribution of IP-Portion Royalties Payouts, seeing which holders receive royalties and the amounts distributed.',
  },
  {
    title: 'Search and Filters',
    description:
      'The explorer offers a search bar and filters, enabling users to refine their queries and easily locate specific transactions, blocks, or addresses.',
  },
  {
    title: 'Visualization',
    description:
      "Visual representations of the blockchain, such as graphs and charts, help users better understand the network's activities and trends.",
  },
  {
    title: 'API Access',
    description:
      "Developers can access the CratD2C Blockchain Explorer's API to integrate blockchain data into their applications, enabling a wide range of third-party services and tools.",
  },
  {
    title: 'User-Friendly Interface',
    description:
      'The explorer is designed to be intuitive and user-friendly, making it accessible to both novice and experienced users.',
  },
  {
    title: 'Real-Time Updates',
    description: 'The explorer provides real-time updates, ensuring users can access the most current blockchain data.',
  },
];
