import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Tasks: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path d="M8 19.5H4" stroke="#B4EB56" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16.25 12.75C18.597 12.75 20.5 14.653 20.5 17C20.5 19.347 18.597 21.25 16.25 21.25C13.903 21.25 12 19.347 12 17C12 14.653 13.903 12.75 16.25 12.75"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.903 16.174L15.837 18.24L14.597 17"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 14.5H4" stroke="#B4EB56" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 9.5H4" stroke="#B4EB56" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 4.5H4" stroke="#B4EB56" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);
