import { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';
import clsx from 'clsx';
import { BORDER_RADIUS_L, useModal } from 'shared';

import { NavTitle } from '..';

type NavLinkWithPopoverProps = {
  title: string;
  isTitleActive: boolean;
  navItems: { title: string; path?: string }[];
};
export const NavLinkWithPopover = ({ title, isTitleActive, navItems }: NavLinkWithPopoverProps) => {
  const [isMenuVisible, onOpenMenu, onCloseMenu] = useModal(false);
  const menuRef = useRef(null);

  return (
    <>
      <NavTitle className={clsx({ accent: isTitleActive })} ref={menuRef} onClick={onOpenMenu}>
        {title}
      </NavTitle>
      {isMenuVisible && (
        <Menu
          onClose={onCloseMenu}
          open={isMenuVisible}
          anchorEl={menuRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            sx: {
              mt: 1,
              p: 0,
              py: 1,
              borderRadius: BORDER_RADIUS_L,
              maxWidth: 242,
            },
          }}
          MenuListProps={{
            sx: {
              p: 0,
              gap: 0,
            },
          }}
          sx={{ p: 0 }}
        >
          {navItems.map((link) => (
            <MenuItem
              key={link.title}
              component={NavLink}
              to={link.path || ''}
              onClick={onCloseMenu}
              sx={{ borderRadius: 0, fontSize: 14, lineHeight: '21.7px', whiteSpace: 'normal' }}
            >
              {link.title}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
