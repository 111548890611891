import { ReactNode } from 'react';
import { Grid, GridProps, GridSpacing, Typography } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';

interface ListItemProps {
  number?: string | number;
  title: ReactNode;
  description: ReactNode;
  spacing?: ResponsiveStyleValue<GridSpacing>;
  childrenOpts?: {
    titleGridProps?: GridProps;
    descriptionGridProps?: GridProps;
  };
}

export const ListItem = ({ number, title, description, spacing = { md: 5, xs: 3 }, childrenOpts }: ListItemProps) => {
  const orderNum = `0${number}`.slice(-2);
  const withNumber = number !== undefined;
  return (
    <Grid container spacing={spacing} justifyContent="space-between">
      <Grid item {...(childrenOpts?.titleGridProps || { xs: 12, md: 4.5 })}>
        <Typography
          variant="h6"
          fontWeight={600}
          sx={[
            withNumber && {
              position: 'relative',
              paddingLeft: '35px',
              '&:before': {
                content: `"${orderNum}"`,
                position: 'absolute',
                left: 0,
                top: 0,
                fontSize: 'inherit',
                fontFamily: 'inherit',
                opacity: 0.2,
              },
            },
          ]}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        sx={[
          withNumber && {
            marginLeft: {
              xs: '35px',
              md: 0,
            },
          },
        ]}
        {...(childrenOpts?.descriptionGridProps || { xs: 12, md: 7.5 })}
      >
        {typeof description === 'string' ? (
          <Typography variant="body2" className="font-secondary secondary" fontSize={14} whiteSpace="pre-line">
            {description}
          </Typography>
        ) : (
          description
        )}
      </Grid>
    </Grid>
  );
};
