import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const House: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M9 17.0009H15M3 14.6009V12.131C3 10.9823 3 10.408 3.14805 9.87905C3.2792 9.41051 3.49473 8.96983 3.78405 8.57866C4.11067 8.13706 4.56404 7.78444 5.47078 7.0792L8.07078 5.05697C9.47608 3.96396 10.1787 3.41745 10.9546 3.20738C11.6392 3.02202 12.3608 3.02202 13.0454 3.20738C13.8213 3.41745 14.5239 3.96396 15.9292 5.05697L18.5292 7.0792C19.436 7.78444 19.8893 8.13706 20.2159 8.57866C20.5053 8.96983 20.7208 9.41051 20.8519 9.87905C21 10.408 21 10.9823 21 12.131V14.6009C21 16.8411 21 17.9612 20.564 18.8169C20.1805 19.5695 19.5686 20.1814 18.816 20.5649C17.9603 21.0009 16.8402 21.0009 14.6 21.0009H9.4C7.15979 21.0009 6.03969 21.0009 5.18404 20.5649C4.43139 20.1814 3.81947 19.5695 3.43597 18.8169C3 17.9612 3 16.8411 3 14.6009Z"
      stroke="#949494"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
