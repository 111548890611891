import { Link, NavLink, useLocation } from 'react-router-dom';
import { Box, Container, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { Breadcrumbs, CratLogoAnimated } from 'shared';
import { headerNavLinks } from 'widgets/layout/constants';

import { ConnectButton } from './features';
import { MobileMenu, NavLinkWithPopover, NavTitle } from './ui';

export const Header = () => {
  const { pathname } = useLocation();
  const parentPathname = `/${pathname.split('/')[1]}`;

  return (
    <Container component="header" sx={{ flexGrow: 1, mb: 4 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ height: '100%', py: 2 }}>
        <Link to="/">
          <Stack direction="row" alignItems="center" spacing={1.125}>
            <Box component="img" src={CratLogoAnimated} width={35} height={39} />
            <Typography variant="h2" fontSize={22}>
              CratD2C
            </Typography>
          </Stack>
        </Link>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={{ xs: 2, md: 3, lg: 7.5 }}>
          <Stack
            component="nav"
            direction="row"
            alignItems="center"
            spacing={{ md: 2, lg: 4.5 }}
            sx={{ display: { md: 'flex', xs: 'none' } }}
          >
            {headerNavLinks.map((link) =>
              link.subpaths ? (
                <NavLinkWithPopover
                  key={link.title}
                  title={link.title}
                  isTitleActive={parentPathname === link.path}
                  navItems={link.subpaths}
                />
              ) : (
                <NavLink key={link.path} to={link.path}>
                  <NavTitle className={clsx({ accent: parentPathname === link.path })}>{link.title}</NavTitle>
                </NavLink>
              ),
            )}
          </Stack>
          <ConnectButton />
          <MobileMenu />
        </Stack>
      </Stack>
      <Breadcrumbs />
    </Container>
  );
};
