import { H1, PageParagraph, Section } from 'shared';

export const Intro = () => {
  return (
    <Section>
      <H1 text="Staking" highlightedWords={[0]} />

      <PageParagraph>
        The staking mechanism within the CratD2C Decentralized Autonomous Smart Chain (DASC) is a fundamental and
        innovative feature that empowers participants to actively engage with the network, contribute to its security,
        and earn rewards in return. The staking process involves participants locking up a certain amount of CratD2C
        Coins in the platform&#39;s staking pool, enabling them to play a role in the network&#39;s consensus and
        governance.
      </PageParagraph>
    </Section>
  );
};
