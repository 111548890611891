/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Buffer } from 'buffer';

import type { HttpTransport } from 'viem';
import { http } from 'viem';
import { createConfig } from 'wagmi';

import { CHAINS } from './chains';

if (!window.Buffer) {
  window.Buffer = Buffer;
}

export const wagmiConfig = createConfig({
  chains: CHAINS,
  multiInjectedProviderDiscovery: false,
  transports: CHAINS.reduce<Record<number, HttpTransport>>((acc, chain) => {
    acc[chain.id] = http(undefined, { batch: true, retryCount: 1 });
    return acc;
  }, {}),
});
