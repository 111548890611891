import { Link } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { BORDER_MAIN, BORDER_RADIUS_S, COLOR_SECONDARY } from 'shared/config';
import { Icons } from 'shared/ui';

interface BottomNavigationActionProps {
  label: string;
  value: {
    name: string;
    link: string;
  };
  orientation: 'ltr' | 'rtl';
}

export const BottomNavigationAction = ({ label, orientation, value }: BottomNavigationActionProps) => {
  const isRtl = orientation === 'rtl';
  return (
    <Stack
      component={Link}
      to={value.link}
      aria-label={isRtl ? 'Go to the next page' : 'Go to the prev page'}
      alignItems={isRtl ? 'flex-end' : 'flex-start'}
      justifyContent="center"
      sx={{
        width: '100%',
        padding: { xs: 1.25, md: 2 },
        minHeight: 90,
        borderRadius: BORDER_RADIUS_S,
        border: BORDER_MAIN,
      }}
    >
      <Typography className="secondary" mb={0.25}>
        {label}
      </Typography>
      <Stack
        width="100%"
        spacing={1.5}
        direction={isRtl ? 'row' : 'row-reverse'}
        justifyContent="space-between"
        alignItems="center"
      >
        <Icons.Arrow
          role="img"
          aria-label="Arrow Icon"
          sx={{
            width: { xs: 20, md: 24 },
            height: { xs: 20, md: 24 },
            transform: isRtl ? 'rotate(270deg)' : 'rotate(90deg)',
            color: COLOR_SECONDARY,
          }}
        />
        <Typography variant="h5" noWrap>
          {value.name}
        </Typography>
      </Stack>
    </Stack>
  );
};
