import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material';
import { COLOR_BG_BLACK_2, COLOR_PRIMARY, useBreakpoints } from 'shared';
import HomeBg from 'shared/assets/video/home_background.webm';

export const Intro = () => {
  const { isDownSmLayout } = useBreakpoints();

  return (
    <Box component="section">
      <Typography
        className="no-resize"
        variant="h1"
        sx={{
          maxWidth: { xs: 255, sm: 615, md: 1080 },
          fontSize: { xs: 40, sm: 72 },
        }}
      >
        CratD2C Decentralized Autonomous{' '}
        <Typography component="span" fontFamily="PP Editoral" fontStyle="italic" variant="inherit">
          SmartChain
        </Typography>
      </Typography>
      <Box maxWidth={431} mt={{ xs: 1.5, sm: 4 }}>
        <Typography variant="h5" fontSize={18} className="no-resize regular" mb={{ xs: 3, sm: 5 }}>
          Decentralizing Commerce, Building Communities{' '}
        </Typography>
        <Card className="blur" sx={{ p: { xs: 2, sm: 2.5 }, pt: { sm: 3 } }}>
          <Stack direction={{ sm: 'row' }} gap={{ xs: 2, sm: 3 }}>
            <Stack alignItems={{ xs: 'center', sm: 'start' }} flexGrow={1}>
              <Typography variant="h6" className="secondary medium">
                IP Protection in
              </Typography>
              <Typography variant="h5" className="no-resize accent">
                181 Countries
              </Typography>
              <Typography variant="h6" className="secondary medium">
                Reg. No.{' '}
                <Box component="span" sx={{ color: COLOR_PRIMARY, whiteSpace: 'nowrap' }}>
                  EC-01-004149
                </Box>
              </Typography>
            </Stack>
            {!isDownSmLayout ? (
              <Divider
                orientation="vertical"
                sx={{
                  height: '69px',
                }}
              />
            ) : (
              <Divider
                orientation="horizontal"
                sx={{
                  width: '100%',
                }}
              />
            )}
            <Stack alignItems={{ xs: 'center', sm: 'start' }} flexGrow={1}>
              <Typography variant="h6" className="secondary medium">
                IP Valuation:
              </Typography>
              <Typography variant="h5" className="no-resize accent">
                $160,255,384.00
              </Typography>
              <Typography variant="h6" className="medium">
                London Rate
              </Typography>
            </Stack>
          </Stack>
        </Card>
        <Stack direction={{ sm: 'row' }} columnGap={1} rowGap={1.25} mt={{ xs: 3, sm: 2 }}>
          <Button size={isDownSmLayout ? 'medium' : 'large'} LinkComponent="a" href="#crowdsale" sx={{ flexGrow: 1 }}>
            Buy CRAT
          </Button>
          <Button
            size={isDownSmLayout ? 'medium' : 'large'}
            color="success"
            variant="outlined"
            className="active"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdrdJlZLXXfXr8Nexgo2y7CfBP-kPhsHD3tVh9kVXlE8ZHPsw/viewform?usp=pp_url"
            target="_blank"
            sx={{ whiteSpace: 'nowrap', flexGrow: 1 }}
          >
            Buy IP-Portion
          </Button>
        </Stack>
      </Box>

      <Box
        component="video"
        autoPlay
        muted
        loop
        playsInline
        sx={{
          objectFit: 'cover',
          position: 'absolute',
          zIndex: -2,
          top: 0,
          height: { xs: 700, md: 1434 },
          left: 0,
          width: '100vw',
          overflow: 'hidden',
        }}
      >
        <source src={HomeBg} type="video/webm" />
      </Box>
      <Box
        sx={{
          background: `linear-gradient(transparent 10%, ${COLOR_BG_BLACK_2})`,
          objectFit: 'cover',
          position: 'absolute',
          zIndex: -1,
          top: 0,
          height: { xs: 700, md: 1434 },
          left: { xs: -150, md: 0 },
          width: { xs: 'calc(100vw + 150px)', md: '100vw' },
          overflow: 'hidden',
        }}
      />
    </Box>
  );
};
