import { FC } from 'react';
import { Grid, SxProps, Typography, TypographyProps } from '@mui/material';
import { BORDER_MAIN } from 'shared/config';
import { GridBreakpointsType } from 'shared/types';

import { TdProps } from './types';

export interface TableRowProps<T> {
  trTitle: string;
  trData: T[];
  TdSlot: FC<TdProps<T>>;
  trTitleSpace?: number;
  sx?: SxProps;
  titleSx?: TypographyProps;
  gridConfig?: { [key: number]: GridBreakpointsType<number> };
}

export const TableRow = <T extends string | number>({
  trTitle,
  trData,
  trTitleSpace = 4,
  TdSlot,
  gridConfig,
  sx,
  titleSx,
}: TableRowProps<T>) => {
  return (
    <Grid
      container
      item
      xs={12}
      component="tr"
      sx={{ width: '100%', py: 1.75, minHeight: 70, borderTop: BORDER_MAIN, ...sx }}
    >
      <Grid item component="td" xs={trTitleSpace}>
        <Typography maxWidth={{ xs: 175, sm: 230 }} variant="h6" {...titleSx}>
          {trTitle}
        </Typography>
      </Grid>

      {trData.map((value, index) => {
        const calculatedColumns = (12 - trTitleSpace) / trData.length;
        return (
          <Grid
            item
            component="td"
            xs={gridConfig?.[index]?.xs || calculatedColumns}
            key={`${trTitle + value + index}`}
          >
            <TdSlot value={value} title={trTitle} />
          </Grid>
        );
      })}
    </Grid>
  );
};
