import { ComponentsOverrides, ComponentsProps, formLabelClasses, inputBaseClasses, Theme } from '@mui/material';

import { BORDER_RADIUS_S, colorVariations } from '../common';
import { FontFamilies, FontWeights } from '../Typography';

export const getMuiTextFieldOverrides = (theme: Theme): ComponentsOverrides['MuiTextField'] => ({
  root: {
    width: '100%',

    '& .MuiFormHelperText-root': {
      fontFamily: FontFamilies.primary,
      fontWeight: 400,
      color: theme.themeColors.colorsFormLabelTextField,
      fontSize: 15,
      lineHeight: '155%',
      letterSpacing: '-0.01em',
      marginLeft: '0px',
      marginTop: '8px',
    },

    '& .MuiInputLabel-root': {
      position: 'static',
      transform: 'none',
      fontFamily: FontFamilies.primary,
      fontWeight: 400,
      color: theme.themeColors.colorsFormLabelTextField,
      marginBottom: 5,
      fontSize: 15,
      lineHeight: '155%',
      letterSpacing: '-0.01em',

      [`&.${formLabelClasses.focused}`]: {
        color: theme.themeColors.colorsFormLabelTextField,
      },
    },

    '& > .MuiInputBase-root': {
      fontFamily: FontFamilies.primary,
      background: theme.themeColors.colorsTextFieldBackground,
      border: `1px solid ${theme.themeColors.colorsTextFieldBorder}`,
      borderRadius: BORDER_RADIUS_S,
      position: 'relative',
      padding: '9px 16px',
      minHeight: 40,

      '&:hover, &:active, &.Mui-focused': {
        background: theme.themeColors.colorsTextFieldBackground,
      },

      '&::after, &:before': {
        display: 'none',
      },

      '&.Mui-disabled': {
        background: theme.themeColors.colorsTextFieldBackground,
      },

      [`& .${inputBaseClasses.input}`]: {
        padding: 0,
        fontWeight: FontWeights.Regular,
        fontSize: 16,
        lineHeight: '147%',
        color: theme.themeColors.colorsTextFieldColor,
        ...colorVariations,
      },

      [`& > .${inputBaseClasses.input}::placeholder`]: {
        opacity: 1,
        fontWeight: FontWeights.Regular,
        fontSize: 16,
        lineHeight: '147%',
        color: theme.themeColors.colorsTextFieldPlaceholderColor,
      },

      [`&.MuiInputBase-colorError`]: {
        borderColor: theme.themeColors.colorsTextFieldColorError,
      },
    },

    'input:-webkit-autofill': {
      boxShadow: '0 0 0 1000px transparent inset !important',
      transitionDelay: '999999s',
      transitionProperty: 'background-color, color',
    },
  },
});

export const getMuiTextFieldDefaultProps = (): ComponentsProps['MuiTextField'] => ({
  variant: 'filled',
  SelectProps: {
    displayEmpty: true,
  },
  autoComplete: 'off',
  size: 'medium',
  InputProps: {
    disableUnderline: true,
  },
});
