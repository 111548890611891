import { useEffect } from 'react';
import { noop } from 'lodash';
import { useDisconnect } from 'shared';
import { useAccount } from 'wagmi';

export const useDisconnectTimeout = (
  isMessageSent: boolean,
  isAuthorized: boolean,
  disconnect: () => void,
  afterMs: number,
) => {
  const { isReconnecting, isConnecting, isDisconnected } = useAccount();
  const { isDisconnecting } = useDisconnect();

  useEffect(() => {
    if (isDisconnecting || isAuthorized || isConnecting || (isDisconnected && !isReconnecting) || isMessageSent) {
      return noop;
    }

    const timeoutId = setTimeout(disconnect, afterMs);

    return () => clearTimeout(timeoutId);
  }, [isAuthorized, isConnecting, isDisconnecting, isDisconnected, isReconnecting, isMessageSent, disconnect, afterMs]);
};
