import { Box } from '@mui/material';
import { Intro } from 'shared';

import { intro } from './constants';
import { InfoLine } from './ui';

export const MarketPortals = () => {
  return (
    <>
      <Box component="section">
        <Intro
          mainTitle="e-Market Portals"
          paragraph={intro.paragraph}
          paragraphProps={{ letterSpacing: '-0.4px !important' }}
          paragraphSubtitle={intro.subtitle}
          paragraphSubtitleProps={{ whiteSpace: 'pre-line', pt: { md: 2.5 } }}
          highlightedTitleWords={[0, 1]}
        />
      </Box>
      <InfoLine />
    </>
  );
};
