import { State } from 'app/store';

import { GiveawayState } from './reducer';

const getGiveaway = (state: State) => state.giveaway;

const getProp =
  <T extends keyof GiveawayState>(propKey: T) =>
  (state: State) =>
    state.giveaway[propKey];

export const selectors = {
  getGiveaway,
  getProp,
};
