import { useMemo } from 'react';
import { Box, Divider, Grid, SxProps } from '@mui/material';
import { camelize } from 'shared/lib';

import { buildPageBottomNavigation, extractRootPath } from '../navigation.helper';

import { BottomNavigationAction } from './bottom-navigation-action';

interface BottomNavigationProps {
  pathname: string;
  withDivider?: boolean;
  sx?: SxProps;
}

export const BottomNavigation = ({ pathname, withDivider = false, sx }: BottomNavigationProps) => {
  const rootPath = camelize(extractRootPath(pathname));
  const { navigationMapByIds, fullNavigationMapForPath } = useMemo(
    () => buildPageBottomNavigation(rootPath),
    [rootPath],
  );

  const currentRouteId = fullNavigationMapForPath[pathname].id;
  const previousRoute = navigationMapByIds.get(currentRouteId - 1);
  const nextRoute = navigationMapByIds.get(currentRouteId + 1);

  return (
    <Box sx={sx} component="nav" aria-label="bottom-navigation">
      <Grid container spacing={{ xs: 1.25, md: 2.5 }} component="ul">
        <Grid item xs={6} component="li">
          {previousRoute && (
            <BottomNavigationAction
              value={{ link: previousRoute.path, name: previousRoute.name }}
              orientation="rtl"
              label="Previous"
            />
          )}
        </Grid>
        <Grid item xs={6} component="li">
          {nextRoute && (
            <BottomNavigationAction
              value={{ link: nextRoute.path, name: nextRoute.name }}
              orientation="ltr"
              label="Next"
            />
          )}
        </Grid>
      </Grid>
      {withDivider && <Divider flexItem sx={{ mt: 5.5, mx: { xs: -2.5, lg: 'calc((1300px - 100vw) / 2 )' } }} />}
    </Box>
  );
};
