/* eslint-disable no-nested-ternary */
import { ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import { alpha, Box, Card, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import {
  COLOR_ACCENT,
  COLOR_BG,
  COLOR_PRIMARY,
  COLOR_STROKE,
  Icons,
  Note,
  TRANSITION_500,
  useBreakpoints,
} from 'shared';
import { RoadmapProps } from 'widgets/roadmap/types';

interface StepProps
  extends Pick<RoadmapProps, 'fixedStepSize' | 'fixedLineSize' | 'spacing' | 'firstPolygonVariant' | 'showCardNumber'> {
  step: { description: ReactNode; title?: ReactNode; note?: ReactNode };
  isFirst: boolean;
  isEven: boolean;
  isLast: boolean;
  index: number;
}

export const Step = ({
  step,
  isFirst,
  isEven,
  isLast,
  index,
  fixedLineSize,
  fixedStepSize,
  showCardNumber = true,
  spacing = 0,
  firstPolygonVariant = 'normal',
}: StepProps) => {
  const maxWidth = 432;
  const num = `${index > 8 ? '' : '0'}${index + 1}`.substring(-2);

  const isDouble = isFirst && firstPolygonVariant === 'double';
  const isScale = isFirst && firstPolygonVariant === 'scale';

  const { isDownMdLayout } = useBreakpoints();
  const { ref, inView: isActive } = useInView({
    threshold: 0.9,
    rootMargin: '0% 0% -8% 0%',
    triggerOnce: true,
  });

  if (isDownMdLayout) {
    return (
      <Box
        sx={{
          overflowY: 'visible',
          position: 'relative',
          '&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            height: isLast ? 0 : 24,
            borderLeft: '1px dashed',
            borderColor: COLOR_ACCENT,
            left: '50%',
            transform: 'translateY(100%)',
            zIndex: 12,
          },
        }}
      >
        <Card>
          {!!step.title && (
            <Typography variant="h5" component="p" className="no-resize" mb={1.5}>
              {showCardNumber && (
                <Typography variant="inherit" component="span" fontWeight="inherit" className="secondary">
                  {num}.{' '}
                </Typography>
              )}
              <Typography variant="inherit" component="span" fontWeight="inherit" className="accent">
                {step.title}
              </Typography>
            </Typography>
          )}
          <Typography
            variant="body2"
            className="font-secondary"
            sx={{
              color: alpha(COLOR_PRIMARY, 0.6),
            }}
          >
            {step.description}
          </Typography>
          {!!step.note && (
            <Note
              sx={{
                mt: 1.5,
              }}
            >
              {step.note}
            </Note>
          )}
        </Card>
      </Box>
    );
  }

  return (
    <Stack
      ref={ref}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        position: 'relative',
        ...(isDouble ? { pb: 14 } : {}),
        ...(!!fixedStepSize && { height: fixedStepSize }),

        ...(!fixedLineSize && {
          '&:before': {
            transition: 'all 0.5s linear',
            content: '""',
            position: 'absolute',
            bottom: 0,
            height: isLast ? 0 : `calc(149% + ${spacing}px)`,
            borderLeft: '1px dashed',
            borderColor: isActive ? COLOR_ACCENT : COLOR_STROKE,
            left: '50%',
            transform: 'translateY(50%)',
            opacity: 0.2,
          },
        }),
      }}
    >
      <Card
        sx={(theme) => ({
          maxWidth,
          zIndex: 2,
          background: COLOR_BG,
          flexGrow: 1,
          flexBasis: 0,
          visibility: isEven ? 'visible' : 'hidden',
          transition: 'all 0.5s linear',
          borderColor: isActive ? COLOR_ACCENT : COLOR_STROKE,
          padding: theme.spacing(2, 2.5, 2, 2),
        })}
      >
        {!!step.title && (
          <Typography variant="h5" className="no-resize" component="p" mb={1.25}>
            {showCardNumber && (
              <Typography variant="inherit" component="span" fontWeight="inherit" className="stroke">
                {num}.{' '}
              </Typography>
            )}
            <Typography variant="inherit" component="span" fontWeight="inherit" className="accent">
              {step.title}
            </Typography>
          </Typography>
        )}
        <Typography
          variant="body2"
          className="font-secondary"
          sx={{
            color: alpha(COLOR_PRIMARY, 0.6),
          }}
        >
          {step.description}
        </Typography>
        {!!step.note && (
          <Note
            sx={{
              mt: 1.5,
            }}
          >
            {step.note}
          </Note>
        )}
      </Card>

      <Box
        sx={{
          flex: '0 0 10%',
          ...(isDouble ? { flex: '0 0 20px' } : {}),
        }}
      />
      <Box
        sx={{
          flexShrink: 0,
          position: 'relative',

          '&:before': {
            transition: 'all 0.5s linear',
            content: '""',
            position: 'absolute',
            top: '50%',
            width: 180,
            borderTop: '1px dashed',
            borderColor: isActive ? COLOR_ACCENT : COLOR_STROKE,
            height: '1px',
            opacity: 0.3,
            ...(isEven
              ? { left: 0, transform: 'translate(-100%, -100%)' }
              : { right: 0, transform: 'translate(100%, -100%)' }),
          },
        }}
      >
        <Typography
          variant="h5"
          className={clsx(!isActive && 'stroke')}
          sx={{
            transition: TRANSITION_500,
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 3,
          }}
        >
          {num}
        </Typography>
        <Box
          sx={{
            position: 'relative',
            ...(!!fixedLineSize && {
              '&:before': {
                transition: 'all 0.5s linear',
                content: '""',
                position: 'absolute',
                bottom: 0,
                height: isLast ? 0 : `calc(10% + ${fixedLineSize}px)`,
                borderLeft: '1px dashed',
                borderColor: isActive ? COLOR_ACCENT : COLOR_STROKE,
                left: '50%',
                transform: 'translate(-50%, 95%)',
                opacity: 0.2,
              },
            }),
          }}
        >
          <Icons.Polygon
            sx={{
              transition: TRANSITION_500,
              color: isActive ? COLOR_ACCENT : COLOR_STROKE,
              position: 'relative',
              fill: COLOR_BG,
              zIndex: 2,
              ...(isDouble ? { width: 261, height: '100%' } : isScale ? { transform: 'scale(1.55)' } : {}),
            }}
          />
        </Box>
        {isDouble && (
          <Icons.Polygon
            sx={{
              transition: TRANSITION_500,
              color: isActive ? COLOR_ACCENT : COLOR_STROKE,
              position: 'absolute',
              fill: COLOR_BG,
              zIndex: 2,
              width: 143,
              height: '100%',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          flex: '0 0 10%',
          ...(isDouble ? { flex: '0 0 20px' } : {}),
        }}
      />
      <Card
        sx={{
          maxWidth,
          zIndex: 2,
          background: COLOR_BG,
          flexGrow: 1,
          flexBasis: 0,
          visibility: !isEven ? 'visible' : 'hidden',
          transition: TRANSITION_500,
          borderColor: isActive ? COLOR_ACCENT : COLOR_STROKE,
        }}
      >
        {!!step.title && (
          <Typography variant="h5" className="no-resize" component="p" mb={1.25}>
            {showCardNumber && (
              <Typography variant="inherit" component="span" fontWeight="inherit" className="stroke">
                {num}.{' '}
              </Typography>
            )}
            <Typography variant="inherit" component="span" fontWeight="inherit" className="accent">
              {step.title}
            </Typography>
          </Typography>
        )}
        <Typography
          variant="body2"
          className="font-secondary"
          sx={{
            color: alpha(COLOR_PRIMARY, 0.6),
          }}
        >
          {step.description}
        </Typography>
        {!!step.note && (
          <Note
            sx={{
              mt: 1.5,
            }}
          >
            {step.note}
          </Note>
        )}
      </Card>
    </Stack>
  );
};
