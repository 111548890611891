import BigNumber from 'bignumber.js';
import { toDecimals } from 'shared';

type Tier = {
  bonusPercent: number;
  investedValue: number;
  investedValueLevel: number;
  entireBonusPercent: number;
};

export const tiers: Tier[] = [
  {
    bonusPercent: 0,
    entireBonusPercent: 1,
    investedValue: 0,
    investedValueLevel: 0,
  },
  {
    bonusPercent: 2,
    entireBonusPercent: 5,
    investedValue: 500,
    investedValueLevel: 0,
  },
  {
    bonusPercent: 3,
    entireBonusPercent: 10,
    investedValue: 1000,
    investedValueLevel: 1000,
  },
  {
    bonusPercent: 5,
    entireBonusPercent: 15,
    investedValue: 2000,
    investedValueLevel: 1000,
  },
  {
    bonusPercent: 10,
    entireBonusPercent: 25,
    investedValue: 5000,
    investedValueLevel: 1000,
  },
  {
    bonusPercent: 15,
    entireBonusPercent: 35,
    investedValue: 10000,
    investedValueLevel: 1000,
  },
  {
    bonusPercent: 25,
    entireBonusPercent: 50,
    investedValue: 20000,
    investedValueLevel: 1000,
  },
];

export const getUserTierInfo = (tierLevel: number) => {
  return [tiers[tierLevel], tiers[tierLevel + 1]];
};

export const calculateTierBonus = (totalInvestAmount: string, tokenPrice: string, currentTier: number) => {
  const tier = tiers
    .filter(
      (t, index) =>
        index > currentTier && new BigNumber(totalInvestAmount).isGreaterThanOrEqualTo(toDecimals(t.investedValue)),
    )
    .slice(-1)[0];

  const currentTierObj = tiers[currentTier];

  const bonusTokens = tier
    ? new BigNumber(tier.investedValue)
        .minus(currentTierObj.investedValue)
        .multipliedBy(tier.bonusPercent)
        .div(100)
        .dividedBy(tokenPrice)
        .toFixed()
    : '0';

  return bonusTokens;
};

export const UNITS = {
  PER_DAY: 1000 * 60 * 60 * 24,
  PER_HOUR: 1000 * 60 * 60,
  PER_MINUTE: 1000 * 60,
  PER_SECOND: 1000,
} as const;

export const CELL_LABELS: Record<keyof ReturnType<typeof getEndsIn>['parts'], string> = {
  days: 'days',
  hours: 'hours',
  minutes: 'min.',
  seconds: 'sec.',
};

const getUnit = (days: number, hours: number, minutes: number, seconds: number) => {
  if (days) {
    return [days, 'days'] as const;
  }

  if (hours) {
    return [hours, 'hours'] as const;
  }

  if (minutes) {
    return [minutes, 'minutes'] as const;
  }

  return [seconds, 'seconds'] as const;
};

export const getEndsIn = (diffInMs: number) => {
  const days = Math.floor(diffInMs / UNITS.PER_DAY);
  const hours = Math.floor((diffInMs % UNITS.PER_DAY) / UNITS.PER_HOUR);
  const minutes = Math.floor((diffInMs % UNITS.PER_HOUR) / UNITS.PER_MINUTE);
  const seconds = Math.floor((diffInMs % UNITS.PER_MINUTE) / UNITS.PER_SECOND);

  return {
    parts: { days, hours, minutes, seconds },
    unit: getUnit(days, hours, minutes, seconds),
  };
};

export const getUtcTimestamp = () => {
  const date = new Date();

  return Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds(),
  );
};
