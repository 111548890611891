import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Mail: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M17.8385 4H6.16146C5.63433 3.99998 5.17954 3.99997 4.80497 4.03057C4.40963 4.06287 4.01641 4.13419 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2.21166 5.86436 2.1398 6.096 2.09376 6.33023L3.49108 7.1287L7.04661 9.10399C9.08846 10.2384 9.92599 10.6912 10.7915 10.8692C11.5889 11.0332 12.4114 11.0332 13.2089 10.8692C14.0744 10.6912 14.9119 10.2383 16.9537 9.10399L20.5 7.13386L21.899 6.29444C21.8528 6.07241 21.7825 5.85283 21.673 5.63803C21.3854 5.07355 20.9265 4.6146 20.362 4.32698C19.9836 4.13419 19.5904 4.06287 19.195 4.03057C18.8205 3.99997 18.3657 3.99998 17.8385 4Z"
      fill="#B4EB56"
    />
    <path
      d="M22 8.56622L21.5147 8.85742C21.5051 8.86314 21.4955 8.86869 21.4858 8.87408L17.7678 10.9397C15.9333 11.9592 14.8147 12.5808 13.6118 12.8282C12.5485 13.0469 11.4518 13.0469 10.3886 12.8282C9.18563 12.5808 8.06706 11.9592 6.23251 10.9397L2.504 8.86823L2 8.58022V15.8385C1.99998 16.3657 1.99997 16.8205 2.03057 17.195C2.06287 17.5904 2.13419 17.9836 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.01641 19.8658 4.40963 19.9371 4.80497 19.9694C5.17952 20 5.63425 20 6.16136 20H17.8385C18.3656 20 18.8205 20 19.195 19.9694C19.5904 19.9371 19.9836 19.8658 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C21.8658 17.9836 21.9371 17.5904 21.9694 17.195C22 16.8205 22 16.3657 22 15.8386V8.56622Z"
      fill="#B4EB56"
    />{' '}
  </SvgIcon>
);
