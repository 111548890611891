import { ComparisonTable, Intro } from './ui';

export const ComparativeAnalysis = () => {
  return (
    <>
      <Intro />
      <ComparisonTable />
    </>
  );
};
