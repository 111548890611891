import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { COLOR_ACCENT, COLOR_STROKE, FontWeights, Intro, Section } from 'shared';

import { TABLE_DATA } from './constants';

const Table = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  overflowX: 'auto',
}));

const Row = styled('li')(({ theme }) => ({
  borderTop: `1px solid ${COLOR_STROKE}`,
  paddingBlock: theme.spacing(3),
  display: 'grid',
  gap: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

const StyledText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    fontSize: 20,
  },
}));

const StyledLink = styled(Typography)(({ theme }) => ({
  color: COLOR_ACCENT,
  fontWeight: FontWeights.Medium,
  textDecoration: 'underline',
  [theme.breakpoints.up('sm')]: {
    fontSize: 20,
  },
})) as typeof Typography;

export const Resources = () => {
  return (
    <>
      <Intro sx={{ mb: 5 }} mainTitle="Our Resources" highlightedTitleWords={[0]} />
      <Section>
        <Table>
          {TABLE_DATA.map(([title, link]) => (
            <Row key={title}>
              <StyledText noWrap>{title}</StyledText>
              <StyledLink
                component="a"
                href={`https://${link}`}
                referrerPolicy="no-referrer"
                rel="noreferrer"
                target="_blank"
                noWrap
              >
                {link}
              </StyledLink>
            </Row>
          ))}
        </Table>
      </Section>
    </>
  );
};
