import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { colorVariations } from '../common';

export enum FontWeights {
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
}

export enum FontFamilies {
  secondary = '"Inter", sans-serif',
  primary = '"Poppins", sans-serif',
  third = '"Montserrat",  sans-serif',
}

const weightVariations = {
  '&.regular': {
    fontWeight: FontWeights.Regular,
  },
  '&.medium': {
    fontWeight: FontWeights.Medium,
  },
  '&.semi-bold': {
    fontWeight: FontWeights.SemiBold,
  },
  '&.bold': {
    fontWeight: FontWeights.Bold,
  },
};

export const fontVariations = {
  '&.font-primary': {
    fontFamily: FontFamilies.primary,
  },
  '&.font-secondary': {
    fontFamily: FontFamilies.secondary,
  },
  '&.font-third': {
    fontFamily: FontFamilies.third,
  },
};

export const getTypographyOverrides = (theme: Theme): ComponentsOverrides['MuiTypography'] => ({
  root: {
    textTransform: 'none',
    color: theme.themeColors.mainColor,
    fontFamily: FontFamilies.primary,
    fontWeight: FontWeights.Regular,
    textAlign: 'left',
    fontStyle: 'normal',
    ...fontVariations,
    ...colorVariations,
    ...weightVariations,

    '&.center': {
      textAlign: 'center',
    },
    '&.nowrap': {
      whiteSpace: 'nowrap',
    },
  },

  h1: {
    fontSize: 72,
    lineHeight: '112%',
    fontFamily: FontFamilies.primary,
    fontWeight: FontWeights.SemiBold,
    letterSpacing: `-0.03em`,

    [theme.breakpoints.down('md')]: {
      '&:not(.no-resize)': { fontSize: 30 },
    },
  },

  h2: {
    fontSize: 40,
    lineHeight: '112%',
    fontWeight: FontWeights.SemiBold,
    letterSpacing: `-0.03em`,

    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      lineHeight: '120%',
    },
  },

  h3: {
    fontSize: 40,
    lineHeight: '130%',
    fontWeight: FontWeights.Medium,
    letterSpacing: `-0.02em`,

    [theme.breakpoints.down('md')]: {
      '&:not(.no-resize)': { fontSize: 20, lineHeight: '120%' },
    },
  },

  h4: {
    fontSize: 24,
    lineHeight: '125%',
    fontWeight: FontWeights.Medium,
    letterSpacing: `-0.03em`,
    [theme.breakpoints.down('md')]: {
      '&.md': { fontSize: 20 },
    },

    '&.summary': {
      fontSize: '26px',
      lineHeight: '34px',
      letterSpacing: '-0.5px',
      fontWeight: FontWeights.Medium,
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
        lineHeight: '28px',
      },
    },

    '&.large': {
      fontSize: '32px',
      lineHeight: '42.24px',
      letterSpacing: '-1%',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px',
        lineHeight: '28px',
      },
    },
  },

  h5: {
    fontSize: 20,
    lineHeight: '150%',
    fontWeight: FontWeights.Medium,
    letterSpacing: `-0.02em`,

    [theme.breakpoints.down('md')]: {
      '&:not(.no-resize)': { fontSize: 16 },
    },

    '&.md': {
      lineHeight: '32px',
      letterSpacing: 'none',
      fontWeight: FontWeights.Regular,
    },

    '&.sm': {
      fontSize: 18,
      lineHeight: '23px',
    },
  },

  h6: {
    fontSize: 15,
    lineHeight: '155%',
    fontWeight: FontWeights.Regular,
    letterSpacing: `-0.01em`,
  },

  body2: {
    fontSize: 14,
    lineHeight: '155%',
    fontWeight: FontWeights.Regular,
    letterSpacing: `-0.01em`,
  },

  body1: {
    fontSize: 15,
    lineHeight: '24px',
    fontWeight: FontWeights.Regular,
  },

  subtitle1: {
    fontSize: 14,
    lineHeight: '21px',
    fontFamily: FontFamilies.primary,
    fontWeight: FontWeights.Medium,
    letterSpacing: `-0.03em`,
  },

  subtitle2: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: FontWeights.Regular,
  },

  button: {
    fontSize: 20,
    lineHeight: '32px',
    textTransform: 'none',

    '&.sm': {
      fontSize: 18,
      lineHeight: '26px',
    },
  },
});

export const getTypographyDefaultProps = (): ComponentsProps['MuiTypography'] => ({
  variant: 'body1',
});
