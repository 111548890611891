export const intro = {
  paragraph:
    "At CratD2C, we take security seriously, and we believe in the power of the community to help us identify and resolve potential vulnerabilities in our ecosystem. To encourage responsible disclosure and to ensure the safety of our platform, we've launched the CratD2C Bug Bounty Program.",
};

export const scope = [
  {
    title: 'Smart Contracts',
    description:
      'Vulnerabilities or issues in our smart contracts that could potentially lead to exploits or unexpected behavior.',
  },
  {
    title: 'Blockchain Protocol',
    description:
      'Any weaknesses or vulnerabilities in the underlying blockchain protocol, such as consensus mechanisms, transaction validation, or network security.',
  },
  {
    title: 'Web and Mobile Applications',
    description:
      'Security flaws, vulnerabilities, or weaknesses in our web and mobile applications, including the CratD2C Wallet and Explorer.',
  },
  {
    title: 'Network Security',
    description:
      'Threats related to network security, such as DDoS attacks, spam attacks, or any other form of network abuse.',
  },
];

export const bugBountyRewardsList = [
  {
    title: 'Critical Issues',
    reward: 10000,
  },
  {
    title: 'High Severity Issue',
    reward: 5000,
  },
  {
    title: 'Medium Severity Issues',
    reward: 2000,
  },
  {
    title: 'Low Severity Issues',
    reward: 1000,
  },
];

export const howToParticipate = [
  'Visit our Bug Bounty page on the CratD2C website to learn more about the program, scope, and guidelines.',
  "If you believe you've identified a security vulnerability, submit a detailed report through the provided channels.",
  'Our security team will review your report and assess the severity and impact of the vulnerability.',
  "Once verified, you'll receive a reward in CRAT Coins based on the severity of the issue.",
];

export const rulesAndGuidelines = [
  'Participants must adhere to responsible disclosure practices and must not exploit vulnerabilities for personal gain.',
  'Reports should include detailed information about the vulnerability, steps to reproduce it, and any relevant proof of concept.',
  'Duplicate reports will not be eligible for rewards',
  'Vulnerabilities related to third-party applications or services integrated with CratD2C are not within the scope of this program.',
];
