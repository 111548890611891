import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Staking: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon viewBox="0 0 36 35" sx={{ width: '36px', height: '35px', fill: 'none', ...sx }} {...props}>
    <path
      d="M20.9028 15.2426C20.9028 17.2468 17.6533 18.8716 13.6448 18.8716C9.63626 18.8716 6.38672 17.2468 6.38672 15.2426M20.9028 15.2426C20.9028 13.2383 17.6533 11.6135 13.6448 11.6135C9.63626 11.6135 6.38672 13.2383 6.38672 15.2426M20.9028 15.2426V21.049M6.38672 15.2426V21.049M29.6125 7.9845C29.6125 5.98024 26.363 4.35547 22.3545 4.35547C19.4816 4.35547 16.9985 5.19004 15.8222 6.40075M29.6125 7.9845C29.6125 9.32776 28.1529 10.5006 25.9835 11.128M29.6125 7.9845V20.3232C29.6125 21.3978 28.1529 22.3361 25.9835 22.838M29.6125 14.5168C29.6125 15.6152 28.0875 16.5711 25.8382 17.0643M20.9028 21.049C20.9028 23.0533 17.6533 24.6781 13.6448 24.6781C9.63626 24.6781 6.38672 23.0533 6.38672 21.049M20.9028 21.049V26.8555C20.9028 28.8597 17.6533 30.4845 13.6448 30.4845C9.63626 30.4845 6.38672 28.8597 6.38672 26.8555V21.049"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
