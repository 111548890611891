import { Box, SxProps, Theme } from '@mui/material';
import { BlockchainAnimated } from 'shared';

interface BlockchainProps {
  sx?: SxProps<Theme>;
}

export const Blockchain = ({ sx }: BlockchainProps) => {
  return (
    <Box className="container" sx={{ flexGrow: 1 }}>
      <BlockchainAnimated sx={sx} />
    </Box>
  );
};
