import { PropsWithChildren } from 'react';
import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';

interface PageParagraphProps {
  pProps?: TypographyProps;
}

export const PageParagraph = ({
  children,
  pProps = {},
  ...boxProps
}: PageParagraphProps & BoxProps & PropsWithChildren) => {
  return (
    <Box
      {...boxProps}
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        mt: { xs: 2, sm: 3.5 },
        ...boxProps?.sx,
      }}
    >
      <Typography
        variant="h4"
        className="summary"
        {...pProps}
        sx={{
          gridColumn: { xs: 'span 12', md: '5 / 13' },
          ...pProps?.sx,
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};
