import { Card, Stack, Typography } from '@mui/material';
import { COLOR_ACCENT, COLOR_BG, COLOR_SECONDARY } from 'shared';

import { list } from '../constants';

import { AmbassadorialMenuProps } from './ambassadorial-menu';

type AmbassadorialMenuCardProps = AmbassadorialMenuProps & {
  isMobileOpen: boolean;
  handleClose: () => void;
};

export const AmbassadorialMenuCard = ({
  activeSection,
  isMobile,
  isMobileOpen,
  handleClose,
}: AmbassadorialMenuCardProps) => (
  <Card
    sx={(theme) => ({
      display: { xs: isMobile && isMobileOpen ? 'block' : 'none', md: isMobile ? 'none' : 'block' },
      p: theme.spacing('21px', '20px', '18px', 0),
      width: 246,
      bgcolor: COLOR_BG,
    })}
  >
    <Stack spacing={1}>
      {list.map(({ title, id }) => (
        <Typography
          key={title}
          component="a"
          variant="body2"
          color={id === activeSection ? COLOR_ACCENT : COLOR_SECONDARY}
          borderLeft={id === activeSection ? `2px solid ${COLOR_ACCENT}` : 'none'}
          pl={id === activeSection ? 1.25 : 1.5}
          href={`#${id}`}
          onClick={handleClose}
          sx={{
            position: 'relative',
            zIndex: 5,
            cursor: 'pointer',
            '&:hover': { color: COLOR_ACCENT },
          }}
        >
          {title}
        </Typography>
      ))}
    </Stack>
  </Card>
);
