import { useCallback, useEffect } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { CHAINS } from 'app/config/wallet';
import { useWagmiAuth } from 'app/providers';
import { crowdsaleModel } from 'entities/crowdsale';
import { userModel } from 'entities/user';
import {
  BORDER_RADIUS_2XL,
  BORDER_RADIUS_L,
  COLOR_ACCENT,
  COLOR_PRIMARY,
  COLOR_STROKE,
  CROWDSALE_ABI,
  useAppDispatch,
  useIntervalAsync,
  wagmiContracts,
} from 'shared';
import { useWalletClient, useWatchContractEvent } from 'wagmi';

import { BuyCoins, CrowdsaleProgress, IcoCountdown, Price, Round, UserStatistic } from './ui';

const HOW_TO_BUY_GITBOOK_LINK = 'https://cratd2csmartchain.gitbook.io/cratd2cico/';

export const Crowdsale = () => {
  const dispatch = useAppDispatch();

  const { isSuccess: isWalletConnected } = useWalletClient();

  const { state: authState } = useWagmiAuth();

  const getCrowdsaleData = useCallback(async () => {
    dispatch(crowdsaleModel.thunks.getCrowdsaleData());
  }, [dispatch]);

  useIntervalAsync(getCrowdsaleData, 20_000);

  useEffect(() => {
    if (isWalletConnected && authState.isAuthorized) {
      dispatch(userModel.thunks.getUserInfo());
    }
  }, [dispatch, authState.isAuthorized, isWalletConnected]);

  useWatchContractEvent({
    abi: CROWDSALE_ABI,
    chainId: CHAINS[0].id,
    onLogs: (logs) => {
      if (!logs.length) {
        return;
      }

      dispatch(crowdsaleModel.actions.updateCrowdsaleState({ cratPrice: logs[0].args?.newPrice?.toString() }));
    },
    eventName: 'PriceUpdate',
    address: wagmiContracts.contracts.Crowdsale.address,
  });

  return (
    <Box component="section" mt={{ sm: 8, xs: 12 }} id="crowdsale">
      <Stack direction="row" justifyContent="space-between" alignContent="center">
        <Typography variant="h4" className="secondary" sx={{ pt: { xs: 0.8, md: 1.5 } }}>
          Crowdsale
        </Typography>
        <Button
          variant="outlined"
          href={HOW_TO_BUY_GITBOOK_LINK}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            px: 2,
            width: 169,
            height: { xs: 40, md: 56 },
            border: `2px solid ${COLOR_PRIMARY}`,
            '&:hover': { border: `2px solid ${COLOR_ACCENT}` },
          }}
        >
          How To Buy?
        </Button>
      </Stack>
      <CrowdsaleProgress />
      <Box
        mt={5}
        border={`1px solid ${COLOR_STROKE}`}
        borderRadius={{ xs: BORDER_RADIUS_L, md: BORDER_RADIUS_2XL }}
        p={{ xs: 2, sm: 4 }}
      >
        <Round />
        <IcoCountdown />
      </Box>
      <Price />
      <Stack direction={{ md: 'row', xs: 'column-reverse' }} gap={4} mt={3.5}>
        <Box
          sx={{
            flexGrow: 1,
            flexBasis: 0,
          }}
        >
          <BuyCoins />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            flexBasis: 0,
          }}
        >
          <UserStatistic />
        </Box>
      </Stack>
    </Box>
  );
};
