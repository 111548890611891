import { Stack } from '@mui/material';
import { format } from 'date-fns';
import { Intro } from 'shared';

import { events } from './constants';
import { EventBlock } from './ui';

export const UpcomingEvents = () => {
  const getMonth = (date: number) => format(new Date(date), 'M');
  const checkMonthsEquality = (date1: number, date2: number) => getMonth(date1) !== getMonth(date2);

  return (
    <Stack mb={{ xs: 6, md: 14.5 }}>
      <Intro mainTitle="Upcoming Events" highlightedTitleWords={[0]} sx={{ mb: { xs: 3, md: 6 } }} />
      <Stack gap={{ xs: 4, md: 6 }}>
        {events.map((event, index) => (
          <EventBlock
            key={event.title}
            withDivider={index === 0 ? true : checkMonthsEquality(event.dateStart, events[index - 1].dateStart)}
            {...event}
          />
        ))}
      </Stack>
    </Stack>
  );
};
