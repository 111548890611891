import { Box, Grid, Typography } from '@mui/material';
import { Intro } from 'shared';

import { intro } from './constants';
import { TeamContacts } from './ui';

export const ProjectTeam = () => {
  return (
    <Box component="section" mb={{ xs: 6, md: 12 }}>
      <Intro
        mainTitle="Project Team"
        paragraph={intro.paragraph}
        paragraphProps={{ letterSpacing: '-0.4px !important' }}
        highlightedTitleWords={[0]}
        sx={{ marginBottom: { xs: '20px !important', md: '36px !important' } }}
      />
      <TeamContacts />
      <Grid container justifyContent="end" mt={6}>
        <Grid item xs={12} md={8} position="relative">
          <Typography
            variant="h4"
            className="summary"
            fontSize={{ xs: 16, md: 26 }}
            position={{ xs: 'unset', md: 'absolute' }}
            top={-470}
          >
            Feel free to reach out to the appropriate contact based on your inquiry. We are excited to connect with you
            and explore ways to collaborate and support your interests in the CratD2C Decentralized Autonomous Smart
            Chain ecosystem.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
