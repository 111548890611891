import { useCallback, useMemo, useState } from 'react';
import { RECONNECT_KEY } from 'app/providers';
import { useConfig, useDisconnect as useDisconnectWagmi } from 'wagmi';

export const useDisconnect = () => {
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const { storage } = useConfig();
  const { disconnectAsync: wagmiDisconnectAsync, isPending } = useDisconnectWagmi();

  const computedIsDisconnecting = useMemo(() => isDisconnecting || isPending, [isDisconnecting, isPending]);

  const disconnectAsync = useCallback(
    async (...args: Parameters<typeof wagmiDisconnectAsync>) => {
      if (computedIsDisconnecting) {
        return;
      }

      await wagmiDisconnectAsync(...args);

      if (!storage) {
        return;
      }

      await Promise.all([storage.removeItem('recentConnectorId'), storage.removeItem(RECONNECT_KEY)]);

      setIsDisconnecting(false);
    },
    [wagmiDisconnectAsync, storage, computedIsDisconnecting],
  );

  const toReturn = useMemo(
    () => ({ disconnectAsync, isDisconnecting: computedIsDisconnecting }),
    [disconnectAsync, computedIsDisconnecting],
  );

  return toReturn;
};
