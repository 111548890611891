import { useCallback, useEffect, useState } from 'react';
import { throttle } from 'lodash';

const getScrollPercentage = () => {
  const scrollTop = window.scrollY ?? document.documentElement.scrollTop;
  const { scrollHeight, clientHeight } = document.documentElement;

  return (scrollTop / (scrollHeight - clientHeight)) * 100;
};

export const useScrollThreshold = (thresholdPercentage: number, wait = 250) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = useCallback(
    throttle(() => {
      const scrollPercentage = getScrollPercentage();

      if (scrollPercentage > thresholdPercentage && !isScrolled) {
        setIsScrolled(true);
      }

      if (scrollPercentage <= thresholdPercentage && isScrolled) {
        setIsScrolled(false);
      }
    }, wait),
    [isScrolled, thresholdPercentage, wait],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return isScrolled;
};
