import { Card, styled } from '@mui/material';
import { BORDER_RADIUS_2XL } from 'shared';

export const ModalWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'grid',
  placeItems: 'center',
  padding: theme.spacing(2),
  zIndex: 10,
}));

export const ModalCard = styled(Card)(({ theme }) => ({
  width: 'min(556px, 100%)',
  background: 'black',
  padding: theme.spacing(4),
  borderRadius: BORDER_RADIUS_2XL,
}));
