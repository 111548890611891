export const explanationData = [
  {
    title: 'Latency',
    description:
      "CratD2C boasts an impressive latency of 0.5 to 3 seconds, providing nearly instant finality for transactions. In contrast, Bitcoin's latency can range from several minutes to much longer, Ethereum's latency is in the seconds range, Cardano and EOS offer similar low-latency experiences, while Solana achieves sub-second latency.",
  },
  {
    title: 'Transactions Per Second (TPS)',
    description:
      "CratD2C shines with a high TPS capacity of up to 100,000 transactions per second. Bitcoin, with 3 to 7 TPS, is significantly slower due to its Proof of Work (PoW) consensus mechanism. Ethereum's TPS ranges from 30 to 45, but it faces scalability challenges. Cardano and EOS offer higher TPS, catering to around 1,000 to 1,500 and 4,000 to 8,000 respectively. Solana stands out with an impressive TPS of 50,000 to 65,000, making it a leader in scalability within the threshold of PoS .",
  },
];

export const BOTTOM_TEXT =
  "CratD2C's competitive latency and remarkable TPS capabilities position it as a strong contender among leading blockchain projects, offering efficiency, speed, and scalability for a wide range of applications.";
