export const rows = [
  {
    title: 'DPoS Consensus',
    description: 'Utilizes a Delegated Proof-of-Stake mechanism for fast, secure, and energy-efficient consensus.',
  },
  {
    title: 'Layer-1 Infrastructure',
    description:
      'Employs Layer-1 blockchain architecture, ensuring robust security and streamlined network operations.',
  },
  {
    title: 'Scalability',
    description:
      'Achieves up to 100K transactions per second (TPS), enabling seamless handling of high transaction loads.',
  },
  {
    title: 'Instant Finality',
    description: 'Provides near-instant transaction finality, eliminating the need for long confirmation times.',
  },
  {
    title: 'Low Transaction Fees',
    description:
      "Our SmartChain isn't just technology; it's a vision realised. It's a bridge between the present and the future, where innovation meets practicality and blockchain becomes more than a buzzword—it becomes a game-changer.",
  },
  {
    title: 'Enhanced Privacy',
    description: 'Implements privacy features, safeguarding user data and transaction details.',
  },
  {
    title: 'Smart Contracts',
    description: 'Supports advanced smart contracts, automating processes and ensuring reliable execution.',
  },
  {
    title: 'Coin Creation',
    description: 'Enables easy creation of coins, NFTs, Metaverses, and other digital assets on the blockchain.',
  },
  {
    title: 'Decentralized Applications',
    description: 'Hosts various dApps, facilitating innovation and integration across industries.',
  },
  {
    title: 'Interoperability',
    description: 'Enables seamless communication and data exchange with other blockchain networks.',
  },
];
