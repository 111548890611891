import { Typography } from '@mui/material';
import { OptionsEnum } from 'features/calculator';
import { Br, PageParagraph } from 'shared';
import { Advantages, Intro } from 'shared/ui/staking-roles';
import { CalculatorBlock } from 'widgets';

import { CALCULATOR_BOTTOM_TEXT, intro, list } from './constants';

export const Validator = () => {
  return (
    <>
      <Intro mainTitle="Validator" paragraphSubtitle={intro.subtitle} paragraph={intro.paragraph} />
      <Advantages
        list={list}
        headTitle="Advantages"
        listTitle={
          <Typography variant="h4" className="summary">
            Here are five vital advantages of being a Validator in this <Br display={{ xs: 'none', md: 'inline' }} />{' '}
            ecosystem:
          </Typography>
        }
      />
      <PageParagraph sx={{ mb: { xs: 6, md: 11 } }} pProps={{ sx: { letterSpacing: '-0.25px !important' } }}>
        Being a Validator in the CratD2C ecosystem is not only a position of significant responsibility but also offers
        substantial advantages. These include financial incentives, a pivotal role in maintaining the blockchain&#39;s
        integrity and security, and the opportunity to contribute strategically to the advancement of the blockchain
        technology landscape.
      </PageParagraph>

      <CalculatorBlock activeOption={OptionsEnum.Validator} bottom={CALCULATOR_BOTTOM_TEXT} />
    </>
  );
};
