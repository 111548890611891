import { modalModel } from 'entities/modal';

export const participants: {
  name: string;
  modalType: Exclude<
    modalModel.ModalType,
    modalModel.ModalType.SendSuccess | modalModel.ModalType.SendReject | modalModel.ModalType.ApproveReject
  >;
}[] = [
  { name: 'Validator', modalType: modalModel.ModalType.Validator },
  { name: 'Delegator', modalType: modalModel.ModalType.Delegator },
  { name: 'LiteBacker', modalType: modalModel.ModalType.LiteBacker },
  { name: 'TurboBacker', modalType: modalModel.ModalType.TurboBacker },
];
