import { styled, Typography } from '@mui/material';
import { COLOR_ACCENT, FontWeights, TRANSITION_300 } from 'shared';

export const NavTitle = styled(Typography)(({ theme }) => ({
  transition: TRANSITION_300,
  fontSize: 14,
  lineHeight: '21px',
  fontWeight: FontWeights.Medium,
  letterSpacing: `-0.03em`,
  cursor: 'pointer',
  '&:hover, &.active': {
    color: COLOR_ACCENT,
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: 11,
  },
}));

export const MobileNavTitle = styled(Typography)(() => ({
  transition: TRANSITION_300,
  fontSize: 24,
  lineHeight: '31px',
  fontWeight: FontWeights.Medium,
  '&:hover, &.active': {
    color: COLOR_ACCENT,
  },
}));
