import { useInView } from 'react-intersection-observer';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { COLOR_ACCENT, COLOR_BG, COLOR_STROKE, Icons, useBreakpoints } from 'shared';

interface InfoRowProps {
  head?: React.ReactNode;
  title?: React.ReactNode;
  icon: React.ReactNode;
  isLast: boolean;
}

export const InfoRow = ({ title, icon, isLast, head, children }: React.PropsWithChildren<InfoRowProps>) => {
  const { isDownMdLayout } = useBreakpoints();

  const { ref, inView: isActive } = useInView({
    threshold: isDownMdLayout ? 0.1 : 0.3,
    triggerOnce: true,
  });

  return (
    <>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid ref={ref} item xs={4}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                position: 'relative',
              }}
            >
              {!!title && (
                <Typography
                  variant="h4"
                  fontSize={{ xs: 20, md: 24 }}
                  className="secondary"
                  sx={{
                    position: 'absolute',
                    left: { xs: '50%', md: 0 },
                    top: { xs: '100%', md: '50%' },
                    transform: { xs: 'translate(-50%, 18px)', md: 'translateY(-50%)' },
                  }}
                >
                  {title}
                </Typography>
              )}
              <Icons.Polygon
                sx={{
                  width: 66,
                  height: '100%',
                  fill: COLOR_BG,
                  transition: 'all 0.6s linear',
                  color: isActive ? COLOR_ACCENT : COLOR_STROKE,
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  '& > svg': {
                    transition: 'all 0.6s linear',
                    color: isActive ? COLOR_ACCENT : COLOR_STROKE,
                  },
                }}
              >
                {icon}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            {head}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={4}
        sx={{
          position: 'relative',
          ...(!isLast && {
            ':before': {
              transition: 'all 0.6s linear',
              content: '""',
              position: 'absolute',
              top: '0%',
              left: '50%',
              transform: 'translateX(-50%)',
              borderRight: '1px dashed',
              borderColor: isActive ? COLOR_ACCENT : COLOR_STROKE,
              height: '100%',
            },
          }),
        }}
      />
      <Grid item xs={8} pb={{ xs: 9, md: 16 }} pt={{ md: 1.5 }}>
        {children}
      </Grid>
    </>
  );
};
