import { buttonClasses, ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { COLOR_ACCENT } from '../colors';
import { BORDER_ACCENT, BORDER_BOLD, BORDER_RADIUS_M, colorVariations, TRANSITION_300 } from '../common';
import { FontFamilies, FontWeights } from '../Typography';

export const getMuiButtonOverrides = (theme: Theme): ComponentsOverrides['MuiButton'] => ({
  root: {
    fontFamily: FontFamilies.primary,
    boxShadow: 'none',
    textTransform: 'none',
    transition: TRANSITION_300,
    transitionProperty: 'background, backgound-color, border-color, border, color',
    ...colorVariations,

    '&:hover': {
      boxShadow: 'none',
    },
  },

  sizeLarge: {
    minHeight: 56,
    fontSize: 15,
    fontWeight: FontWeights.Medium,
    lineHeight: '23px',
  },

  sizeMedium: {
    minHeight: 44,
    fontSize: 15,
    fontWeight: FontWeights.Medium,
    lineHeight: '20px',
  },

  sizeSmall: {
    fontSize: 15,
    letterSpacing: '-0.45px',
  },

  containedPrimary: {
    minHeight: 44,
    fontSize: 18,
    fontWeight: FontWeights.Medium,
    lineHeight: '26px',

    borderRadius: BORDER_RADIUS_M,
    padding: '8.5px 42px',
    color: theme.themeColors.colorButtonContainedText,
    backgroundColor: theme.themeColors.colorButtonContainedBackground,

    '&:hover': {
      backgroundColor: theme.themeColors.colorButtonContainedHoverBackground,
    },

    '&:focus': {
      outline: '4px solid',
      outlineColor: theme.themeColors.colorButtonContainedFocusBorder,
    },

    [`&.${buttonClasses.disabled}`]: {
      pointerEvents: 'none',
      backgroundColor: theme.themeColors.colorButtonContainedDisabledBackground,
      opacity: 1,
      color: theme.themeColors.colorButtonContainedText,
    },
  },

  containedSecondary: {
    padding: '5px 16px',
    color: theme.themeColors.colorButtonContainedSecondaryText,
    backgroundColor: theme.themeColors.colorButtonContainedSecondaryBackground,
    border: '1px solid transparent',

    '&:hover': {
      borderColor: theme.themeColors.colorButtonContainedSecondaryHoverBorder,
      backgroundColor: theme.themeColors.colorButtonContainedSecondaryBackground,
    },

    '&:focus': {
      borderColor: theme.themeColors.colorButtonContainedSecondaryFocusBorder,
    },

    [`&.${buttonClasses.disabled}`]: {
      pointerEvents: 'none',
      backgroundColor: theme.themeColors.colorButtonContainedDisabledBackground,
      opacity: 1,
      color: theme.themeColors.colorButtonContainedText,
    },
  },

  outlinedPrimary: {
    borderRadius: BORDER_RADIUS_M,
    padding: '8.5px 42px',
    color: theme.themeColors.colorButtonOutlinedText,
    backgroundColor: theme.themeColors.colorButtonOutlinedBackground,
    border: `1px solid`,
    borderColor: theme.themeColors.colorButtonOutlinedBorder,

    '&:hover': {
      borderColor: theme.themeColors.colorButtonOutlinedHoverBorder,
      backgroundColor: theme.themeColors.colorButtonOutlinedBackground,
    },

    '&:focus': {
      borderColor: theme.themeColors.colorButtonOutlinedFocusBorder,
    },

    [`&.${buttonClasses.disabled}`]: {
      pointerEvents: 'none',
      border: BORDER_BOLD,
      borderColor: theme.themeColors.colorButtonOutlinedDisabledBorder,
      color: theme.themeColors.colorButtonOutlinedDisabledColor,
    },
  },

  outlined: {
    '&.MuiButton-outlinedSuccess': {
      border: BORDER_ACCENT,
      borderRadius: BORDER_RADIUS_M,
      color: COLOR_ACCENT,
    },
  },

  textPrimary: {
    padding: 0,
    color: theme.themeColors.colorButtonText,
    backgroundColor: theme.themeColors.colorButtonTextBackground,
    borderRadius: '4px',

    '&:hover': {
      backgroundColor: theme.themeColors.colorButtonTextBackground,
      color: theme.themeColors.colorButtonTextHover,
    },

    '&:focus': {
      borderColor: theme.themeColors.colorButtonOutlinedFocusBorder,
    },

    [`&.${buttonClasses.disabled}`]: {
      pointerEvents: 'none',
      opacity: 0.5,
    },
  },

  text: {
    minHeight: '0 !important',
  },
});

export const getMuiButtonDefaultProps = (): ComponentsProps['MuiButton'] => ({
  disableElevation: false,
  disableFocusRipple: true,
  disableRipple: true,
  variant: 'contained',
  size: 'medium',
});
