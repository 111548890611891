export const list = [
  {
    title: 'Blockchain Platform',
    description:
      'CratD2C decentralized e-commerce trading portal is built on an autonomous Smart Chain, which provides the underlying infrastructure for the decentralized marketplace.',
  },
  {
    title: 'Smart Contracts',
    description:
      'The platform utilizes smart contracts to automate and enforce the terms and conditions of transactions between buyers and sellers. Smart contracts handle tasks such as product listing, order management, payment processing, and dispute resolution, ensuring a secure and trustless environment for trade.',
  },
  {
    title: 'User Interface (UI)',
    description:
      'The portal features an intuitive and user-friendly interface for both buyers and sellers, allowing them to browse products easily, create listings, place orders, and manage their accounts.',
  },
  {
    title: 'Product Listings',
    description:
      'The platform host a range of decentralized applications (dApps) and coins, each tailored to different use cases or industries, which include dApps for supply chain management, e-commerce, real estate, tourism, and more.',
  },
  {
    title: 'Decentralized Identity and Reputation',
    description:
      'The platform incorporates decentralized identity solutions, enabling users to create and manage their digital identities without relying on a centralized authority. Additionally, a decentralized reputation system can track user behaviour, ratings, and reviews, allowing buyers and sellers to establish trust and credibility within the community.',
  },
  {
    title: 'Cryptocurrency Payments',
    description:
      'CratD2C decentralized e-commerce trading portal transactions are typically conducted using CRAT cryptocurrency (CRAT Coin). This enables fast, secure, and low-cost payments, as well as global accessibility and reduced reliance on the traditional approach',
  },
  {
    title: 'Decentralized Order Fulfillment',
    description:
      'The platform integrates with decentralized logistics and supply chain solutions, allowing for trustless and transparent order fulfilment, tracking, and delivery.',
  },
  {
    title: 'Dispute Resolution',
    description:
      'Decentralized dispute resolution mechanisms, such as community-based arbitration or automated smart contract resolutions, are employed to handle conflicts between buyers and sellers, ensuring a fair and transparent process.',
  },
  {
    title: 'Governance',
    description:
      'CratD2C decentralized e-commerce trading portal utilises a governance model allowing users to participate in decision-making processes, such as voting on platform updates, fee structures, or dispute resolution policies.',
  },
  {
    title: 'Interoperability and Scalability',
    description:
      'The platform is designed to ensure compatibility with other dApps systems on the network, allowing for seamless interaction and integration of various decentralized services with the capacity to handle high volumes of transactions and support a growing user base.',
  },
];
