import { useCallback, useEffect, useRef } from 'react';
import type { Chain } from 'viem';
import { useAccount, useConfig, useReconnect } from 'wagmi';

import { RECONNECT_KEY } from '../../../constants';
import type { WalletAdapterData } from '../../../types';

import { useEagerConnect } from './use-eager-connect';

export const useAutoConnect = (
  wallets: WalletAdapterData[],
  chains: readonly [Chain, ...Chain[]],
  onComplete: () => void,
) => {
  const { storage } = useConfig();
  const { reconnectAsync } = useReconnect();
  const { isConnected } = useAccount();
  const { eagerConnect } = useEagerConnect(wallets, chains);

  const isReconnectingRef = useRef(false);

  const tryReconnect = useCallback(async () => {
    if (isConnected) {
      onComplete();
      return;
    }

    const [connectResult, recentConnector] = await Promise.all([eagerConnect(), storage?.getItem('recentConnectorId')]);
    if (connectResult || !recentConnector) {
      onComplete();
      return;
    }

    const lastConnector = await storage?.getItem(RECONNECT_KEY);
    const wallet = wallets.find((data) => data.walletId === lastConnector);

    if (!wallet) {
      onComplete();
      return;
    }

    await reconnectAsync({ connectors: [wallet?.createConnectorFn] });
    onComplete();
  }, [isConnected, eagerConnect, storage, wallets, onComplete]);

  useEffect(() => {
    // StrictMode work-around
    if (isReconnectingRef.current) {
      return;
    }

    isReconnectingRef.current = true;

    tryReconnect();
  }, []);
};
