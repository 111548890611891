import { Typography } from '@mui/material';

export const intro = {
  paragraph:
    "The Intellectual Property (IP) Valuation Report for CratD2C's Decentralized Autonomous Smart Chain represents a comprehensive and meticulous assessment conducted by the prestigious London Rate International Office for IP Valuation.",
  subtitle:
    "This evaluation was performed in strict adherence to the outlined Scope of Work and IP-Valuation Assignment parameters, ensuring a thorough and reliable valuation of CratD2C's groundbreaking intellectual contributions to the field of blockchain technology.",
};

export const listTitle = (
  <Typography variant="h4" className="summary" maxWidth={700} mb={{ md: 4.5 }}>
    The valuation process was structured around several critical components, each designed to ensure a holistic and
    accurate appraisal of CratD2C&apos;s IP assets:
  </Typography>
);

export const list = [
  {
    title: 'Engagement Overview',
    description:
      "This section introduced the valuation assignment, outlining the objectives and the importance of the valuation in the context of CratD2C's strategic positioning within the blockchain industry.",
  },
  {
    title: 'Assignment',
    description:
      'Detailed specifications of the valuation assignment were provided, including the methodologies to be employed and the expected outcomes of the valuation process.',
  },
  {
    title: 'General Information of the Subject of Valuation',
    description:
      "Comprehensive background information on CratD2C's Decentralized Autonomous Smart Chain was presented, offering insight into its development, applications, and potential market impact.",
  },
  {
    title: 'Assessment',
    description:
      "An in-depth analysis was conducted to evaluate the IP assets. This included a review of the technological innovation, market applicability, competitive advantage, and potential revenue generation of CratD2C's offerings.",
  },
  {
    title: 'Conclusion',
    description:
      "The valuation concluded with a synthesis of the findings, highlighting the significant value of CratD2C's IP assets within the competitive landscape of blockchain technology.",
  },
  {
    title: 'Attachments',
    description:
      'Supporting documents and evidence were included to substantiate the valuation assessment and conclusions drawn.',
  },
];

export const table = {
  head: ['IP Spectrum', 'Certificate Number', 'Valuation (USD)'],
  rows: [
    ['Work of Science', 'IP EC-01-004149', '$ 128,204,307.00'],
    ["Author's Design", '1361-2023', '$ 32,051,077.00'],
  ],
  total: ['Total Value for IP Work', '', '$ 160,255,384.00'],
};
