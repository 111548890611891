import { useCallback, useState } from 'react';
import { Box, Button, Divider, IconButton, Stack, styled, Typography } from '@mui/material';
import { giveawayModel, LEADERBOARD_ITEMS_PER_PAGE } from 'entities/giveaway';
import _ from 'lodash';
import {
  BORDER_RADIUS_M,
  checkRequestStatusEquality,
  COLOR_ACCENT,
  COLOR_BG_BLACK_4,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_STROKE,
  Icons,
  shortenPhrase,
  Spinner,
  uiPropSelector,
  useAppDispatch,
  useBreakpoints,
  useIntervalAsync,
  useShallowSelector,
} from 'shared';

const Row = styled(Stack, { shouldForwardProp: (prop) => prop !== 'isLooser' })<{ isLooser?: boolean }>(
  ({ theme, isLooser }) => ({
    flexDirection: 'row',
    borderRadius: '5px',
    padding: theme.spacing(1, 2),
    width: '100%',

    '&:nth-of-type(odd)': {
      backgroundColor: isLooser ? 'transparent' : COLOR_BG_BLACK_4,
    },

    '.place': {
      color: isLooser ? COLOR_STROKE : COLOR_PRIMARY,
    },
    '.address': {
      color: isLooser ? COLOR_SECONDARY : COLOR_PRIMARY,
      letterSpacing: '-0.15px',
    },
    '.points': {
      color: isLooser ? COLOR_PRIMARY : COLOR_ACCENT,
      letterSpacing: '-0.15px',
    },
    '.fire': {
      display: isLooser ? 'none' : 'unset',
    },
  }),
);

const Shadow = styled(Box)({
  background: 'linear-gradient(180deg, rgba(15, 10, 19, 0) 0%, #0F0A13 83.5%)',
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 1,
  top: 20,
});

export const LeaderboardTable = () => {
  const dispatch = useAppDispatch();
  const { isDownSmLayout } = useBreakpoints();

  const {
    leaderboard: { leaderboardData, isLastPage },
  } = useShallowSelector(giveawayModel.selectors.getGiveaway);
  const getLeaderboardRequestStatus = useShallowSelector(uiPropSelector(giveawayModel.actionTypes.GET_LEADERBOARD));

  const [itemsCount, setItemsCount] = useState(LEADERBOARD_ITEMS_PER_PAGE);

  const isLoading = checkRequestStatusEquality(getLeaderboardRequestStatus);

  const getLeaderboardData = useCallback(async () => {
    dispatch(giveawayModel.thunks.getLeaderBoard({ pageSize: itemsCount, pageNumber: 1 }));
  }, [itemsCount, dispatch]);

  useIntervalAsync(getLeaderboardData, 30_000);

  return (
    <Stack alignItems="center" gap={1}>
      <Stack width="100%" alignItems="center" position="relative">
        {leaderboardData.length
          ? leaderboardData.map(({ username, amount }, index) => (
              <>
                <Row key={username} isLooser={index > 9}>
                  <Typography mr={{ xs: 2, md: 3 }} className="place">
                    {(index + 1).toString().padStart(2, '0')}
                  </Typography>
                  <Typography width="100%" className="address">
                    {isDownSmLayout ? shortenPhrase(username, 6, 4) : username}
                  </Typography>
                  <Icons.Fire className="fire" />
                  <Typography whiteSpace="nowrap" ml={{ xs: 0.5, md: 2 }} className="secondary" minWidth={119}>
                    <Typography component="span" className="points">
                      {amount.toLocaleString()}
                    </Typography>{' '}
                    points
                  </Typography>
                </Row>
                {index === 9 && <Divider flexItem sx={{ my: 1 }} />}
              </>
            ))
          : _.times(9, (index) => (
              <Row key={index}>
                <Typography mr={{ xs: 2, md: 3 }} className="place">
                  {(index + 1).toString().padStart(2, '0')}
                </Typography>
                <Typography width="100%" className="address">
                  -
                </Typography>
                <Typography whiteSpace="nowrap" ml={{ xs: 0.5, md: 2 }} className="secondary" minWidth={119}>
                  <Typography component="span" className="points">
                    0
                  </Typography>{' '}
                  points
                </Typography>
              </Row>
            ))}
        {!leaderboardData.length && <Shadow />}
      </Stack>
      {!isLastPage && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setItemsCount((prev) => prev + LEADERBOARD_ITEMS_PER_PAGE)}
          disabled={isLoading}
          sx={{ borderRadius: BORDER_RADIUS_M, minHeight: 37, width: 130, background: COLOR_BG_BLACK_4 }}
        >
          {!isLoading ? 'Load more' : <Spinner sx={{ width: 25 }} />}
        </Button>
      )}
      {leaderboardData.length > LEADERBOARD_ITEMS_PER_PAGE && (
        <IconButton className="transparent" onClick={() => setItemsCount(LEADERBOARD_ITEMS_PER_PAGE)}>
          <Icons.CloseCircle sx={{ color: COLOR_STROKE }} />
        </IconButton>
      )}
    </Stack>
  );
};
