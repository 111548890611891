import { Box, BoxProps, Typography } from '@mui/material';

export const ReferralBonusText = (props: BoxProps) => {
  return (
    <Box textAlign="center" {...props}>
      <Typography textTransform="uppercase" display="inline" fontSize={17}>
        earn 10%
      </Typography>{' '}
      <Typography textTransform="uppercase" display="inline" className="secondary" fontSize={{ xs: 14, sm: 16 }}>
        instantly in USDT/USDC of all purchases made by your referrals
      </Typography>
    </Box>
  );
};
