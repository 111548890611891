import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Referral: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <circle
      cx="7.99829"
      cy="8.50854"
      r="3.49145"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="17.0021"
      cy="9.49897"
      r="2.50104"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.99585 20.0033V18.9859C1.99585 16.785 3.77959 15.0012 5.98051 15.0012H10.0162C12.2171 15.0012 14.0008 16.785 14.0008 18.9859V20.0033"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0021 15.0012H18.1045C20.3055 15.0012 22.0892 16.785 22.0892 18.9859V20.0033"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
