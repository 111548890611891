import {
  ApproveModalPayload,
  ApproveRejectModalPayload,
  BiennialSupplyMechanismModalPayload,
  CratNewModalPayload,
  CratWidgetModalPayload,
  CtvgModalPayload,
  DecentralizedCommunityModalPayload,
  DelegatorModalPayload,
  EcommerceTradingModalPayload,
  EstateTradingModalPayload,
  IntellectualPropertyModalPayload,
  LiteBackerModalPayload,
  LuxuryModalPayload,
  PaymentApiModalPayload,
  PaymentGatewayModalPayload,
  PosModalPayload,
  SendModalPayload,
  SendRejectModalPayload,
  SendSuccessModalPayload,
  SendTimeoutModalPayload,
  StakingModalPayload,
  TrustLedgerModalPayload,
  TurboBackerModalPayload,
  ValidatorModalPayload,
  VendingMachineModalPayload,
} from '../ui';

export enum ModalType {
  POS = 'POS',
  IntellectualProperty = 'IntellectualProperty',
  DecentralizedCommunity = 'DecentralizedCommunity',
  CTVG = 'CTVG',
  EcommerceTrading = 'EcommerceTrading',
  EstateTrading = 'EstateTrading',
  Luxury = 'Luxury',
  PaymentGateway = 'PaymentGateway',
  VendingMachine = 'VendingMachine',
  PaymentApi = 'PaymentApi',
  CratNew = 'CratNew',
  CratWidget = 'CratWidget',
  TrustLedger = 'TrustLedger',
  BiennialSupplyMechanism = 'BiennialSupplyMechanism',
  Staking = 'Staking',
  Delegator = 'Delegator',
  Validator = 'Validator',
  LiteBacker = 'LiteBacker',
  TurboBacker = 'TurboBacker',
  Approve = 'Approve',
  ApproveReject = 'ApproveReject',
  Send = 'Send',
  SendReject = 'SendReject',
  SendSuccess = 'SendSuccess',
  SendTimeout = 'SendTimeout',
}

export interface IModal<T extends { type: ModalType; data: any } = { type: ModalType; data: any }> {
  onClose: () => void;
  data: T['data'];
  isOpen?: boolean;
}
export type IModalPayload =
  | PosModalPayload
  | IntellectualPropertyModalPayload
  | DecentralizedCommunityModalPayload
  | CtvgModalPayload
  | EcommerceTradingModalPayload
  | EstateTradingModalPayload
  | LuxuryModalPayload
  | PaymentGatewayModalPayload
  | VendingMachineModalPayload
  | PaymentApiModalPayload
  | CratWidgetModalPayload
  | CratNewModalPayload
  | TrustLedgerModalPayload
  | BiennialSupplyMechanismModalPayload
  | StakingModalPayload
  | DelegatorModalPayload
  | ValidatorModalPayload
  | LiteBackerModalPayload
  | TurboBackerModalPayload
  | ApproveModalPayload
  | ApproveRejectModalPayload
  | SendModalPayload
  | SendRejectModalPayload
  | SendSuccessModalPayload
  | SendTimeoutModalPayload;

export type ModalTransition = 'fade' | 'slide';
