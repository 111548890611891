import React from 'react';
import { Box } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface DecentralizedCommunityModalPayload {
  data?: null;
  type: ModalType.DecentralizedCommunity;
  isOpen?: boolean;
}

export const DecentralizedCommunityModal = React.forwardRef<
  HTMLElement,
  Pick<DecentralizedCommunityModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar
      isOpen={isOpen}
      ref={ref}
      onClose={onClose}
      title="Decentralized Autonomous Community (DAC)"
      icon={<Icons.People />}
      titleSx={{
        maxWidth: 338,
      }}
    >
      <Box component="p">
        Manufacturers featured on the CratD2C Trading Portal engage with a{' '}
        <Box
          color="inherit"
          component="a"
          target="_blank"
          rel="noreferrer"
          href="https://www.investopedia.com/tech/what-dao/"
          sx={{
            textDecoration: 'underline',
          }}
        >
          Decentralized Autonomous
        </Box>{' '}
        Community (DAC), wherein members cast their votes in favour or against the manufacturer, assessing aspects like
        service quality, customer satisfaction, and product longevity.
      </Box>
      <br />
      <Box component="p">
        A manufacturer that secures a positive vote outcome from the DAC after numerous transactions on the CratD2C
        Trading Portal is eligible to receive a percentage of the tokenomics incentives from the overall transactions on
        the blockchain every quarter.
      </Box>
    </ModalSidebar>
  );
});
