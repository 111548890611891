import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { COLOR_GRAY_4, Modal, Spinner } from 'shared';

export interface ApproveModalPayload {
  data?: null;
  type: ModalType.Approve;
  isOpen?: boolean;
}

export const ApproveModal = React.forwardRef<
  HTMLElement,
  Pick<ApproveModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose }, ref) => {
  return (
    <Modal ref={ref}>
      <Stack alignItems="center">
        <Typography variant="h5" component="span" color="inherit" className="no-resize">
          STEP 1/2{' '}
          <Typography variant="inherit" component="span" className="accent">
            APPROVE
          </Typography>
        </Typography>
        <Spinner
          sx={{
            mt: 4,
            mb: 3,
          }}
        />
        <Typography
          variant="h5"
          className="center"
          component="span"
          color="inherit"
          fontSize={18}
          sx={{
            maxWidth: 340,
          }}
        >
          Please press &quot;Approve&quot; button in Metamask extension
        </Typography>
        <Stack
          sx={{
            mt: 3,
            maxWidth: 405,
            width: '100%',
            color: COLOR_GRAY_4,
          }}
        >
          <Typography variant="body2" color="inherit" className="center">
            BEP-20 coins are deployed with functionality that allows other smart contracts to move coins.
          </Typography>
          <br />
          <Typography variant="body2" color="inherit" className="center">
            The Execution of this Transaction Approves a ratio 1:1 swap for CratD2C-Pre to CRAT Coin when the Blockchain
            Fully Go Live
          </Typography>
        </Stack>
      </Stack>
    </Modal>
  );
});
