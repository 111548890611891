import { walletConnect } from 'wagmi/connectors';

type WCConnector = ReturnType<typeof walletConnect>;

const cache: Record<string, WCConnector> = {};

const memoize = (key: string, getter: () => WCConnector) => {
  if (!cache[key]) {
    cache[key] = getter();
  }

  return cache[key];
};

type Params = { projectId: string; qrcode?: boolean };

export function getWcConnector({ projectId, qrcode = true }: Params) {
  return memoize(qrcode ? 'qrcode' : 'no-qrcode', () =>
    walletConnect({
      projectId,
      showQrModal: qrcode,
      qrModalOptions: { themeVariables: { '--wcm-z-index': '1000' } },
    }),
  );
}

getWcConnector.type = walletConnect.type;
