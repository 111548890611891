import { useCallback, useEffect, useMemo, useState } from 'react';
import { CHAINS } from 'app/config/wallet';
import { useWagmiConnect } from 'app/providers/with-wagmi-connect';
import { getToastMessage, shortenPhrase, useDisconnect, useSupportedChains } from 'shared';
import { ProviderRpcError } from 'viem';
import { useAccount, useConnections, useSwitchChain } from 'wagmi';

import { useStore } from '../store';
import { extractErrorMessage, getAuthToken } from '../utils';

import { useDisconnectTimeout } from './use-disconnect-timeout';

export const useAuth = () => {
  const [authState, dispatch] = useStore();
  const [isMessageSent, setIsMessageSent] = useState(false);
  const { disconnectAsync: wagmiDisconnectAsync, isDisconnecting } = useDisconnect();
  const { isReconnecting } = useWagmiConnect();
  const { address: accountAddress, isConnecting } = useAccount();
  const { isSupported: isSupportedChain } = useSupportedChains();
  const { switchChainAsync: wagmiSwitchChainAsync } = useSwitchChain();
  const connections = useConnections();

  const disconnectAsync = useCallback(
    async (showToast = true) => {
      await wagmiDisconnectAsync();
      dispatch({ type: 'disconnect' });

      if (isMessageSent) {
        setIsMessageSent(false);
      }

      if (showToast) {
        getToastMessage('info', 'Disconnected from wallet', { autoClose: 3000 });
      }
    },
    [wagmiDisconnectAsync, dispatch, isMessageSent],
  );

  const switchChainAsync = useCallback(async () => {
    await wagmiSwitchChainAsync?.({ chainId: CHAINS[0].id });
    getToastMessage('success', `Switched to ${CHAINS[0].name}`);
  }, [wagmiSwitchChainAsync]);

  const authorize = useCallback(async () => {
    if (!connections.length || isMessageSent || !accountAddress || isDisconnecting) {
      return false;
    }

    /**
     * In order to connect with walletConnect on mobile devices, we need to add artificial delay
     * Don't know actually why, it's just that
     * */
    return new Promise((resolve) => {
      setTimeout(async () => {
        try {
          setIsMessageSent(true);

          const token = await getAuthToken(accountAddress);

          dispatch({ type: 'authorize', payload: { token, address: accountAddress } });
          getToastMessage('success', `Wallet connected: ${shortenPhrase(accountAddress, 8, 6)}`);
          resolve(true);
        } catch (error) {
          if (error instanceof ProviderRpcError && error.code === 4001) {
            setIsMessageSent(false);
          }

          getToastMessage('error', extractErrorMessage(error));
          disconnectAsync(false);
          console.error(error);
          resolve(false);
        }
      }, 2_000);
    });
  }, [connections, accountAddress, disconnectAsync, isMessageSent, isDisconnecting]);

  const handleAuthProcess = useCallback(async () => {
    if (isDisconnecting) {
      return;
    }

    const notPendingAndAuthorized = !isReconnecting && !isConnecting && authState.isAuthorized;

    if (!accountAddress && notPendingAndAuthorized) {
      await disconnectAsync(false);
      return;
    }

    if (isReconnecting || (!accountAddress && !isConnecting)) {
      return;
    }

    if (accountAddress && !authState.isAuthorized) {
      await authorize();
      return;
    }

    if (!isSupportedChain) {
      getToastMessage('error', `You've changed to wrong network. Please, choose ${CHAINS[0].name}`);
      await switchChainAsync();
    }
  }, [
    isDisconnecting,
    isReconnecting,
    authState.isAuthorized,
    accountAddress,
    disconnectAsync,
    isConnecting,
    authorize,
    isSupportedChain,
    switchChainAsync,
  ]);

  const value = useMemo(
    () => ({
      state: authState,
      actions: { disconnectAsync },
    }),
    [disconnectAsync, authState],
  );

  useEffect(() => {
    handleAuthProcess();
  }, [handleAuthProcess]);

  useDisconnectTimeout(isMessageSent, authState.isAuthorized, disconnectAsync, 5_000);

  return value;
};
