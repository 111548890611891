import { Box, Typography } from '@mui/material';
import { rows } from 'pages/smart-chain/smart-chain-scope/constants';
import { Br, COLOR_SECONDARY, Section, SplittedRows } from 'shared';

export const ScopeInfo = () => {
  return (
    <Section>
      <SplittedRows data={rows} textColor={COLOR_SECONDARY} />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Typography
          variant="h4"
          className="summary"
          sx={{
            gridColumn: { xs: 'span 13', md: '5 / 13' },
          }}
        >
          Incorporating these features and attributes, the CratD2C Decentralized Autonomous Smart Chain presents a
          <Br display={{ xs: 'none', lg: 'inline' }} />
          comprehensive ecosystem that redefines the landscape of digital interactions, transactions, and opportunities
          for its participants.
        </Typography>
      </Box>
    </Section>
  );
};
