export const list = [
  {
    title: 'Sustainable Growth',
    description:
      'The Zig-Zag structure prevents sudden coin supply influx, preventing massive sell-offs that can destabilize the market. It supports a gradual and sustainable growth trajectory.',
  },
  {
    title: 'Fair Distribution',
    description:
      'The mechanism ensures that the distribution is equitable, catering to participants with varying levels of commitment and contributions, from core members to validators , Delegators, LiteBacker, TurboBacker, and Ambassadors.',
  },
  {
    title: 'Community Engagement',
    description:
      "By incentivizing consistent participation, the mechanism promotes a vibrant and engaged community that actively contributes to the ecosystem's development.",
  },
  {
    title: 'Reduced Market Volatility',
    description:
      'The controlled release of coins prevents extreme market fluctuations caused by excessive supply, providing a more stable and predictable environment for participants.',
  },
  {
    title: 'Loyalty and Longevity:',
    description:
      'Participants are rewarded for their long-term commitment. The structure encourages stakeholders to stay engaged over time, fostering loyalty and ongoing involvement.',
  },
  {
    title: 'Strategic Flexibility',
    description:
      'The eight layers offer strategic flexibility to accommodate different participation levels, from core members to LiteBackers and TurboBackers, catering to a diverse audience.',
  },
  {
    title: 'Incentivized Staking',
    description:
      "Staking within each layer allows participants to earn attractive APR rates, generating passive income while contributing to the network's stability.",
  },
  {
    title: 'Innovative Royalty System',
    description:
      'The structure introduces a unique royalty system, enhancing the value proposition for participants and fostering a sense of ownership and responsibility.',
  },
  {
    title: 'Steady Coin Demand',
    description:
      'The controlled supply mechanism prevents oversupply scenarios, promoting steady demand for CRAT coins and potentially mitigating price volatility.',
  },
  {
    title: 'Enhanced Network Security',
    description:
      "As participants are motivated to stay committed over time, the network's security and decentralization are reinforced, bolstering its overall resilience.",
  },
];

export const intro = {
  paragraph:
    'CratD2C introduces an innovative supply mechanism designed to operate until 2039, underscoring its dedication to sustainability, fairness, and community involvement. This pioneering approach ensures a regulated and equitable distribution of CRAT native coins, heralding a new era in the blockchain landscape.',
  subtitle: (
    <>
      {' '}
      The CratD2C Decentralized Autonomous Smart Chain unveils the revolutionary Biennial 8-Layer Zig-Zag Supply
      Mechanism, marking a significant advancement in blockchain technology. This mechanism represents a paradigm shift,
      offering numerous benefits that differentiate it from existing projects and establish a new standard for
      sustainability, fairness, and community engagement. <br /> <br /> The 8-Layer Zig-Zag Supply Mechanism is
      meticulously crafted to facilitate a balanced and controlled distribution of CRAT native coins among participants.
      Comprising eight distinct layers, each serves a specific purpose while ensuring overall ecosystem equilibrium.
      Let&#39;s explore this mechanism&#39;s detailed explanation and its unique advantages.
    </>
  ),
  mainTitle: 'Biennial 8-Layer Zig-Zag Supply Mechanism:',
};
