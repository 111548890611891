import { IntroSection, ProgressInfo, TokenomicsRoadmap } from './ui';

export const Tokenomics = () => {
  return (
    <>
      <IntroSection />
      <TokenomicsRoadmap />
      <ProgressInfo />
    </>
  );
};
