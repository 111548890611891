import { Typography } from '@mui/material';
import { crowdsaleModel, mapPriceToRound } from 'entities/crowdsale';
import { COLOR_ACCENT, fromDecimals, useShallowSelector } from 'shared';

export const Round = () => {
  // const price = useShallowSelector(crowdsaleModel.selectors.getProp('cratPrice'));
  // const round = mapPriceToRound[fromDecimals(price)] || 1;

  return (
    <Typography variant="h4" className="large center" sx={{ span: { color: COLOR_ACCENT } }}>
      {/* Round {round} <span>of 10</span> */}
      Round 2 <span>of 4</span>
    </Typography>
  );
};
