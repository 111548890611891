import { Grid, styled, Typography } from '@mui/material';
import { COLOR_SECONDARY, COLOR_STROKE } from 'shared';

import { table } from '../constants';

const TableRow = styled(Grid)({
  borderBottom: `1px solid ${COLOR_STROKE}`,
});

export const EcosystemValuationTable = () => (
  <>
    <TableRow item container xs={12} pb={1.75}>
      {table.head.map((title) => (
        <Grid item xs={4} key={title}>
          <Typography color={COLOR_SECONDARY}>{title}</Typography>
        </Grid>
      ))}
    </TableRow>
    {table.rows.map((data, index) => (
      <TableRow key={`${index + 1}`} item container xs={12} py={2.25}>
        {data.map((value) => (
          <Grid item xs={4} key={value}>
            <Typography>{value}</Typography>
          </Grid>
        ))}
      </TableRow>
    ))}
    <Grid item container xs={12} py={2.25}>
      {table.total.map((value) => (
        <Grid item xs={4} key={value}>
          <Typography className="semi-bold">{value}</Typography>
        </Grid>
      ))}
    </Grid>
  </>
);
