import { Icons } from 'shared/ui/';

export const analysisData = [
  {
    name: 'CratD2C',
    total: 100,
    filled: 0,
    labelText: '0.5-3 sec.',
    Icon: Icons.CratChain,
  },
  {
    name: 'EOS',
    total: 100,
    filled: 0.62,
    labelText: '4,000 - 8,000 sec.',
    Icon: Icons.Eos,
  },
  {
    name: 'Ethereum',
    total: 100,
    filled: 1.72,
    labelText: '30-45 sec.',
    Icon: Icons.Eth,
  },
  {
    name: 'Cardano',
    total: 100,
    filled: 7,
    labelText: '1,000 - 1,500 sec.',
    Icon: Icons.Cardano,
  },
  {
    name: 'Solana',
    total: 100,
    filled: 8.15,
    labelText: '0.5-3 sub-sec.',
    Icon: Icons.Solana,
  },
  {
    name: 'Bitcoin',
    total: 100,
    filled: 66,
    labelText: '3-7 min.',
    Icon: Icons.Bitcoin,
  },
];
