import { Box, Card, Typography } from '@mui/material';
import { IPress } from 'pages/press-releases/constants';

type PressCardProps = { maxHeight: number } & IPress;

export const PressCard = ({ name, image, link, paragraph, height, maxHeight }: PressCardProps) => {
  return (
    <Card sx={{ pl: { xs: 2, md: 3 }, pr: { xs: 2, md: 2.75 }, py: 2.5 }}>
      <Box height={{ xs: 'auto', md: maxHeight }}>
        <Box
          component="img"
          src={image}
          alt={name}
          sx={{ height: { xs: 'auto', md: height }, width: 'fit-content', maxWidth: '100%' }}
        />
      </Box>
      <Typography className="secondary medium" mt={1.5} mb={3.5}>
        {paragraph}
      </Typography>
      <Typography className="accent medium" component="a" href={link} rel="noreferrer" target="_blank">
        Read more
      </Typography>
    </Card>
  );
};
