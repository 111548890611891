import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const IntellectualProperty: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon viewBox="0 0 36 35" sx={{ width: '36px', height: '35px', fill: 'none', ...sx }} {...props}>
    <g clipPath="url(#clip0_3709_257)">
      <path
        d="M20.9082 27.1192H21.8707V27.3005C21.8707 28.5059 21.8723 29.7113 21.8704 30.917C21.8668 32.8925 20.4779 34.5039 18.5262 34.7992C16.519 35.103 14.5247 33.6539 14.1941 31.6467C14.1566 31.4195 14.1324 31.1872 14.1314 30.9571C14.1253 29.7194 14.1285 28.4816 14.1285 27.2439V27.0897H15.1071C15.0874 26.9055 15.0845 26.7346 15.0484 26.5711C14.8998 25.895 14.5131 25.406 13.8967 25.0819C12.6166 24.4084 11.5612 23.4745 10.7608 22.2674C9.5998 20.5172 9.11164 18.5898 9.35152 16.5002C9.75413 12.9945 12.2795 9.95769 15.6621 9.0483C19.0705 8.13212 22.027 9.04539 24.4348 11.6C26.0536 13.3177 26.8146 15.4205 26.6929 17.779C26.5237 21.0614 24.9508 23.5116 22.0922 25.1226C21.3399 25.5466 20.936 26.1551 20.9034 27.0148C20.9021 27.0458 20.906 27.0768 20.9079 27.1192H20.9082ZM18.943 27.1201C18.9792 26.8086 18.9921 26.5064 19.0525 26.2143C19.3162 24.9336 20.0414 23.9993 21.1956 23.3882C23.7375 22.0425 25.1341 19.2765 24.7005 16.4398C24.4804 14.9991 23.8663 13.7455 22.8435 12.7069C21.3774 11.218 19.5807 10.5559 17.5079 10.6674C16.25 10.7349 15.1152 11.1844 14.0875 11.9125C11.4023 13.8153 10.4683 17.3702 11.884 20.3401C12.5349 21.7058 13.5477 22.7225 14.8811 23.4351C16.0521 24.0607 16.7343 25.0528 16.9681 26.3552C17.0133 26.6063 17.0249 26.8632 17.0523 27.1201H18.9433H18.943Z"
        fill="#B4EB56"
      />
      <path
        d="M8.87581 11.5056C8.87839 12.3891 8.06996 12.8441 7.37064 12.4389C6.26066 11.7958 5.15422 11.1462 4.04617 10.4992C3.77271 10.3396 3.49537 10.1858 3.22675 10.018C2.83061 9.77113 2.67531 9.3132 2.82415 8.864C2.96556 8.43677 3.34944 8.16725 3.7995 8.207C3.94931 8.22025 4.10751 8.27195 4.23795 8.34758C5.61365 9.14257 6.98386 9.94693 8.35665 10.7471C8.68403 10.9378 8.86709 11.2131 8.87581 11.5056Z"
        fill="#B4EB56"
      />
      <path
        d="M8.91682 23.2963C8.90391 23.6421 8.7428 23.9562 8.40864 24.1534C7.02132 24.971 5.63077 25.7841 4.23602 26.5894C3.78983 26.847 3.20287 26.6492 2.93231 26.1757C2.6737 25.7236 2.81737 25.1623 3.27939 24.8857C3.94706 24.4856 4.62215 24.0981 5.29467 23.7055C6.02239 23.2805 6.74721 22.8497 7.47978 22.4331C8.12388 22.067 8.91327 22.5236 8.9165 23.2963H8.91682Z"
        fill="#B4EB56"
      />
      <path
        d="M18.9672 3.3981C18.9672 4.20537 18.9701 5.01232 18.9659 5.81959C18.964 6.23195 18.7192 6.57742 18.346 6.7167C17.9631 6.85987 17.5385 6.7571 17.2719 6.45397C17.1166 6.27784 17.0339 6.06973 17.0336 5.83543C17.0316 4.20505 17.0291 2.57467 17.0346 0.944294C17.0365 0.410422 17.4685 0 17.9996 0C18.531 0 18.9594 0.409776 18.9646 0.944294C18.9723 1.76223 18.9665 2.58017 18.9672 3.3981Z"
        fill="#B4EB56"
      />
      <path
        d="M3.97397 16.4684C4.78564 16.4684 5.59763 16.4639 6.40929 16.47C6.87711 16.4736 7.24485 16.7951 7.33686 17.2592C7.42339 17.6948 7.19513 18.1453 6.78381 18.3156C6.65337 18.3696 6.50357 18.4009 6.36248 18.4016C4.76594 18.4074 3.1694 18.4068 1.57287 18.4045C1.00302 18.4035 0.580078 17.9873 0.580078 17.4373C0.580078 16.8866 1.00238 16.4707 1.57093 16.4687C2.37194 16.4661 3.17296 16.4681 3.97397 16.4681V16.4687V16.4684Z"
        fill="#B4EB56"
      />
      <path
        d="M32.0162 18.4051C31.2097 18.4051 30.4035 18.4086 29.597 18.4035C29.1595 18.4006 28.7885 18.1087 28.6797 17.7003C28.5661 17.273 28.7324 16.8193 29.1095 16.6141C29.2593 16.5327 29.4436 16.4758 29.6125 16.4745C31.2252 16.4641 32.8378 16.4661 34.4505 16.4696C35.0042 16.4709 35.422 16.8988 35.4188 17.4443C35.4155 17.9811 34.9987 18.4009 34.4515 18.4041C33.6398 18.409 32.8281 18.4054 32.0162 18.4051Z"
        fill="#B4EB56"
      />
      <path
        d="M27.2602 3.1686C27.2015 3.33309 27.1666 3.51115 27.0807 3.65981C26.2781 5.05072 25.4687 6.43743 24.6573 7.82317C24.4304 8.21097 24.0533 8.39001 23.6574 8.31665C23.2277 8.23715 22.9245 7.93176 22.8529 7.50324C22.8167 7.28736 22.8635 7.08409 22.9717 6.89827C23.7921 5.48667 24.6121 4.07508 25.4386 2.66704C25.6721 2.26955 26.1095 2.10538 26.5344 2.23077C26.9467 2.35228 27.2283 2.72909 27.2605 3.1686H27.2602Z"
        fill="#B4EB56"
      />
      <path
        d="M28.0854 12.6641C27.409 12.6288 26.9173 11.9505 27.1304 11.3261C27.1933 11.1416 27.3044 10.9926 27.4739 10.8937C28.879 10.0745 30.2847 9.2569 31.6898 8.43767C31.8899 8.32101 32.0998 8.24927 32.3339 8.28094C32.7607 8.33911 33.0774 8.6306 33.1675 9.04426C33.255 9.44692 33.0881 9.84086 32.7207 10.0658C32.3955 10.2645 32.0633 10.452 31.7343 10.6439C30.6867 11.2557 29.6409 11.8707 28.5894 12.4753C28.436 12.5635 28.2546 12.6023 28.086 12.6637L28.0854 12.6641Z"
        fill="#B4EB56"
      />
      <path
        d="M32.2327 26.6493C32.0629 26.5896 31.8788 26.5547 31.7258 26.4664C30.354 25.6743 28.9867 24.8745 27.62 24.0731C27.1312 23.7864 26.9775 23.2215 27.25 22.7507C27.5235 22.2775 28.0878 22.1221 28.576 22.4049C29.9656 23.2096 31.3516 24.0197 32.736 24.8331C33.1364 25.0684 33.3081 25.4843 33.1951 25.8889C33.0718 26.3307 32.6963 26.618 32.2324 26.6497L32.2327 26.6493Z"
        fill="#B4EB56"
      />
      <path
        d="M13.134 7.4298C13.1321 7.80597 12.9013 8.15983 12.5681 8.2975C12.2061 8.44713 11.7787 8.35729 11.501 8.06515C11.4277 7.98791 11.3632 7.89936 11.3089 7.80758C10.5118 6.45674 9.71465 5.10558 8.92267 3.75183C8.54751 3.11035 8.90782 2.36415 9.64007 2.27722C10.0401 2.22972 10.3542 2.40423 10.5612 2.74711C10.8469 3.22087 11.1204 3.70207 11.399 4.18003C11.9211 5.07585 12.4473 5.96908 12.9616 6.8691C13.0572 7.0365 13.1053 7.23137 13.1754 7.41364C13.1615 7.41913 13.1479 7.4243 13.134 7.4298Z"
        fill="#B4EB56"
      />
      <path
        d="M13.1553 17.4528C13.1869 15.0029 14.9672 12.9605 17.3825 12.6315C19.2644 12.3752 21.1828 13.3195 22.1472 14.9774C22.4442 15.4876 22.318 16.0577 21.845 16.3421C21.3627 16.6323 20.7977 16.4726 20.4893 15.9601C19.8872 14.9586 18.9871 14.4926 17.8358 14.5382C16.2247 14.6018 14.9607 16.0836 15.1195 17.69C15.2539 19.0509 16.1236 20.0475 17.4164 20.3219C18.5932 20.5717 19.8236 20.0372 20.4648 18.9879C20.6459 18.6918 20.8745 18.4743 21.2313 18.4372C21.6239 18.3965 21.9416 18.5429 22.1566 18.8731C22.3729 19.2053 22.3677 19.5544 22.1682 19.8947C21.6817 20.7236 21.0053 21.3541 20.1516 21.7904C17.344 23.2252 13.9156 21.5687 13.2915 18.4734C13.2237 18.1376 13.1995 17.7931 13.1553 17.4528Z"
        fill="#B4EB56"
      />
    </g>
    <defs>
      <clipPath id="clip0_3709_257">
        <rect width="34.8387" height="34.8387" fill="white" transform="translate(0.580078)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
