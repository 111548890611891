import { appRoutes, Routes } from 'shared';

import type { Navigation, NavigationItem } from './navigation.types';

export const splitPathnameRegExp = /\/[^/]+/g;

export const buildNavigationMap = (routes: Routes) => {
  const navigationMap: Navigation = {};

  const mapRouteTree = (tree: Routes) => {
    Object.values(tree).forEach((node) => {
      if (node?.children) {
        mapRouteTree(node.children);
      }

      if (!node?.root) {
        return;
      }

      navigationMap[node.root.path] = {
        name: node.root.title,
        path: node.root.path,
        id: node.id,
      };
    });
  };

  mapRouteTree(routes);

  return navigationMap;
};

export const extractRootPath = (pathname: string) => pathname.split('/')[1];

export const buildPageBottomNavigation = (pathItem: string) => {
  const navigationMapByIds = new Map<number, Omit<NavigationItem, 'id'>>();

  const fullNavigationMapForPath = buildNavigationMap({ [pathItem]: appRoutes[pathItem] });

  Object.keys(fullNavigationMapForPath).forEach((key) => {
    const { id, ...rest } = fullNavigationMapForPath[key];
    navigationMapByIds.set(id, rest);
  });

  return { navigationMapByIds, fullNavigationMapForPath };
};
