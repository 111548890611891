import { wagmiConfig } from 'app/config/wallet';
import { getContract } from 'viem';

import { ERC_20_ABI } from '../abi';

const address = {
  testnet: '0xba3B9D3A521c5A71fc26182F77001248C88dA774',
  mainnet: '0x3953E03D3cc3972BE18B6C15EA4d324d28B32a8C',
} as const;

export const cratContract = getContract({
  abi: ERC_20_ABI,
  address: process.env.REACT_APP_NODE_ENV === 'production' ? address.mainnet : address.testnet,
  client: wagmiConfig.getClient(),
});
