import { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { userModel } from 'entities/user';
import { OptionsEnum } from 'features/calculator';
import { fromDecimals, useShallowSelector } from 'shared';

import { StatusTip } from '..';

export const Status = () => {
  const cratBalance = useShallowSelector(userModel.selectors.getProp('cratBalance'));

  const status = useMemo(() => {
    const formattedCratBalance = +fromDecimals(cratBalance);
    if (formattedCratBalance >= 300000) return OptionsEnum.TurboBacker;
    if (formattedCratBalance >= 200000) return OptionsEnum.LiteBacker;
    if (formattedCratBalance >= 100000) return OptionsEnum.Validator;
    if (formattedCratBalance >= 1000) return OptionsEnum.Delegator;
    return undefined;
  }, [cratBalance]);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="h6" component="div">
        Your current status - {!status && 'no any'}
        {status && (
          <Typography variant="inherit" component="span" className="accent">
            {status}
          </Typography>
        )}
      </Typography>
      {status && <StatusTip status={status} />}
    </Stack>
  );
};
