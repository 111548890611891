import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowGray: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon {...props} viewBox="0 0 20 20" sx={{ width: '20px', height: '20px', fill: 'none', ...sx }}>
    <path
      d="M14.804 13.0345C15.1083 12.7746 15.2697 12.3848 15.2383 11.9859C15.2102 11.6287 15.0046 11.3708 14.9021 11.2484C14.7872 11.1111 14.6306 10.9546 14.4712 10.7953L11.0451 7.36916C10.975 7.29895 10.8912 7.21508 10.8113 7.14727C10.7187 7.06869 10.5782 6.96407 10.3844 6.90108C10.1333 6.81951 9.86289 6.81951 9.61183 6.90108C9.41797 6.96407 9.27747 7.06869 9.18491 7.14727C9.10503 7.21508 9.02124 7.29893 8.95108 7.36915L5.52494 10.7953C5.36561 10.9546 5.20899 11.1111 5.0941 11.2484C4.99158 11.3708 4.78599 11.6287 4.75788 11.9859C4.72649 12.3848 4.88796 12.7746 5.19221 13.0345C5.46467 13.2672 5.79238 13.3041 5.95146 13.3182C6.12977 13.334 6.35123 13.334 6.57655 13.334L13.4197 13.334C13.645 13.334 13.8664 13.334 14.0447 13.3182C14.2038 13.3041 14.5315 13.2672 14.804 13.0345Z"
      fill="#4B484D"
    />
  </SvgIcon>
);
