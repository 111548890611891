import { Box, Grid, Typography } from '@mui/material';
import { COLOR_STROKE, Intro, Section } from 'shared';

import { termsAndConditions } from './constants';

export const TermsAndConditions = () => {
  return (
    <>
      <Intro
        mainTitle="Terms & Conditions"
        subTitle="Please ensure you thoroughly review our Terms and Conditions and Disclaimer for complete understanding and compliance"
        paragraphProps={{ letterSpacing: '-0.4px !important' }}
        sx={{ h1: { maxWidth: 1000 } }}
      />

      <Section mb={{ xs: 3, sm: 4 }}>
        {termsAndConditions.map((term, i) => (
          <Grid
            key={term.title}
            container
            sx={{ borderTop: `1px solid ${COLOR_STROKE}`, paddingTop: 2, paddingBottom: 4 }}
            rowSpacing={1}
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h5" className="secondary">
                {i + 1}. {term.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" className="secondary">
                {term.description}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Section>
      <Section>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            mt: { xs: 2, sm: 3.5 },
          }}
        >
          <Typography
            variant="h5"
            className="secondary"
            sx={{
              gridColumn: { xs: 'span 12', md: '7 / 14' },
            }}
          >
            Participating in the CratD2C Pre-Development Stage confirms that you have read, understood, and agree to
            these Terms and Conditions.
          </Typography>
        </Box>
      </Section>
    </>
  );
};
