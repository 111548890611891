import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Tiktok: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M21.0987 6.543V10.1235C21.0987 10.1235 19.8327 10.071 18.8929 9.822C17.5834 9.47175 16.7434 8.93325 16.7434 8.93325C16.7434 8.93325 16.1607 8.54775 16.1172 8.5215V15.9202C16.1172 16.3312 16.0084 17.3603 15.6822 18.2183C15.3964 18.9517 14.9869 19.6313 14.4732 20.2275C14.4732 20.2275 13.6684 21.2257 12.2539 21.8955C10.9789 22.5 9.8562 22.4858 9.5217 22.5C9.5217 22.5 7.58595 22.578 5.83995 21.3915L5.83095 21.3825V21.3915C5.2407 20.9797 4.7142 20.4832 4.2687 19.9177C3.71595 19.2127 3.37695 18.3773 3.28995 18.132V18.123C3.1512 17.7067 2.8587 16.7002 2.90295 15.729C2.9727 14.0175 3.5472 12.9623 3.69945 12.6998C4.0992 11.9798 4.62195 11.3355 5.2437 10.7955C6.6777 9.59325 8.5497 9.04575 10.4059 9.285L10.4014 12.957C10.0992 12.8587 9.7827 12.8085 9.4647 12.8085C7.7847 12.8085 6.4227 14.1787 6.4227 15.8707C6.4227 17.5627 7.7847 18.933 9.4647 18.933C9.9882 18.933 10.5019 18.798 10.9572 18.5392C11.8429 18.036 12.4204 17.1248 12.4977 16.1085V16.101C12.4999 16.0905 12.4999 16.08 12.4999 16.0702C12.5014 16.0455 12.5037 16.0245 12.5037 16.002C12.5112 15.819 12.5112 15.6338 12.5112 15.447V1.5H16.1179C16.1119 1.5 16.0744 1.84575 16.1659 2.37975H16.1614C16.2702 3.02325 16.5664 3.94275 17.3577 4.84875C17.6742 5.19075 18.0304 5.49375 18.4197 5.751C18.5532 5.838 18.6912 5.91825 18.8329 5.99175C19.7554 6.4515 20.6562 6.59175 21.0994 6.54375L21.0987 6.543Z"
      fill="#B4EB56"
    />
  </SvgIcon>
);
