/* eslint-disable @typescript-eslint/ban-ts-comment */

import { injected } from 'wagmi/connectors';

import { Icon } from './icon';

export const rainbowId = 'rainbow';
export const rainbowName = 'Rainbow';

const getRainbowConnector = () => injected({ target: 'rainbow' });

const deeplinkDAppUrl = globalThis.window ? globalThis.window.location.host + globalThis.window.location.pathname : '';
/** @fallback https://rainbow.me/dapp?url=${deeplinkDAppUrl} */
const deeplink = `rainbow://dapp?url=${deeplinkDAppUrl}`;

const detector = () => {
  // @ts-ignore
  return Boolean(globalThis.window?.ethereum?.isRainbow);
};

export const Rainbow = () => {
  return {
    walletName: rainbowName,
    walletId: rainbowId,
    type: injected.type,
    deeplink,
    Icon,
    detector,
    createConnectorFn: getRainbowConnector(),
  };
};
