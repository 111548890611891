import { Typography } from '@mui/material';
import { Icons } from 'shared';

import { ApiSection } from '../ui/api-section';
import { EcommerceTrading } from '../ui/ecommerce-trading';
import { EstateTrading } from '../ui/estate-trading';
import { Luxury } from '../ui/luxury';
import { PaymentGateway } from '../ui/payment-gateway';
import { Pos } from '../ui/pos';
import { VendingMachine } from '../ui/vending-machine';
import { Widget } from '../ui/widget';

export const steps = [
  {
    id: '1',
    title: 'dApp',
    children: <EcommerceTrading />,
    icon: <Icons.Buy />,
    head: (
      <Typography variant="h3">
        CratD2C{' '}
        <Typography variant="inherit" component="span" className="accent" fontWeight="inherit">
          eCommerce Trading Portal
        </Typography>
      </Typography>
    ),
  },

  {
    id: '2',
    title: 'dApp',
    children: <EstateTrading />,
    icon: <Icons.Building />,
    head: (
      <Typography variant="h3">
        CratD2C{' '}
        <Typography variant="inherit" component="span" className="accent" fontWeight="inherit">
          Real Estate Trading Portal
        </Typography>
      </Typography>
    ),
  },

  {
    id: '3',
    title: 'dApp',
    children: <Luxury />,
    icon: <Icons.Diamond />,
    head: (
      <Typography variant="h3">
        CRAT{' '}
        <Typography variant="inherit" component="span" className="accent" fontWeight="inherit">
          Luxury LifeStyle Booking Portal
        </Typography>
      </Typography>
    ),
  },

  {
    id: '4',
    children: <PaymentGateway />,
    icon: <Icons.Exchange />,
    head: (
      <Typography variant="h3">
        CratD2C{' '}
        <Typography variant="inherit" component="span" className="accent" fontWeight="inherit">
          Universal Payment Gateway
        </Typography>
      </Typography>
    ),
  },

  {
    id: '5',
    children: <VendingMachine />,
    icon: <Icons.Display />,
    head: (
      <Typography variant="h3">
        Vending{' '}
        <Typography variant="inherit" component="span" className="accent" fontWeight="inherit">
          Machine
        </Typography>
      </Typography>
    ),
  },

  {
    id: '6',
    children: <Pos />,
    icon: <Icons.Pos />,
    head: <Typography variant="h3">POS</Typography>,
  },

  {
    id: '7',
    children: <ApiSection />,
    icon: <Icons.Settings />,
    head: <Typography variant="h3">API</Typography>,
  },

  {
    id: '8',
    children: <Widget />,
    icon: <Icons.Widget />,
    head: <Typography variant="h3">Widget</Typography>,
  },
];
