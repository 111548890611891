import { Box, Stack, Typography } from '@mui/material';
import { COLOR_ACCENT, COLOR_PRIMARY, COLOR_SECONDARY, PageParagraph, SplittedRows } from 'shared';

import { bottomParagraph, rows } from './constants';

export const IpPortionRoyalties = () => {
  return (
    <>
      <Stack gap={3.5}>
        <Typography
          variant="h1"
          className="no-resize"
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              fontSize: 30,
            },
          })}
        >
          <Box component="span" color={COLOR_ACCENT}>
            IP-Portion{' '}
          </Box>
          Royalties
        </Typography>
        <Typography maxWidth={650} className="secondary" variant="h4">
          IP-Portion Royalties in the CratD2C Decentralized Autonomous Smart Chain:{' '}
          <Box component="span" color={COLOR_PRIMARY}>
            A New Paradigm for Intellectual Property
          </Box>
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        mt={{ xs: 3, md: 1.25 }}
        mb={{ xs: 4, md: 10 }}
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Typography
          variant="body2"
          color={COLOR_SECONDARY}
          sx={{ mt: { xs: 3, md: 0 }, gridColumn: { xs: 'span 12', md: '7 / 13' } }}
        >
          The CratD2C Decentralized Autonomous Smart Chain introduces a groundbreaking approach to intellectual property
          (IP) rights and royalties through its IP-Portion system. This innovative mechanism is designed to ensure fair
          compensation and continuous incentives for creators and contributors within the ecosystem, fostering a culture
          of innovation and collaboration. Here, we delve into the intricacies of IP-Portion royalties and their impact
          on the blockchain and IP landscapes.
        </Typography>
      </Stack>

      <SplittedRows titleColor={COLOR_PRIMARY} data={rows} spacing={{ xs: 4, md: 11.5 }} rowTitleVariant="h4" />
      <PageParagraph pProps={{ mt: { xs: 0, md: 2 }, mb: { xs: 4, md: 8.5 } }}>{bottomParagraph}</PageParagraph>
    </>
  );
};
