import { Stack, Typography } from '@mui/material';
import { BORDER_RADIUS_S, COLOR_STROKE, formatNumber, Icons } from 'shared';

interface EarningsProps {
  value: string | number;
}

export const Earnings = ({ value }: EarningsProps) => {
  return (
    <Stack alignItems="center" sx={{ padding: 2, borderRadius: BORDER_RADIUS_S, border: `1px solid ${COLOR_STROKE}` }}>
      <Typography variant="body2" className="secondary" mb={1}>
        Earnings
      </Typography>

      <Stack direction="row" alignItems="center" spacing={1}>
        <Icons.Crat />
        <Typography variant="h4" maxWidth={230} noWrap>
          {formatNumber(value, 2, 2)}
        </Typography>
      </Stack>
    </Stack>
  );
};
