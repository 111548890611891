/* eslint-disable @typescript-eslint/no-explicit-any */

import { KeysOfValueType, Normalized } from './types';

type KeyGetter<T extends Record<string | number, any>> =
  | KeysOfValueType<T, string | number>
  | ((entry: T) => string | number);

export const normalizeData = <
  Data extends Record<string, unknown>,
  K extends KeyGetter<Data> = KeyGetter<Data>,
  Result extends Normalized<Data> = Normalized<Data>,
>(
  data: Data[],
  key: K,
) => {
  return data.reduce<Result>((acc, obj) => {
    const isFn = typeof key === 'function';

    if (!isFn && !Object.hasOwn(obj, key)) {
      return acc;
    }

    const keyValue = isFn ? key(obj) : obj[key as keyof Data];

    if (keyValue !== undefined) {
      acc[keyValue as any] = obj;
    }

    return acc;
  }, {} as Result);
};
