import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { appRoutes, useScrollThreshold } from 'shared';

import { STORAGE_KEY } from '../constants';

const TIMEOUT = 1000 * 5;

const getStorageState = () => {
  const lsValue = localStorage.getItem(STORAGE_KEY);
  const ssValue = sessionStorage.getItem(STORAGE_KEY);

  if (ssValue === 'true' || lsValue === 'true') {
    return true;
  }

  return false;
};

export const useModalConditions = () => {
  const [isTimeoutPassed, setIsTimeoutPassed] = useState(false);
  const [conditions, setConditions] = useState(false);
  const { pathname } = useLocation();
  const hasPassedScroll = useScrollThreshold(25);

  const isIndexPage = useMemo(() => pathname === appRoutes.home.root.path, [pathname]);
  const dontShow = useMemo(getStorageState, []);

  useEffect(() => {
    if (dontShow || isTimeoutPassed) {
      return;
    }

    setTimeout(() => setIsTimeoutPassed(true), TIMEOUT);
  }, [dontShow, isTimeoutPassed]);

  useEffect(() => {
    if (dontShow || !isTimeoutPassed || (isIndexPage && !hasPassedScroll)) {
      return;
    }

    setConditions(true);
  }, [dontShow, isTimeoutPassed, isIndexPage, hasPassedScroll]);

  return conditions;
};
