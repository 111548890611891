import { wagmiConfig } from 'app/config/wallet';
import { getContract } from 'viem';

import { ERC_20_ABI } from '../abi';

const address = {
  testnet: '0xE1B2A624147Ceb1154D132DFb3885a621DA165B7',
  mainnet: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
} as const;

export const usdcContract = getContract({
  abi: ERC_20_ABI,
  address: process.env.REACT_APP_NODE_ENV === 'production' ? address.mainnet : address.testnet,
  client: wagmiConfig.getClient(),
});
