import { createAppAsyncThunk, logger, wagmiContracts } from 'shared';

import { actionTypes } from '../action-types';

export const getCrowdsaleData = createAppAsyncThunk(
  actionTypes.GET_CROWDSALE_DATA,
  async (_undefined, { rejectWithValue }) => {
    try {
      const { Crowdsale, Crat } = wagmiContracts.contracts;

      const crowdsaleResponses = await Promise.all([
        Crowdsale.read.totalFundsRaised(),
        Crowdsale.read.currentTokenPrice(),
        Crat.read.balanceOf([Crowdsale.address]),
      ]);

      const [usdTotalRaised, cratPrice, cratAvailableAmount] = crowdsaleResponses.map(String);

      return { cratPrice, usdTotalRaised, cratAvailableAmount };
    } catch (err) {
      logger('crowdsaleData', err);
      return rejectWithValue(err);
    }
  },
);
