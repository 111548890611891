import { giveawayModel } from 'entities/giveaway';
import { createAppAsyncThunk, logger } from 'shared';

import { actionTypes } from '../action-types';
import { giveawayApi } from '../api';

export const getTimestamp = createAppAsyncThunk(
  actionTypes.GET_TIMESTAMP,
  async (any, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { timestamp },
      } = await giveawayApi.getTimer();

      dispatch(
        giveawayModel.actions.updateGiveawayState({
          endTime: timestamp * 1000,
        }),
      );

      return null;
    } catch (err) {
      logger('getTimestamp', err);
      return rejectWithValue(err);
    }
  },
);
