import { Box, Stack, Typography } from '@mui/material';
import { ListItem } from 'shared';

import { list } from './constants';

export const EstateTrading = () => {
  return (
    <Box>
      <Typography variant="h5">
        CratD2C decentralized real estate trading portal is an online platform built on Decentralized Autonomous Smart
        Chain (DASC) technology that enables buyers, sellers, and other stakeholders to interact directly without
        intermediaries, leveraging a series of interconnected modules that streamline property transactions and ensure
        transparency. Here&#39;s an outline of the key components and features of the CratD2C decentralized real estate
        trading portal:
      </Typography>
      <Box
        py={6}
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Stack
          gap={5}
          sx={{
            gridColumn: { xs: 'span 12', md: '4 / 13' },
            transform: { xs: 'translateX(-35px)', md: 'none' },
          }}
        >
          {list.map((item, i) => {
            return <ListItem key={item.title} number={i + 1} title={item.title} description={item.description} />;
          })}
        </Stack>
      </Box>
      <Typography variant="h5" pt={{ md: 4 }}>
        In summary, CratD2C decentralized real estate trading portal leverages blockchain technology and smart contracts
        to create a secure, transparent, and efficient platform for property trading. By eliminating intermediaries and
        empowering users, this structure can revolutionise how we buy, sell, and invest in real estate.
      </Typography>
    </Box>
  );
};
