import type { ComponentProps } from 'react';
import { forwardRef } from 'react';

export const Icon = forwardRef<SVGSVGElement, ComponentProps<'svg'>>((props, ref) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <circle cx="20" cy="20" r="15" fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20zm0-8c6.627 0 12-5.373 12-12S26.627 8 20 8 8 13.373 8 20s5.373 12 12 12z"
        fill="url(#a)"
      />
      <rect x="16" y="16" width="8" height="8" rx="1" fill="#2952EA" />
      <defs>
        <linearGradient id="a" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
          <stop stopColor="#345FF8" />
          <stop offset="1" stopColor="#1D44DC" />
        </linearGradient>
      </defs>
    </svg>
  );
});
