import { Stack } from '@mui/material';
import { steps } from 'pages/smart-chain/smart-chain/ui/roadmap/constants';
import { Roadmap as RoadmapWidget } from 'widgets';

export const Roadmap = () => {
  return (
    <Stack component="section" mt={{ xs: 6, md: 22 }} mb={{ xs: 6, md: 10 }} gap={{ xs: 3, md: 0 }}>
      <RoadmapWidget fixedStepSize={260} fixedLineSize={150} steps={steps} firstPolygonVariant="double" />
    </Stack>
  );
};
