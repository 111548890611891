import { useCallback } from 'react';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { COLOR_GRAY_LIGHT_4, Icons, logger, useDisconnect } from 'shared';

interface Props {
  onClose: () => void;
}

export const LogoutMenu = ({ onClose }: Props) => {
  const { disconnectAsync } = useDisconnect();

  const disconnectWallet = useCallback(async () => {
    try {
      await disconnectAsync();
    } catch (error) {
      logger('disconnect', error);
    }

    onClose();
  }, [disconnectAsync, onClose]);

  return (
    <Stack
      alignItems="center"
      sx={{
        width: '100%',
        position: 'relative',
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          height: 40,
          width: 40,
          borderRadius: '50%',
          border: '2px solid',
          borderColor: '#4A4C89',
          background: 'transparent',
          '&:hover': {
            background: 'transparent',
          },
        }}
      >
        <Icons.Close />
      </IconButton>
      <Stack
        sx={{
          width: 110,
          height: 110,
          borderRadius: '50%',
          backgroundColor: COLOR_GRAY_LIGHT_4,
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Icons.Logout />
      </Stack>
      <Typography pt={4.75} pb={2.5} variant="h5" className="black center" fontWeight={600}>
        Log out
      </Typography>
      <Typography variant="body2" px={1.75} pb={4} className="black center" fontWeight={500}>
        Do you really want to disconnect your wallet and log out ?
      </Typography>
      <Stack gap={2} sx={{ width: '100%' }}>
        <Button onClick={disconnectWallet}>Exit</Button>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: COLOR_GRAY_LIGHT_4,
            '&:hover': {
              backgroundColor: COLOR_GRAY_LIGHT_4,
            },
          }}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
