import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { BORDER_RADIUS_S } from 'shared/config/theme/common';

import { COLOR_ACCENT, COLOR_BG, COLOR_BG_BLACK, COLOR_STROKE, COLOR_WH } from '../colors';
import { FontWeights } from '../Typography';

export const getMuiTabsOverrides = (theme: Theme): ComponentsOverrides['MuiTabs'] => ({
  indicator: {
    display: 'none',
  },
  root: {
    position: 'relative',
    '&.scrollable': {
      paddingBottom: 40,
    },
  },
  scrollButtons: {
    display: 'block',
  },
});

export const getMuiTabOverrides = (theme: Theme): ComponentsOverrides['MuiTab'] => ({
  root: {
    color: COLOR_STROKE,
    textTransform: 'capitalize',
    fontWeight: FontWeights.Bold,
    border: `1px solid ${COLOR_STROKE}`,
    borderRadius: BORDER_RADIUS_S,
    minHeight: 0,
    padding: theme.spacing(1.25, 2),
    backgroundColor: COLOR_BG,
    opacity: 1,

    '&:hover': {
      backgroundColor: 'transparent',
      border: `1px solid ${COLOR_ACCENT}`,
      transition: '400ms',
      color: COLOR_WH,
    },

    '&.Mui-selected': {
      background: COLOR_ACCENT,
      color: COLOR_BG_BLACK,
    },

    '& .MuiTabScrollButton-root': {
      background: COLOR_STROKE,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore no MuiTabScrollButton type at ComponentsOverrides :-(
export const getMuiTabButtonOverrides = (theme: Theme): ComponentsOverrides['MuiTabScrollButton'] => ({
  root: {},
});

export const getMuiTabsDefaultProps = (): ComponentsProps['MuiTabs'] => ({});
