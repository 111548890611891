import { Typography } from '@mui/material';
import { OptionsEnum } from 'features/calculator';
import { Advantages, Intro } from 'shared';
import { CalculatorBlock } from 'widgets';

import { BOTTOM_TEXT, intro, list } from './constants';

export const TurboBacker = () => {
  return (
    <>
      <Intro
        mainTitle="TurboBacker"
        paragraphSubtitle={intro.subtitle}
        paragraph={intro.paragraph}
        paragraphProps={{ letterSpacing: '-0.4px !important' }}
      />
      <Advantages
        list={list}
        listTitle={
          <Typography variant="h4" className="summary">
            Key Advantages of Being a TurboBacker:
          </Typography>
        }
        headTitle="Advantages"
      />
      <CalculatorBlock activeOption={OptionsEnum.TurboBacker} bottom={BOTTOM_TEXT} />
    </>
  );
};
