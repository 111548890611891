/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { Box, Stack, styled, SxProps, Typography } from '@mui/material';
import {
  BORDER_ACCENT,
  BORDER_MAIN_BOLD,
  BORDER_RADIUS_L,
  BORDER_RADIUS_XL,
  COLOR_ACCENT,
  COLOR_SECONDARY,
  COLOR_WH,
} from 'shared/config';
import { FontWeights } from 'shared/config/theme/Typography';
import { useTimeLeft } from 'shared/hooks/useTimeLeft';
import { flexHelper } from 'shared/lib';

const CountdownCell = styled(Box, { shouldForwardProp: (prop) => prop !== 'isFirst' })<{ isFirst?: boolean }>(
  ({ theme, isFirst }) => ({
    ...flexHelper({ alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }),

    border: isFirst ? BORDER_ACCENT : BORDER_MAIN_BOLD,
    maxWidth: 88,
    width: '100%',
    height: 92,
    borderRadius: BORDER_RADIUS_XL,
    '& > p, h6': {
      textAlign: 'center',
    },
    '& >  h6': {
      fontSize: 32,
      fontWeight: FontWeights.Medium,
      color: isFirst ? COLOR_ACCENT : COLOR_WH,
      lineHeight: '110%',
    },
    '& > p': {
      fontSize: 14,
      color: COLOR_SECONDARY,
      lineHeight: '110%',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: BORDER_RADIUS_L,
      width: 69,
      height: 58,
      padding: theme.spacing(0.5),
      '& >  h6': {
        fontSize: 20,
      },
      '& > p': {
        fontSize: '12px',
      },
    },
  }),
);

export interface CountdownProps {
  endTime: number;
  endText?: string;
  sx?: SxProps;
}

export const Countdown: FC<CountdownProps> = ({ endTime, endText, sx }) => {
  const timeLeft = useTimeLeft(endTime);

  if (!timeLeft || (+timeLeft.seconds === 0 && +timeLeft.minutes === 0 && endText)) {
    return (
      <Typography variant="h3" className="center">
        {endText}
      </Typography>
    );
  }

  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={{ xs: 0.75, sm: 4 }}
      width="100%"
      sx={{ ...sx }}
    >
      <CountdownCell isFirst>
        <Typography variant="h6">{days}</Typography>
        <Typography>days</Typography>
      </CountdownCell>
      <CountdownCell>
        <Typography variant="h6">{hours}</Typography>
        <Typography>hours</Typography>
      </CountdownCell>
      <CountdownCell>
        <Typography variant="h6">{minutes}</Typography>
        <Typography>min.</Typography>
      </CountdownCell>
      <CountdownCell>
        <Typography variant="h6">{seconds}</Typography>
        <Typography>sec.</Typography>
      </CountdownCell>
    </Stack>
  );
};
