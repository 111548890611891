import { formatNumber } from 'shared';

import type { Pool, TransformedData, TransformObjectKeys } from '../model';
import { Frequency, Included, valuesToPercentageSet } from '../model';

export function transformDataForUI(data: Pool[]): TransformedData[] {
  const transformedData: TransformedData[] = [];

  const keys = Object.keys(data[0]) as TransformObjectKeys[];

  const getValuesByKey = (key: TransformObjectKeys) => {
    return data.map((item) => {
      return item[key];
    });
  };

  const dfsTransform = (newData: Pool[], index: number) => {
    if (index >= keys.length) {
      return;
    }

    transformedData.push({ title: keys[index], values: getValuesByKey(keys[index]) });

    dfsTransform(newData, index + 1);
  };

  dfsTransform(data, 0);

  return transformedData;
}

export const formatUIValue = (title: string, value: Included | Frequency | number, tableFormat = false) => {
  if (valuesToPercentageSet.has(title.toUpperCase())) {
    return `${value}%`;
  }

  if (value === 'NO' && !tableFormat) {
    return '-';
  }

  return typeof value === 'number' ? formatNumber(value) : value;
};

export const increaseValueByPercent = (value: number | string, percent: number) => {
  const increaseFraction = percent / 100;

  return +value * (1 + increaseFraction);
};

const compoundingMultipliers: Record<Frequency, number> = {
  Biweekly: 24,
  Monthly: 12,
  Weekly: 52,
};

export const calculateAPY = (apr: number, frequency: Frequency) => {
  const aprToDec = apr / 100;
  const multiplier = compoundingMultipliers[frequency];
  const apy = (1 + aprToDec / multiplier) ** multiplier - 1;

  return +(apy * 100).toFixed(4);
};
