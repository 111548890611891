export const privacyPolicyItems = [
  {
    title: 'Introduction',
    description:
      'Welcome to CratD2C Decentralized Autonomous SmartChain, accessible from https://cratd2csmartchain.io. This Privacy Policy describes our policies and procedures for collecting, using, disclosing, and safeguarding your personal information when you use our platform. As a decentralized autonomous organization (DAO) on the blockchain, we prioritize transparency and your privacy. Please review this policy carefully.',
  },
  {
    mainTitle: 'Information We Collect',
    title: 'Direct Interactions:',
    description:
      'When you interact directly with our web3 portal, such as when you register for any of our services or perform transactions, we may require you to provide us with certain personally identifiable information, including but not limited to your public blockchain address, email address, and username.',
  },
  {
    title: 'Blockchain Transactions:',
    description:
      'Transactions on the blockchain are public and can be viewed by anyone. When you execute transactions with CratD2C, your blockchain address and transaction details are visible publicly on the blockchain and are immutable.',
  },
  {
    title: 'Use of Your Information',
    subitemsTitle: 'We use the collected data for various purposes:',
    subitems: [
      {
        subDescription: 'To maintain and operate our website and services.',
      },
      {
        subDescription: 'To manage your registration as a user of the service.',
      },
      {
        subDescription: 'To perform contracts between you and us.',
      },
      {
        subDescription: 'To provide customer support.',
      },
      {
        subDescription: 'To gather analysis or valuable information so that we can improve our service.',
      },
      {
        subDescription: 'To monitor the usage of our service.',
      },
      {
        subDescription: 'To detect, prevent, and address technical issues.',
      },
    ],
  },
  {
    mainTitle: 'Disclosure of Your Information',
    title: 'Blockchain:',
    description:
      'As a Web3 platform, transactions are inherently public and involve automatically disclosing certain information on the blockchain.',
  },
  {
    title: 'Service Providers:',
    description:
      'We may employ third-party companies and individuals to facilitate our service ("Service Providers"), provide the service on our behalf, perform Service-related services, or assist us in analyzing how our service is used. These third parties have access to your personal data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.',
  },
  {
    title: 'Security of Your Information:',
    description:
      'While no method of transmission over the Internet or method of electronic storage is 100% secure, we strive to use commercially acceptable means to protect your personal information.',
  },
  {
    title: 'Policy for Children',
    description:
      'Our service does not address anyone under the age of 18 ("Children"), and we do not knowingly collect personally identifiable information from anyone under 18. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us.',
  },
  {
    title: 'Your Data Protection Rights',
    description:
      'Depending on your location, data protection laws may give you rights with respect to your personal information, including the right to request access, correction, or deletion of your personal data.',
  },
  {
    title: 'Changes to This Privacy Policy',
    description:
      'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.',
  },
  {
    title: 'Contact Us',
    description: 'If you have any questions about this Privacy Policy, please email us at support@cratd2csmartchain.io',
  },
];
