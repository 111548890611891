export const aboutData = [
  {
    title: 'Native Coin',
    value: 'CRAT',
  },
  {
    title: 'Max supply',
    value: '300,000,000',
  },
  {
    title: 'Latency',
    value: '0.5 - 3 sec.',
  },
  {
    title: 'TPS',
    value: 'upto 100k/s',
  },
];
