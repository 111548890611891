import { Box, Card, Grid, Typography } from '@mui/material';
import { BORDER_RADIUS_S, LinearProgressWithLabel } from 'shared';

interface ProgressItemProps {
  title: string;
  value: number;
  label: string;
  subtitle: string;
  percentage: number | string;
}

export const ProgressItem = ({ value, title, subtitle, label, percentage }: ProgressItemProps) => {
  return (
    <Box>
      <Typography variant="button" component="h5" mb={2}>
        {title}
      </Typography>
      <Grid container columnSpacing={1.5} rowSpacing={1.25}>
        <Grid item xs={9} sm={11}>
          <LinearProgressWithLabel
            value={value}
            label={
              <Typography
                variant="h6"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '50%',
                  transform: 'translate(50%, -50%)',
                }}
              >
                {label}
              </Typography>
            }
            wrapperSx={{ mr: 0 }}
            sx={{
              borderRadius: BORDER_RADIUS_S,
              height: 31,
            }}
          />
        </Grid>
        <Grid item xs={3} sm={1}>
          <Card sx={{ padding: 0.5, borderRadius: BORDER_RADIUS_S }}>
            <Typography variant="h6" textAlign="center" className="accent">{`${percentage}%`}</Typography>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" className="secondary">
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
