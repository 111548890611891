import { useEffect } from 'react';
import { Box, Button, Divider, styled, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useWagmiAuth } from 'app/providers';
import { giveawayModel } from 'entities/giveaway';
import {
  BackgroundGiveaway,
  BORDER_MAIN_BOLD,
  BORDER_RADIUS_XL,
  COLOR_ACCENT,
  COLOR_BG_ALPHA_74,
  COLOR_SECONDARY,
  Countdown,
  useAppDispatch,
  useShallowSelector,
} from 'shared';
import { FontWeights } from 'shared/config/theme/Typography';
import { useTimeLeft } from 'shared/hooks/useTimeLeft';

const Wrapper = styled(Stack)(({ theme }) => ({
  borderRadius: BORDER_RADIUS_XL,
  border: BORDER_MAIN_BOLD,
  background: COLOR_BG_ALPHA_74,
  padding: theme.spacing(3),
  width: '100%',
  maxWidth: 517,
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));

const Subtitle = styled(Typography)({
  color: COLOR_SECONDARY,
});
const ValueText = styled(Typography)({
  color: COLOR_ACCENT,
  fontWeight: FontWeights.Medium,
  fontSize: 20,
  letterSpacing: '-0.4px',
});

const Row = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const GiveawayOverview = () => {
  const dispatch = useAppDispatch();
  const { state: authState } = useWagmiAuth();
  const { winningOdds, points, endTime } = useShallowSelector(giveawayModel.selectors.getGiveaway);
  const timeLeft = useTimeLeft(endTime);

  useEffect(() => {
    dispatch(giveawayModel.thunks.getTimestamp());
    if (authState.address) dispatch(giveawayModel.thunks.getUserLeaderboardStats());
  }, [dispatch, authState.address]);

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 2, md: 5 }}>
      <Box maxWidth={736}>
        <Typography variant="h1">
          WIN{' '}
          <Typography variant="h1" component="span" className="accent">
            $200K CratD2C{' '}
          </Typography>
          Pre-ICO Jumbo Giveaway!
        </Typography>
        <Typography variant="h5" maxWidth={482} mt={{ xs: 1.5, md: 6 }}>
          Be one of the 10 lucky winners to share in the $200K Pre-ICO Package Giveaway.
        </Typography>
      </Box>
      <Stack gap={2}>
        <Wrapper>
          <Typography variant="h5">{timeLeft?.days || 0} DAYS LEFT</Typography>
          <Countdown endTime={endTime} />
          <Typography className="center" fontSize={{ xs: 14, sm: 15 }}>
            Each Lucky Winner will take home $20,000 Pre-ICO Package, comprising 113,333 CRAT Native Coins,
            <br />
            Valued at $90,666.40 upon listing.
          </Typography>
          <Button
            size="small"
            LinkComponent="a"
            href="/#crowdsale"
            sx={{ maxWidth: { xs: 296, md: 315 }, whiteSpace: 'nowrap' }}
          >
            Buy CRAT to ACTIVATE Leaderboard
          </Button>
        </Wrapper>
        {!!authState.address && (
          <Wrapper>
            <Row>
              <Subtitle>Expected Winning Reward</Subtitle>
              <Stack alignItems={{ xs: 'start', sm: 'center' }}>
                <ValueText>113,333 native coins</ValueText>
                <Typography variant="subtitle2" className="secondary" mt={0.75}>
                  valued at $90,666.40 upon listing
                </Typography>
              </Stack>
            </Row>
            <Divider flexItem />
            <Row>
              <Subtitle>Winning Odds</Subtitle>
              <ValueText>{winningOdds ? winningOdds.toString().padStart(2, '0') : '-'}</ValueText>
            </Row>
            <Divider flexItem />
            <Row>
              <Subtitle>Current points</Subtitle>
              <ValueText>{points}</ValueText>
            </Row>
          </Wrapper>
        )}
      </Stack>
      <Box
        component="img"
        src={BackgroundGiveaway}
        sx={{
          objectFit: 'cover',
          position: 'absolute',
          zIndex: -1,
          top: 0,
          height: { xs: 1347, sm: 1365 },
          left: { xs: -40, sm: 0 },
          width: { xs: 'calc(100vw + 40px)', sm: '100vw' },
          overflow: 'visible',
        }}
      />
    </Stack>
  );
};
