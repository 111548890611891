import { Link } from '@mui/material';

export const termsAndConditions = [
  {
    title: 'Introduction',
    description:
      'Welcome to the CratD2C ICO. This document outlines the Terms and Conditions (T&C) governing your participation in our Pre-ICO and ICO. By participating in the CratD2C Pre-ICO and ICO, you agree to be bound by these terms.',
  },
  {
    title: 'Coin Swap',
    description:
      'Participants in the Pre-ICO will have the opportunity to swap their CRAT Tokens for CRAT Native Coins at a 3:1 ratio when our Blockchain Mainnet launches. Those who join during the ICO will be able to swap their CratD2C-Pre Tokens for CRAT Native Coins on a 1:1 ratio upon the launch of our Blockchain Mainnet.',
  },
  {
    title: 'Cliff Period',
    description:
      'The Cliff Period for CratD2C Decentralized Autonomous Smart Chain begins on the first day of CRAT Coin’s listing on a centralized exchange and lasts three months. Pre-ICO and ICO Rounds participants cannot trade their acquired coins during this period. This is to ensure market stability and a fair distribution of native coins.',
  },
  {
    title: 'Vesting Period',
    description:
      "Coins purchased in the pre-ICO and ICO phases are fully allocated to you immediately after completing the transaction. However, the conversion of these coins into the blockchain's native cryptocurrency, CRAT Coins, will be conducted at a rate of 25% per quarter following the completion of the specified cliff period.",
  },
  {
    title: 'Acknowledgment',
    description:
      'By participating in the CratD2C Pre-ICO and ICO, you acknowledge and agree to these terms and conditions. You also affirm that you are not a resident or citizen of any ICO-restricted territories.',
  },
  {
    title: 'Disclaimer',
    description:
      "Participants are responsible for adhering to their jurisdictions' Initial Coin Offerings (ICO) regulations. It is incumbent upon each participant to ensure compliance with local laws and regulations related to ICO participation.\n\nFurthermore, CratD2C retains the authority to review these terms at any given moment. It is the duty of each participant to stay informed of any modifications to these terms, as ongoing participation in the ICO implies acceptance of such changes.",
  },
  {
    title: 'General Information',
    description: (
      <>
        The content available on{' '}
        <Link
          href="https://cratd2csmartchain.io"
          referrerPolicy="no-referrer"
          rel="noreferrer"
          target="_blank"
          className="accent medium"
          underline="hover"
        >
          https://cratd2csmartchain.io
        </Link>{' '}
        is intended to provide an overview of the CratD2C Pre-Development Stage and presale. All details regarding
        CratD2C, including project goals, timelines, and token sales, are subject to revision as the project evolves.
        This information should not be considered financial, legal, or investment advice. Participants are encouraged to
        conduct their own due diligence before making any commitments.
      </>
    ),
  },
  {
    title: 'Participation Risk',
    description:
      'By participating in the CratD2C Pre-Development Stage, you acknowledge the inherent risks associated with cryptocurrency and blockchain projects. These risks include but are not limited to, high volatility in the value of CratD2C tokens, market uncertainty, regulatory shifts, and the possibility that the project may not meet its goals. CratD2C cannot and does not guarantee any financial returns, outcomes, or successful project development.',
  },
  {
    title: 'No Guarantee of Success',
    description:
      "CratD2C is in the pre-development phase, and the project's success depends on various factors, including market conditions, presale contributions, and technical development. CratD2C does not provide any assurances or warranties regarding the project's future success or financial viability. Participation is entirely at your own risk.",
  },
  {
    title: 'Limitation of Liability',
    description:
      'CratD2C, its affiliates, officers, and partners will not be held responsible for any losses, damages, or liabilities arising from your participation in the CratD2C presale or use of the platform. This includes, but is not limited to, financial loss due to token price fluctuations or the project’s potential failure to launch. You agree to hold CratD2C and its team harmless from any claims or damages related to your participation.',
  },
  {
    title: 'Participant Responsibilities',
    description:
      'By participating, you agree to provide accurate and up-to-date information required for your involvement in the presale. You are solely responsible for securing your assets, wallets, and private keys. CratD2C is not liable for any losses resulting from user negligence or third-party breaches.',
  },
  {
    title: 'Whitelisting and Benefits',
    description:
      'Participants who join the CratD2C Pre-Development Stage under a special offer will receive exclusive whitelisting, allowing them to bypass the 3-month cliff period post-Initial Exchange Offering (IEO). This means participants will have immediate access to their tokens once listed on exchanges to set up a validator node. CratD2C retains the right to modify this benefit if necessary.',
  },
  {
    title: 'Terms Modifications',
    description:
      'CratD2C reserves the right to revise, update, or amend these Terms and Conditions at any time. Any substantial changes will be communicated through official channels, and continued engagement with the CratD2C platform or participation in the presale will constitute acceptance of these updated terms. It is the participant’s responsibility to stay informed of any such changes.',
  },
  {
    title: 'Contact Information',
    description: (
      <>
        If participants have any inquiries or need further clarification regarding the CratD2C Pre-Development Stage,
        they can contact the support team at{' '}
        <Link
          href="mailto:support@cratd2csmartchain.io"
          rel="noopener noreferrer"
          target="_blank"
          className="accent medium"
          underline="hover"
        >
          support@cratd2csmartchain.io
        </Link>
        .
      </>
    ),
  },
];
