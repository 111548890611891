import { Grid, Stack, Typography } from '@mui/material';
import { list } from 'pages/tokenomics/earn-with-crat/constants';
import { Br, COLOR_ACCENT, ListItem, Section } from 'shared';

export const Benefits = () => {
  return (
    <Section>
      <Grid container rowSpacing={{ xs: 3, md: 0 }}>
        <Grid item xs={12} md={4}>
          <Typography variant="h4" className="secondary">
            Benefits
          </Typography>
        </Grid>

        <Grid item container xs={12} md={8} rowSpacing={4.75}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Here&#39;s a simplified explanation of the benefits for CratD2C{' '}
              <Br display={{ xs: 'none', md: 'inline' }} /> Coin Holders:
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Stack gap={4.5}>
              {list.map((item, i) => {
                return (
                  <ListItem
                    key={item.title}
                    number={i + 1}
                    title={item.title}
                    description={item.description}
                    spacing={{ xs: 2 }}
                    childrenOpts={{
                      titleGridProps: { xs: 12, md: 3, lg: 2.9, sx: { h6: { color: COLOR_ACCENT } } },
                      descriptionGridProps: { xs: 12, md: 9 },
                    }}
                  />
                );
              })}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
};
