import { Box, Typography } from '@mui/material';
import { COLOR_GRAY_5 } from 'shared';

export const VendingMachine = () => {
  return (
    <Box>
      <Typography variant="h5" letterSpacing="-0.021em">
        CratD2C Automated Vending Machine is designed to provide a user-friendly point-of-sale purchase system for daily
        consumer needs using CratD2C Smart Chain Applications and CRAT Coin as means of payment. eCommerce merchants
        worldwide can now have enormous options with various products: snacks, beverages, pizzas, cupcakes, newspapers,
        tickets, etc.
      </Typography>
      <Box
        sx={{
          mt: 4.5,
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Box
          sx={{
            gridColumn: { xs: 'span 12', md: '4 / 13' },
          }}
        >
          <Typography>
            <Typography variant="body2" color={COLOR_GRAY_5} className="font-secondary">
              CratD2C Vending Machine dispenses a product to the users with payment in equivalent in CRAT Coin and
              product selection.
            </Typography>
            <br />
            <Typography variant="body2" color={COLOR_GRAY_5} className="font-secondary">
              CratD2C Vending Machines works 24x7 at any standalone unit store worldwide and can be franchised by
              contract within any city in the world. Its primary function is to issue users with a diverse range of
              products flawlessly at any time.
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
