import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Cardano: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 37 37"
    sx={{
      width: '36px',
      height: '36px',
      ...sx,
    }}
    {...props}
  >
    <circle cx="18.0493" cy="18.0493" r="18.0493" fill="black" />
    <path
      d="M11.19 22.325C11.3151 22.1999 11.487 22.127 11.6694 22.127H28.2109C28.5131 22.127 28.6643 22.4918 28.4506 22.7054L25.183 25.9731C25.0579 26.0982 24.8859 26.1711 24.7035 26.1711H8.16207C7.8598 26.1711 7.70866 25.8063 7.92234 25.5926L11.19 22.325Z"
      fill="url(#paint0_linear_2900_21735)"
    />
    <path
      d="M11.19 10.1238C11.3203 9.99874 11.4922 9.92578 11.6694 9.92578H28.2109C28.5131 9.92578 28.6643 10.2906 28.4506 10.5043L25.183 13.7719C25.0579 13.897 24.8859 13.9699 24.7035 13.9699H8.16207C7.8598 13.9699 7.70866 13.6051 7.92234 13.3915L11.19 10.1238Z"
      fill="url(#paint1_linear_2900_21735)"
    />
    <path
      d="M25.183 16.1844C25.0579 16.0593 24.8859 15.9863 24.7035 15.9863H8.16207C7.8598 15.9863 7.70866 16.3511 7.92234 16.5648L11.19 19.8324C11.3151 19.9575 11.487 20.0305 11.6694 20.0305H28.2109C28.5131 20.0305 28.6643 19.6657 28.4506 19.452L25.183 16.1844Z"
      fill="url(#paint2_linear_2900_21735)"
    />
    <circle cx="18" cy="18" r="18" fill="#EDF0F4" />
    <path
      d="M12.5789 17.9085C12.5277 18.798 13.2124 19.5596 14.102 19.6108C14.134 19.6108 14.166 19.6108 14.198 19.6108C15.094 19.6108 15.8171 18.8876 15.8107 17.9917C15.8107 17.0957 15.0876 16.3725 14.1916 16.3789C13.334 16.3789 12.6237 17.0509 12.5789 17.9085Z"
      fill="#0033AD"
    />
    <path
      d="M6.55091 17.4933C6.26293 17.4741 6.01334 17.6981 6.00054 17.9861C5.98774 18.2741 6.20533 18.5237 6.49331 18.5365C6.7813 18.5557 7.02449 18.3317 7.04369 18.0437C7.06289 17.7557 6.8389 17.5125 6.55091 17.4933Z"
      fill="#0033AD"
    />
    <path
      d="M12.4778 8.52657C12.7338 8.39858 12.8362 8.07859 12.7082 7.8226C12.5802 7.56661 12.2602 7.46422 12.0042 7.59221C11.7483 7.72021 11.6459 8.03379 11.7739 8.28978C11.9018 8.55217 12.2154 8.66096 12.4778 8.52657C12.4778 8.52657 12.4714 8.52657 12.4778 8.52657Z"
      fill="#0033AD"
    />
    <path
      d="M14.114 11.3884C14.5107 11.19 14.6707 10.7036 14.4723 10.3068C14.274 9.91003 13.7876 9.75004 13.3908 9.94843C12.994 10.1468 12.834 10.6332 13.0324 11.03C13.2308 11.4268 13.7172 11.5868 14.114 11.3884Z"
      fill="#0033AD"
    />
    <path
      d="M8.59723 13.3519C8.90442 13.5503 9.314 13.4671 9.51879 13.1599C9.71718 12.8527 9.63399 12.4431 9.3268 12.2384C9.01962 12.04 8.61003 12.1232 8.40524 12.4303C8.20045 12.7375 8.29005 13.1535 8.59723 13.3519Z"
      fill="#0033AD"
    />
    <path
      d="M9.57713 17.2045C9.12915 17.1789 8.75157 17.5181 8.72597 17.966C8.70037 18.414 9.03955 18.7916 9.48753 18.8172C9.93551 18.8428 10.3131 18.5036 10.3387 18.0556C10.3643 17.6141 10.0251 17.2301 9.57713 17.2045Z"
      fill="#0033AD"
    />
    <path
      d="M8.69014 22.6262C8.36375 22.7926 8.22936 23.1894 8.39575 23.5222C8.56214 23.8486 8.95892 23.983 9.29171 23.8166C9.61809 23.6502 9.75249 23.2534 9.5861 22.9206C9.4197 22.5942 9.01652 22.4598 8.69014 22.6262Z"
      fill="#0033AD"
    />
    <path
      d="M11.8238 15.5341C12.2654 15.8221 12.8541 15.7005 13.1357 15.259C13.4237 14.8174 13.3021 14.2286 12.8605 13.947C12.4189 13.659 11.8302 13.7806 11.5486 14.2222C11.2606 14.6574 11.3822 15.2462 11.8238 15.5341Z"
      fill="#0033AD"
    />
    <path
      d="M21.775 11.324C22.1462 11.5672 22.6517 11.4648 22.8949 11.0937C23.1381 10.7225 23.0357 10.2169 22.6645 9.9737C22.2933 9.73052 21.7878 9.83291 21.5446 10.2041C21.295 10.5817 21.3974 11.0809 21.775 11.324Z"
      fill="#0033AD"
    />
    <path
      d="M23.4265 8.47666C23.6697 8.63665 23.9897 8.56625 24.1496 8.32306C24.3096 8.07987 24.2392 7.75989 23.9961 7.5999C23.7529 7.4399 23.4329 7.5103 23.2729 7.74709C23.1193 7.99028 23.1897 8.31666 23.4265 8.47666Z"
      fill="#0033AD"
    />
    <path
      d="M21.8964 16.366C21.0069 16.3148 20.2389 16.9995 20.1941 17.8891C20.1429 18.7787 20.8277 19.5466 21.7172 19.5914C21.7492 19.5914 21.7812 19.5914 21.8068 19.5914C22.6964 19.5914 23.4196 18.8683 23.4196 17.9723C23.426 17.1211 22.754 16.4108 21.8964 16.366Z"
      fill="#0033AD"
    />
    <path
      d="M14.6469 15.4185C14.9221 15.9625 15.4789 16.3081 16.0933 16.3081C16.9828 16.3081 17.706 15.5849 17.706 14.689C17.706 14.4394 17.6484 14.1898 17.5332 13.9594C17.13 13.1594 16.1573 12.8394 15.3637 13.2426C14.5637 13.6522 14.2437 14.625 14.6469 15.4185Z"
      fill="#0033AD"
    />
    <path
      d="M27.3109 13.3525C27.6373 13.1861 27.7653 12.7829 27.5989 12.4566C27.4325 12.1302 27.0293 12.0022 26.703 12.1686C26.3766 12.335 26.2486 12.7317 26.4086 13.0581C26.5814 13.3845 26.9781 13.5189 27.3109 13.3525Z"
      fill="#0033AD"
    />
    <path
      d="M23.2154 13.8644C22.7482 14.1012 22.5562 14.6708 22.793 15.1379C23.0298 15.6051 23.5993 15.7971 24.0665 15.5603C24.5337 15.3235 24.7257 14.754 24.4889 14.2868C24.2521 13.8196 23.6825 13.634 23.2154 13.8644Z"
      fill="#0033AD"
    />
    <path
      d="M17.9367 8.23248C18.3015 8.25168 18.6151 7.97009 18.6407 7.60531C18.6663 7.24052 18.3783 6.92694 18.0135 6.90134C17.6487 6.88214 17.3351 7.15733 17.3095 7.52211C17.2903 7.89329 17.5719 8.20688 17.9367 8.23248Z"
      fill="#0033AD"
    />
    <path
      d="M17.9303 12.4117C18.4551 12.4437 18.9031 12.0405 18.9287 11.5158C18.9607 10.991 18.5575 10.543 18.0327 10.5174C17.508 10.4854 17.06 10.8886 17.0344 11.4134C17.0088 11.9381 17.4056 12.3861 17.9303 12.4117Z"
      fill="#0033AD"
    />
    <path
      d="M12.7833 22.1139C13.2505 21.8771 13.4425 21.3075 13.2057 20.8404C12.9689 20.3732 12.3993 20.1812 11.9322 20.418C11.465 20.6548 11.273 21.2243 11.5098 21.6915C11.7466 22.1587 12.3161 22.3507 12.7833 22.1139Z"
      fill="#0033AD"
    />
    <path
      d="M18.5448 13.7999C18.0584 14.5486 18.2632 15.547 19.0119 16.0334C19.7607 16.5198 20.7591 16.315 21.2454 15.5662C21.7318 14.8174 21.527 13.8191 20.7783 13.3327C20.5159 13.1599 20.2087 13.0703 19.8951 13.0703C19.3511 13.0703 18.8455 13.3455 18.5448 13.7999Z"
      fill="#0033AD"
    />
    <path
      d="M21.3535 20.559C20.9503 19.759 19.9776 19.4391 19.184 19.8422C18.384 20.2454 18.0641 21.2182 18.4672 22.0117C18.8704 22.8117 19.8432 23.1317 20.6367 22.7285C21.4303 22.3317 21.7567 21.3718 21.3599 20.5718C21.3599 20.5654 21.3599 20.5654 21.3535 20.559Z"
      fill="#0033AD"
    />
    <path
      d="M24.175 20.4431C23.7334 20.1551 23.1446 20.2767 22.863 20.7183C22.575 21.1599 22.6966 21.7487 23.1382 22.0302C23.5798 22.3182 24.1686 22.1966 24.4502 21.7551C24.7382 21.3199 24.6166 20.7311 24.175 20.4431Z"
      fill="#0033AD"
    />
    <path
      d="M27.2742 18.0185C27.2998 17.5706 26.9607 17.193 26.5127 17.1674C26.0647 17.1418 25.6871 17.481 25.6615 17.9289C25.6359 18.3769 25.9751 18.7545 26.4231 18.7801C26.8647 18.8057 27.2486 18.4601 27.2742 18.0185Z"
      fill="#0033AD"
    />
    <path
      d="M29.5079 17.4406C29.22 17.4214 28.9704 17.6454 28.9576 17.9334C28.9448 18.2214 29.1624 18.471 29.4503 18.4838C29.7383 18.503 29.9815 18.279 30.0007 17.991C30.0135 17.703 29.7959 17.4598 29.5079 17.4406Z"
      fill="#0033AD"
    />
    <path
      d="M27.4001 22.6251C27.093 22.4267 26.6834 22.5099 26.4786 22.8171C26.2802 23.1243 26.3634 23.5338 26.6706 23.7386C26.9778 23.937 27.3873 23.8538 27.5921 23.5466C27.7969 23.2394 27.7073 22.8235 27.4001 22.6251Z"
      fill="#0033AD"
    />
    <path
      d="M12.5653 27.5022C12.3221 27.3422 12.0021 27.4126 11.8421 27.6494C11.6821 27.8926 11.7525 28.2126 11.9893 28.3726C12.2325 28.5326 12.5525 28.4622 12.7124 28.2254C12.8788 27.9886 12.8084 27.6622 12.5653 27.5022Z"
      fill="#0033AD"
    />
    <path
      d="M23.5238 27.4503C23.2678 27.5783 23.1654 27.8983 23.2934 28.1543C23.4214 28.4103 23.7414 28.5127 23.9974 28.3847C24.2533 28.2567 24.3557 27.9431 24.2277 27.6871C24.0997 27.4247 23.7862 27.3159 23.5238 27.4503C23.5302 27.4503 23.5238 27.4503 23.5238 27.4503Z"
      fill="#0033AD"
    />
    <path
      d="M17.4558 22.1772C17.9422 21.4285 17.7374 20.4301 16.9886 19.9373C16.2399 19.451 15.2415 19.6557 14.7487 20.4045C14.256 21.1533 14.4672 22.1516 15.2159 22.6444C15.4783 22.8172 15.7855 22.9068 16.0991 22.9068C16.6495 22.9132 17.155 22.638 17.4558 22.1772Z"
      fill="#0033AD"
    />
    <path
      d="M14.2245 24.6534C13.8533 24.4102 13.3478 24.5126 13.1046 24.8838C12.8614 25.255 12.9638 25.7605 13.335 26.0037C13.7061 26.2469 14.2117 26.1445 14.4549 25.7733C14.7045 25.4022 14.6021 24.8966 14.2245 24.6534Z"
      fill="#0033AD"
    />
    <path
      d="M17.9946 27.7451C17.6298 27.7259 17.3162 28.0075 17.2906 28.3722C17.265 28.737 17.553 29.0506 17.9178 29.0762C18.2826 29.0954 18.5962 28.8202 18.6218 28.4554C18.641 28.0843 18.3594 27.7707 17.9946 27.7451Z"
      fill="#0033AD"
    />
    <path
      d="M18.0001 23.5662C17.4754 23.5342 17.0274 23.9374 17.0018 24.4622C16.9698 24.987 17.373 25.435 17.8977 25.4605C18.4225 25.4861 18.8705 25.0894 18.8961 24.5646C18.9281 24.0462 18.5249 23.5918 18.0001 23.5662Z"
      fill="#0033AD"
    />
    <path
      d="M21.8846 24.5899C21.4879 24.7947 21.3279 25.2811 21.5327 25.6779C21.7374 26.0746 22.2238 26.2346 22.6206 26.0298C23.0174 25.8315 23.1774 25.3451 22.979 24.9483C22.7742 24.5515 22.2878 24.3851 21.8846 24.5899Z"
      fill="#0033AD"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2900_21735"
        x1="-29.5643"
        y1="6.85796"
        x2="-30.0044"
        y2="29.0042"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2900_21735"
        x1="-34.57"
        y1="4.24363"
        x2="-35.0101"
        y2="26.3899"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2900_21735"
        x1="-32.0831"
        y1="5.54152"
        x2="-32.5232"
        y2="27.6878"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
