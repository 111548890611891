export const BOTTOM_TEXT =
  "In conclusion, the Community Trust Vesting Grant (CTVG) of CratD2C Decentralized Autonomous Smart Chain serves as a beacon of support for promising blockchain startups. By offering financial resources, technical infrastructure, and a collaborative ecosystem, the CTVG empowers startups to accelerate their development journey, showcase their innovations, and contribute to the broader advancement of blockchain technology. This comprehensive program underlines CratD2C's commitment to fostering a dynamic, inclusive, and impactful blockchain ecosystem.";

export const intro = {
  paragraph:
    'The Community Trust Vesting Grant (CTVG) within the CratD2C Decentralized Autonomous Smart Chain (DASC) embodies a strategic initiative aimed at nurturing and accelerating the growth of promising blockchain startups.',
  subtitle:
    "This grant program serves as a powerful catalyst for these startups by providing them with substantial support in the form of grants to develop and build their architecture on the CratD2C Smart Chain. Let's delve into an extensive explanation of the CTVG and the remarkable advantages it offers:",
};

export const list = [
  {
    title: 'Fostering Innovation and Collaboration',
    description: `The CTVG is a manifestation of CratD2C's commitment to innovation and collaboration within the broader blockchain ecosystem. By actively supporting startups in building on the CratD2C Smart Chain, the platform fosters an environment of innovation, where different projects can interact, learn from one another, and collectively contribute to the growth of the blockchain industry.`,
  },
  {
    title: 'Providing Financial Support',
    description:
      'Startups often face financial challenges in their early stages, hindering their ability to fully realize their potential. The CTVG addresses this challenge by providing grants to selected blockchain startups. This financial support empowers startups to focus on their core objectives without the immediate pressure of fundraising, ensuring they have the necessary resources to bring their ideas to life.',
  },
  {
    title: 'Building on a Strong Foundation',
    description:
      'By building their architecture on the CratD2C Smart Chain, startups benefit from a solid foundation of cutting-edge technology and infrastructure. The CratD2C DASC offers features such as Layer-1 blockchain infrastructure, DPoS consensus, enhanced security, rapid finality, scalability, and more. This foundation accelerates the development process and provides startups with the tools they need to create robust and secure blockchain solutions.',
  },
  {
    title: 'Access to a Vibrant Ecosystem',
    description:
      'Startups integrated into the CratD2C ecosystem gain access to a vibrant community of like-minded individuals, developers, validators, delegators, and backers. This community provides networking opportunities, collaboration avenues, and a platform for sharing insights and experiences. It creates an environment conducive to learning, growth, and cross-pollination of ideas.',
  },
  {
    title: 'Accelerated Development and Deployment',
    description:
      'One of the key advantages of the CTVG is that it expedites the development and deployment process for startups. By leveraging the infrastructure and tools provided by the CratD2C Smart Chain, startups can save time on building foundational components and instead focus on creating unique, value-added features for their blockchain applications.',
  },
  {
    title: 'Showcasing Real-World Use Cases',
    description:
      'As startups build their architecture on the CratD2C Smart Chain, they have the opportunity to showcase real-world use cases of blockchain technology. These use cases demonstrate the practical applicability of blockchain solutions across various industries, driving broader awareness and adoption of blockchain technology.',
  },
  {
    title: 'Cross-Promotion and Exposure',
    description:
      'Through the CTVG, startups receive exposure to the existing CratD2C community and ecosystem. This cross-promotion helps startups gain visibility and credibility within a well-established network, which can be instrumental in attracting users, investors, and partners.',
  },
  {
    title: 'Advancing Blockchain Usability',
    description:
      "The CTVG contributes to the advancement of blockchain usability by enabling startups to develop applications and solutions that address real-world challenges. As more startups build on the CratD2C Smart Chain, the ecosystem's diversity and innovation drive the expansion of blockchain use cases beyond traditional finance, ushering in a new era of blockchain adoption.",
  },
];
