import { Stack, Typography } from '@mui/material';
import { COLOR_RED, FontWeights, Icons } from 'shared';

const iconSx = { width: 40, height: 40, color: COLOR_RED };

export const ErrorTab = () => {
  return (
    <Stack alignItems="center" spacing={2.25} pb={6}>
      <Stack padding={4} border={`2px solid ${COLOR_RED}`} borderRadius="50%">
        <Icons.Close sx={iconSx} />
      </Stack>
      <Typography variant="h4" className="large no-resize" fontWeight={FontWeights.SemiBold}>
        Not Successfull
      </Typography>
    </Stack>
  );
};
