export const list = [
  {
    id: '1',
    text: `Blockchain Platform: CratD2C decentralized e-commerce trading portal is built on an autonomous Smart Chain, which provides the underlying infrastructure for the decentralized marketplace.`,
  },
  {
    id: '2',
    text: `Smart Contracts: The platform utilizes smart contracts to automate and enforce the terms and conditions of transactions between buyers and sellers. Smart contracts handle tasks such as product listing, order management, payment processing, and dispute resolution, ensuring a secure and trustless environment for trade.`,
  },
  {
    id: '3',
    text: `User Interface (UI): The portal features an intuitive and user-friendly interface for both buyers and sellers, allowing them to browse products easily, create listings, place orders, and manage their accounts.`,
  },
  {
    id: '4',
    text: `Product Listings: Sellers can list their products or services on the platform, providing essential information such as descriptions, images, pricing, shipping details, and return policies. These listings are stored on the blockchain, ensuring transparency and immutability.`,
  },
  {
    id: '5',
    text: `Decentralized Identity and Reputation: The platform incorporates decentralized identity solutions, enabling users to create and manage their digital identities without relying on a centralized authority. Additionally, a decentralized reputation system can track user behaviour, ratings, and reviews, allowing buyers and sellers to establish trust and credibility within the community.`,
  },
  {
    id: '6',
    text: `Cryptocurrency Payments: CratD2C decentralized e-commerce trading portal transactions are typically conducted using CRAT cryptocurrencies (CRAT Coin). This enables fast, secure, and low-cost payments, as well as global accessibility and reduced reliance on the traditional approach.`,
  },
  {
    id: '7',
    text: `Decentralized Order Fulfillment: The platform integrates with decentralized logistics and supply chain solutions, allowing for trustless and transparent order fulfilment, tracking, and delivery.`,
  },
  {
    id: '8',
    text: `Dispute Resolution: Decentralized dispute resolution mechanisms, such as community-based arbitration or automated smart contract resolutions, are employed to handle conflicts between buyers and sellers, ensuring a fair and transparent process.`,
  },
  {
    id: '9',
    text: `Governance: CratD2C decentralized e-commerce trading portal utilises a governance model allowing users to participate in decision-making processes, such as voting on platform updates, fee structures, or dispute resolution policies.`,
  },
  {
    id: '10',
    text: `Interoperability and Scalability: The platform is designed to ensure compatibility with other dApps systems on the network, allowing for seamless interaction and integration of various decentralized services with the capacity to handle high volumes of transactions and support a growing user base.`,
  },
];
