import type { Store } from 'mipd';

import type { WalletAdapter } from '../types';

type WalletParams = {
  walletconnectProjectId: string;
  providersStore: Store;
};

export const getWalletsData = (adapters: Record<string, WalletAdapter>, params: WalletParams) => {
  return Object.values(adapters).map((adapter) => adapter(params));
};
