/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { Store } from 'mipd';
import { injected } from 'wagmi/connectors';

import { Icon } from './icon';
import { getTargetEIP6963, isProviderExistsEIP6963 } from './utils';

export const exodusId = 'exodus';
export const exodusName = 'Exodus';
export const exodusRdns = 'com.exodus.web3-wallet';

const getExodusConnector = (providersStore: Store) =>
  injected({
    target: () => getTargetEIP6963(providersStore, exodusRdns),
  });

type Params = {
  providersStore: Store;
};

export const Exodus = ({ providersStore }: Params) => ({
  walletName: exodusName,
  walletId: exodusId,
  type: injected.type,
  /** @note handled by walletconnect on mobile */
  isDesktopOnly: true,
  Icon,
  detector: () => isProviderExistsEIP6963(providersStore, exodusRdns),
  createConnectorFn: getExodusConnector(providersStore),
});
