import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { COLOR_ACCENT, COLOR_BLACK } from '../colors';
import { BORDER_RADIUS_M } from '../common';
import { FontFamilies, FontWeights } from '../Typography';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiTooltipOverrides = (theme: Theme): ComponentsOverrides['MuiTooltip'] => ({
  tooltip: {
    borderRadius: BORDER_RADIUS_M,
    background: COLOR_ACCENT,
    fontWeight: FontWeights.SemiBold,
    fontFamily: FontFamilies.primary,
    fontSize: '20px',
    lineHeight: '32px',
    padding: theme.spacing(0.5, 1.5),
    color: COLOR_BLACK,
    whiteSpace: 'nowrap',
    wordWrap: 'unset',
    arrow: {
      background: COLOR_ACCENT,
    },

    [theme.breakpoints.down('md')]: {
      fontSize: 15,
      lineHeight: '20px',
    },
  },
  arrow: {
    '&::before': {
      color: COLOR_ACCENT,
      borderRadius: '4px',
    },
  },
});

export const getMuiTooltipDefaultProps = (): ComponentsProps['MuiTooltip'] => ({
  arrow: true,
});
