import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { steps } from 'pages/tokenomics/tokenomics/ui/roadmap/constants';
import { Roadmap as RoadmapWidget } from 'widgets';

export const TokenomicsRoadmap = () => {
  return (
    <Stack component="section" mb={{ xs: 6, md: 14 }} gap={{ xs: 3, md: 0 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Box
          pb={{ xs: 0, md: 2.5 }}
          sx={{
            gridColumn: { xs: 'span 12 ', md: '5 / 11' },
          }}
        >
          <Typography variant="h4" className="summary">
            Here&#39;s a deeper dive into the Biennial 8-layer ZigZag mechanized module:
          </Typography>
        </Box>
      </Box>
      <RoadmapWidget showCardNumber={false} fixedStepSize={275} fixedLineSize={190} steps={steps} />
    </Stack>
  );
};
