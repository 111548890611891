import { Box, Stack, Typography } from '@mui/material';
import { ListItem } from 'shared';

import { list } from './constants';

export const EcommerceTrading = () => {
  return (
    <Box>
      <Typography variant="h5">
        CratD2C decentralized e-commerce trading portal is an online marketplace that operates on a CratD2C
        Decentralized Autonomous Smart Chain, allowing buyers and sellers to interact directly without intermediaries.
        This structure promotes greater transparency, security, and reduced transaction costs. Here&#39;s an outline of
        the key components and features of the CratD2C decentralized e-commerce trading portal:
      </Typography>
      <Box
        py={6}
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Stack
          gap={5}
          sx={{
            gridColumn: { xs: 'span 12', md: '4 / 13' },
            transform: { xs: 'translateX(-35px)', md: 'none' },
          }}
        >
          {list.map((item, i) => {
            return (
              <ListItem
                key={item.title}
                number={i + 1}
                title={item.title}
                description={item.description}
                spacing={{ xs: 3 }}
              />
            );
          })}
        </Stack>
      </Box>
      <Typography variant="h5" pt={{ md: 4 }}>
        CratD2C&#39;s decentralized e-commerce trading portal leverages blockchain technology and smart contracts to
        create a secure, transparent, and efficient online marketplace. By eliminating intermediaries and empowering
        users, this structure has the potential to revolutionize the way we conduct trade and commerce online.
      </Typography>
    </Box>
  );
};
