import { H1, PageParagraph, Section } from 'shared';

export const Intro = () => {
  return (
    <Section>
      <H1 text="Comparative Analysis" highlightedWords={[0]} />

      <PageParagraph sx={{ mt: { xs: 4, md: 5 } }}>
        Here&apos;s a comparative analysis of CratD2C Decentralized Autonomous Smart Chain against popular blockchain
        projects including Bitcoin, Ethereum, Cardano, and Solana, backed by a table for a clear overview:
      </PageParagraph>
    </Section>
  );
};
