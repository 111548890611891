import React, { useMemo, useState } from 'react';
import { Box, Menu, Stack, Typography } from '@mui/material';
import { BORDER_RADIUS_S, COLOR_BG, COLOR_STROKE, COLOR_WH, Icons } from 'shared';

import { AmbassadorialMenuCard } from './ambassadorial-menu-card';

export type AmbassadorialMenuProps = {
  activeSection: string;
  isMobile?: boolean;
};

export const AmbassadorialMenu = ({ activeSection, isMobile = false }: AmbassadorialMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobileOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      position="sticky"
      top={{ xs: 20, md: 40 }}
      zIndex={5}
      display={isMobile ? { xs: 'block', md: 'none' } : { xs: 'none', md: 'block' }}
    >
      <Stack
        component="button"
        id="ambassadorial-menu-button"
        aria-controls={isMobileOpen ? 'ambassadorial-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMobileOpen ? 'true' : undefined}
        onClick={handleClick}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        display={{ xs: 'flex', md: 'none' }}
        p={1}
        width="100%"
        height={40}
        bgcolor={COLOR_BG}
        border={`1px solid ${COLOR_STROKE}`}
        borderRadius={BORDER_RADIUS_S}
      >
        <Typography variant="body2" color={COLOR_WH}>
          Content navigation
        </Typography>
        <Icons.ArrowGray sx={{ transition: 'all 0.2s ease-in', transform: `rotate(${isMobileOpen ? '180deg' : 0})` }} />
      </Stack>
      {isMobile ? (
        <Menu
          id="ambassadorial-menu"
          aria-labelledby="ambassadorial-menu-button"
          anchorEl={anchorEl}
          open={isMobileOpen}
          onClose={handleClose}
          sx={{ '.MuiPopover-paper': { background: 'transparent' } }}
        >
          <AmbassadorialMenuCard
            activeSection={activeSection}
            isMobile={isMobile}
            isMobileOpen={isMobileOpen}
            handleClose={handleClose}
          />
        </Menu>
      ) : (
        <AmbassadorialMenuCard
          activeSection={activeSection}
          isMobile={isMobile}
          isMobileOpen={isMobileOpen}
          handleClose={handleClose}
        />
      )}
    </Box>
  );
};
