export type Included = 'YES' | 'NO' | 'Biannual';
export type Frequency = 'Monthly' | 'Biweekly' | 'Weekly';

export enum OptionsEnum {
  Validator = 'Validator',
  Delegator = 'Delegator',
  LiteBacker = 'LiteBacker',
  TurboBacker = 'TurboBacker',
}

export interface TransformedData {
  title: TransformObjectKeys;
  values: Array<Included | Frequency | number>;
}

export interface Pool {
  minAmount: number;
  apr: number;
  frequency: Frequency;
  blockConfirmationReward: Included;
  blockTransactionReward: Included;
  stakePoolReward: Included;
  ecoIpRoyaltiesReward: Included;
  liquidityPoolReward: Included;
  bonusIpPortions: Included;
  ipPortionsRoyaltyReturns: Included;
}

export type PoolConfigKeys = keyof Pool;

export type TransformObjectKeys = PoolConfigKeys;
