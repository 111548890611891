import { Grid, Stack, Typography } from '@mui/material';
import { list } from 'pages/smart-chain/staking/ui/mechanics/constants';
import { ListItem, Section } from 'shared';

export const Mechanics = () => {
  return (
    <Section>
      <Grid container rowSpacing={{ xs: 3, md: 0 }}>
        <Grid item xs={12} md={4}>
          <Typography variant="h4">Mechanics</Typography>
        </Grid>

        <Grid item container xs={12} md={8} rowSpacing={4.75}>
          <Grid item xs={12} md={9} lg={8}>
            <Typography variant="h5" className="secondary">
              Here&#39;s a comprehensive explanation of the full staking mechanics within the CratD2C DASC:
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Stack gap={4.5}>
              {list.map((item, i) => {
                return (
                  <ListItem
                    key={item.title}
                    number={i + 1}
                    title={item.title}
                    description={item.description}
                    spacing={{ xs: 2 }}
                    childrenOpts={{
                      titleGridProps: { xs: 12, md: 3 },
                      descriptionGridProps: { xs: 12, md: 9 },
                    }}
                  />
                );
              })}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
};
