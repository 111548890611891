import { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@mui/material';

export const Section = ({ children, ...boxProps }: PropsWithChildren & BoxProps) => {
  return (
    <Box component="section" mb={{ xs: 6, md: 12 }} {...boxProps}>
      {children}
    </Box>
  );
};
