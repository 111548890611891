import { Box, Typography } from '@mui/material';
import { AboutCard } from 'pages/home/ui/about/ui';
import { gridConfig, initialOptions, tableHelper } from 'pages/tokenomics/biennial-layer/ui/mechanism-table/constants';
import { Section, Table, TableRow, TdProps } from 'shared';

const HeadTd = ({ value }: TdProps<string>) => {
  return (
    <Typography variant="h6" className="secondary">
      {value}
    </Typography>
  );
};

const BodyTd = ({ value }: TdProps<string>) => {
  return <Typography variant="subtitle1">{value}</Typography>;
};

export const MechanismTable = () => {
  return (
    <Section>
      <Typography variant="h4" className="secondary" mb={{ xs: 4, md: 6 }}>
        CratD2C Biennial 8-Layer ZigZag Supply Mechanism
      </Typography>
      <AboutCard sx={{ mb: { xs: 4, md: 6 } }} />

      <Box sx={{ overflowX: 'scroll' }}>
        <Table headProps={{ TdSlot: HeadTd, thData: initialOptions, headLeftSpace: 2, gridConfig }}>
          {tableHelper.map(({ title, values }) => (
            <TableRow
              key={title}
              TdSlot={BodyTd}
              trTitle={title}
              trData={values}
              trTitleSpace={2}
              gridConfig={gridConfig}
              titleSx={{ className: 'semi-bold accent' }}
              sx={{ mt: 2 }}
            />
          ))}
        </Table>
      </Box>
    </Section>
  );
};
