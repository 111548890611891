import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Medium: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      ...sx,
    }}
    {...props}
  >
    <g clipPath="url(#clip0_3213_7975)">
      <path
        d="M13.5374 11.8277C13.5374 15.5984 10.5069 18.6552 6.76884 18.6552C3.03073 18.6552 0 15.5977 0 11.8277C0 8.05767 3.0305 5 6.76884 5C10.5072 5 13.5374 8.05698 13.5374 11.8277Z"
        fill="#B4EB56"
      />
      <path
        d="M20.9642 11.8281C20.9642 15.3775 19.4489 18.2559 17.5797 18.2559C15.7106 18.2559 14.1953 15.3775 14.1953 11.8281C14.1953 8.27883 15.7103 5.40039 17.5795 5.40039C19.4487 5.40039 20.9639 8.2779 20.9639 11.8281"
        fill="#B4EB56"
      />
      <path
        d="M23.9976 11.8286C23.9976 15.0079 23.4647 17.5868 22.8073 17.5868C22.1498 17.5868 21.6172 15.0086 21.6172 11.8286C21.6172 8.64856 22.15 6.07031 22.8073 6.07031C23.4645 6.07031 23.9976 8.64832 23.9976 11.8286Z"
        fill="#B4EB56"
      />
    </g>
    <defs>
      <clipPath id="clip0_3213_7975">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
