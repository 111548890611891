import type { PropsWithChildren } from 'react';

import { WagmiAuthContext } from './context';
import { useAuth } from './hooks';

export const WithWagmiAuth = ({ children }: PropsWithChildren) => {
  const contextValue = useAuth();

  return <WagmiAuthContext.Provider value={contextValue}>{children}</WagmiAuthContext.Provider>;
};
