import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from 'shared';

export type LeaderboardState = {
  leaderboardData: {
    username: string;
    amount: number;
  }[];
  isLastPage: boolean;
};

export type GiveawayState = {
  endTime: number;
  leaderboard: LeaderboardState;
  referralCount: number;
  referralPointsEarned: number;
  tokensBought: number;
  purchaseAmount: number;
  purchasePointsEarned: number;
  winningOdds: Nullable<number>;
  points: number;
  userQuizAnswer: number;
  isSuccessfullAnswer: Nullable<boolean>;
};

const initialGiveawayState: GiveawayState = {
  leaderboard: {
    leaderboardData: [],
    isLastPage: false,
  },
  endTime: 0,
  referralCount: 0,
  referralPointsEarned: 0,
  tokensBought: 0,
  purchaseAmount: 0,
  purchasePointsEarned: 0,
  winningOdds: null,
  points: 0,
  userQuizAnswer: 0,
  isSuccessfullAnswer: null,
};

const giveawaySlice = createSlice({
  name: 'giveaway',
  initialState: initialGiveawayState,
  reducers: {
    updateGiveawayState: (state: GiveawayState, action: PayloadAction<Partial<GiveawayState>>) => ({
      ...state,
      ...action.payload,
    }),

    clearGiveawayState: () => ({
      ...initialGiveawayState,
    }),
    clearUserGiveawayState: (state: GiveawayState) => ({
      ...state,
      userQuizAnswer: 0,
      isSuccessfullAnswer: null,
    }),
  },

  // extraReducers(builder) {
  //   builder.addCase(getGiveawayInfo.fulfilled, (state, { payload }) => {
  //     if (!payload) return state;
  //     const { giveawayInfo, ...balances } = payload;
  //     return {
  //       ...state,
  //       ...giveawayInfo,
  //       ...balances,
  //     };
  //   });
  // },
});

export const { reducer } = giveawaySlice;
export const { actions } = giveawaySlice;
