import { Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';

import { OptionsEnum } from '../model';

export type OptionsProps = {
  optionsList: OptionsEnum[];
  activeOption: string;
  onCurrencyClick: (currency: OptionsEnum) => void;
};

export const Options = ({ optionsList, activeOption, onCurrencyClick }: OptionsProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Tabs orientation={isSmallScreen ? 'vertical' : 'horizontal'} value={activeOption} className="outlined">
      {optionsList.map((option, index) => {
        return (
          <Tab
            key={option}
            value={option}
            onClick={() => onCurrencyClick(option)}
            label={
              <Typography color="inherit" variant="h6" className="semi-bold">
                {option}
              </Typography>
            }
            sx={{
              flex: 0.5,
              width: '100%',
              mr: index === optionsList.length - 1 ? 0 : 1,
              mb: isSmallScreen ? 1 : 0,
              ...(isSmallScreen && { flex: 1, maxWidth: '100%' }),
            }}
          />
        );
      })}
    </Tabs>
  );
};
