import { forwardRef } from 'react';
import { Button } from '@mui/material';
import { useWagmiAuth } from 'app/providers';
import { shortenPhrase } from 'shared';

type Props = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const DesktopButton = forwardRef<HTMLButtonElement, Props>(({ onClick }, ref) => {
  const { state } = useWagmiAuth();

  return (
    <Button
      id="connect-button"
      ref={ref}
      onClick={onClick}
      variant="outlined"
      sx={{
        fontSize: 14,
        fontWeight: 400,
        px: 2,
        width: '145px',
        whiteSpace: 'nowrap',
      }}
    >
      {state.isAuthorized ? shortenPhrase(state.address, 6) : 'Connect wallet'}
    </Button>
  );
});
