import { Typography } from '@mui/material';
import { OptionsEnum } from 'features/calculator';
import { Advantages, Intro, PageParagraph } from 'shared';
import { CalculatorBlock } from 'widgets';

import { BOTTOM_TEXT, intro, list } from './constants';

export const Litebacker = () => {
  return (
    <>
      <Intro
        mainTitle="LiteBacker"
        paragraphSubtitle={intro.subtitle}
        paragraph={intro.paragraph}
        paragraphProps={{ letterSpacing: '-0.3px !important' }}
      />
      <Advantages
        list={list}
        listTitle={
          <Typography variant="h4" className="summary">
            Key Advantages of Being a LiteBacker:
          </Typography>
        }
        headTitle="Advantages"
      />
      <PageParagraph sx={{ mb: { xs: 6, md: 11 } }} pProps={{ sx: { letterSpacing: '-0.4px !important' } }}>
        LiteBackers who remain committed are incentivized with appealing bonuses within the CratD2C Ecosystem, fostering
        ongoing engagement.
      </PageParagraph>
      <CalculatorBlock activeOption={OptionsEnum.LiteBacker} bottom={BOTTOM_TEXT} />
    </>
  );
};
