import { Box, Typography } from '@mui/material';
import { COLOR_GRAY_5 } from 'shared';

export const Widget = () => {
  return (
    <Box>
      <Typography variant="h5" letterSpacing="-0.021em">
        Widget is a quick solution to integrate cryptocurrency into our daily business transactions. It generates a
        Checkout page and automatically redirects users to the payment page where merchant checkout integration is
        enabled. Users can complete all the payment processes and steps in less than 2mins.
      </Typography>
      <Box
        sx={{
          mt: 4.5,
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Box
          sx={{
            gridColumn: { xs: 'span 12', md: '4 / 13' },
          }}
        >
          <Typography variant="body2" color={COLOR_GRAY_5} className="font-secondary">
            THE PRICE POINTS ARE CONFIGURED VIA THE CRATD2C SMART CHAIN MECHANISM.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
